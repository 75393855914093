import React, { useState, useEffect } from "react";
import { isDate } from "lodash";

import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";

import DragHandleIcon from "@material-ui/icons/DragHandle";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DeleteIcon from "@material-ui/icons/Delete";

import VisibityIcon from "@material-ui/icons/Visibility";
import VisibityOffIcon from "@material-ui/icons/VisibilityOff";

import { KeyboardDatePicker } from "@material-ui/pickers";

import Uploader from "../../Uploader";

import { scaleCropTo } from "../../../../../../lib/ucImage";
import { getFirestoreDb } from "../../../../../../config/cms.config";

import styles from "../widgets.module.less";

const db = getFirestoreDb();

const type = "events";
const field = "display";
const name = `${type}.${field}`;
const label = "Display Events?";
const id = `entry-module-${name}`;

const Events = ({ widget, onChange, setField, errors, hasErrors, ...rest }) => {
    const [photos, setPhotos] = useState([]);
    const titleColor = !!hasErrors ? "error" : "inherit";

    useEffect(() => {
        if (photos.length === 0) {
            db.collection(`events`)
                .where("hidden", "==", false)
                .get()
                .then(querySnapshot => {
                    let photos = querySnapshot.docs.map(doc => {
                        const { className, label, url, uuid } = doc.data();
                        return {
                            className,
                            label,
                            url,
                            uuid,
                        };
                    });
                    setPhotos(photos);
                });
        }
    });

    const { display, event1, event2, event3 } = widget;

    return (
        <Accordion
            className={styles.Widget}
            TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
            <AccordionSummary
                classes={{ content: styles.TitleBar }}
                expandIcon={<ExpandMoreIcon />}>
                <DragHandleIcon data-movable-handle />
                <Typography component="h4" variant="h6" color={titleColor}>
                    Events
                </Typography>
                {display ? (
                    <VisibityIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                ) : (
                    <VisibityOffIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Box className={styles.grow}>
                    <Box marginBottom={3}>
                        <FormControlLabel
                            label={label}
                            control={
                                <Switch
                                    color="primary"
                                    id={id}
                                    name={field}
                                    checked={display}
                                    onChange={onChange}
                                    inputProps={{
                                        "data-label": label,
                                    }}
                                />
                            }
                        />
                    </Box>
                    <Event
                        widget={widget}
                        name="event1"
                        label="Event 1"
                        event={event1}
                        photos={photos}
                        onChange={onChange}
                        onFile={setField}
                    />
                    <Event
                        widget={widget}
                        name="event2"
                        label="Event 2"
                        event={event2}
                        photos={photos}
                        onChange={onChange}
                        onFile={setField}
                    />
                    <Event
                        widget={widget}
                        name="event3"
                        label="Event 3"
                        event={event3}
                        photos={photos}
                        onChange={onChange}
                        onFile={setField}
                    />
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default Events;

/**
 * value may be a Date, Firebase Timestamp, or null
 * Normalize for use in the UI
 * @param {Object} val
 */
const normalizeDate = val => {
    if (isDate(val)) {
        return val;
    } else {
        return val && val.toDate ? val.toDate() : null;
    }
};

const Event = ({ widget, name, label, event, photos, onChange, onFile }) => {
    const defaultEventImage =
        "https://ucarecdn.com/6d8ff3a9-717b-4956-ab2d-ebab3cbe0a97/eventtakeaseat.jpg";
    const {
        eventName,
        eventDate,
        eventTime,
        eventLocation,
        eventImage = defaultEventImage,
        eventCustomImage,
        eventDescription,
        eventUrl,
        eventCta,
    } = event;

    const getField = field => `${name}.${field}`;

    const handleDateChange = moment => {
        // simulate event
        let change = {
            target: {
                name: getField("eventDate"),
                type: "date",
                value: !!moment ? moment.toDate() : null,
            },
        };
        onChange(change);
    };

    const handleDeleteEvent = e => {
        [
            "eventName",
            "eventDate",
            "eventTime",
            "eventLocation",
            "eventImage",
            "eventCustomImage",
            "eventDescription",
            "eventUrl",
            "eventCta",
        ].forEach(field => {
            let change = {
                target: {
                    name: getField(field),
                    type: "text",
                    value: name === "eventImage" ? defaultEventImage : "",
                },
            };
            onChange(change);
        });
    };

    return (
        <Paper>
            <Box p={2} m={2}>
                <Typography component="h5" variant="subtitle1">
                    {label}
                </Typography>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth={true}
                            name={getField("eventName")}
                            label="Event Name"
                            value={eventName}
                            onChange={onChange}
                            helperText=""
                            margin="dense"
                            inputProps={{
                                "data-lpignore": "true",
                                "data-label": "Event Name",
                            }}
                        />

                        <TextField
                            fullWidth={true}
                            name={getField("eventLocation")}
                            label="Location"
                            value={eventLocation}
                            onChange={onChange}
                            helperText=""
                            margin="dense"
                            inputProps={{
                                "data-lpignore": "true",
                                "data-label": "Location",
                            }}
                        />

                        <KeyboardDatePicker
                            name={getField("eventDate")}
                            label="Date"
                            format="MMMM D, YYYY"
                            margin="dense"
                            value={normalizeDate(eventDate)}
                            onChange={handleDateChange}
                        />

                        <TextField
                            name={getField("eventTime")}
                            label="Time"
                            value={eventTime}
                            onChange={onChange}
                            helperText=""
                            margin="dense"
                            inputProps={{
                                "data-lpignore": "true",
                                "data-label": "Time",
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth={true}
                            name={getField("eventDescription")}
                            label="Description"
                            value={eventDescription}
                            onChange={onChange}
                            helperText=""
                            margin="dense"
                            inputProps={{
                                "data-lpignore": "true",
                                "data-label": "Description",
                            }}
                        />

                        <TextField
                            fullWidth={true}
                            name={getField("eventUrl")}
                            label="URL / Link"
                            value={eventUrl}
                            onChange={onChange}
                            helperText={`Add a link to your event. URL must be valid format and begin with "http://" or "https://".`}
                            margin="dense"
                            inputProps={{
                                "data-lpignore": "true",
                                "data-label": "URL / Link",
                            }}
                        />

                        <TextField
                            fullWidth={true}
                            name={getField("eventCta")}
                            label="Call to action"
                            value={eventCta}
                            onChange={onChange}
                            helperText="Provide a call-to-action or label for your link."
                            margin="dense"
                            inputProps={{
                                "data-lpignore": "true",
                                "data-label": "URL / Link",
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            name={getField("eventImage")}
                            fullWidth={true}
                            select
                            label="Event Image"
                            value={eventImage || defaultEventImage}
                            onChange={onChange}
                            margin="dense">
                            {photos.map(photo => {
                                const { label, uuid, url } = photo;
                                const img = scaleCropTo(uuid, 100, 100);
                                return (
                                    <MenuItem key={uuid} value={url}>
                                        <div className={styles.BadgeListItem}>
                                            <ListItemIcon>
                                                <Avatar src={img} />
                                            </ListItemIcon>
                                            <Typography
                                                component="span"
                                                variant="body1">
                                                {label}
                                            </Typography>
                                        </div>
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                        <Uploader
                            name={getField("eventCustomImage")}
                            label="Custom Event Image"
                            value={eventCustomImage}
                            handleFile={onFile}
                            hint="Upload a logo or graphic representing your award."
                            config={{
                                imagesOnly: true,
                                crop: "3:2",
                                imageShrink: "600x400",
                            }}
                        />
                    </Grid>
                </Grid>
                <Button onClick={handleDeleteEvent}>
                    <DeleteIcon />
                    Delete Event
                </Button>
            </Box>
        </Paper>
    );
};
