import React from "react";
import { get } from "lodash";

import TextField from "@material-ui/core/TextField";

const hint = "";

const AddressLine = ({
    name = "address1",
    label = "Address 1",
    entry,
    errors,
    ...rest
}) => {
    const errorMsg = errors[name];
    const value = get(entry, name);
    const helperText = errorMsg || hint;
    const id = `entry-${name}`;

    return (
        <TextField
            autoComplete="off"
            id={id}
            name={name}
            label={label}
            error={!!errorMsg}
            helperText={helperText}
            value={value}
            fullWidth={true}
            margin="normal"
            inputProps={{
                "data-lpignore": "true",
                "data-label": label,
            }}
            {...rest}
        />
    );
};

export default AddressLine;
