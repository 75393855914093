import React from "react";
import { List, arrayMove } from "react-movable";

import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";

import styles from "./styles.module.less";

const field = "profile.licenseIds";
const hint = "";
const options = ["NMLS ID", "MLO ID", "GA-MLO", "CO-MLO"];

const Licenses = ({ isAdmin, entry, errors, handleListChange }) => {
    const errorMsg = errors[field];
    const list = entry.profile.licenseIds;
    let helperText = errorMsg || hint;

    const getHandler = index => {
        return e => {
            const { name, value } = e.target;
            list[index][name] = value;
            handleListChange(field, list);
        };
    };

    const getDeleter = index => {
        return e => {
            list.splice(index, 1);
            handleListChange(field, list);
        };
    };

    const add = () => {
        list.push({ license: "", id: "" });
        handleListChange(field, list);
    };

    // non-admin view is vurry simple
    if (!isAdmin) {
        return (
            <div>
                <FormControl className={styles.Licenses} margin="normal">
                    <FormLabel>License IDs</FormLabel>
                    <Box marginTop={1}>
                        {list.map((item, i) => {
                            const { license, id } = item;
                            return (
                                <FormLabel key={i}>
                                    <strong>{license}:</strong> {id}
                                </FormLabel>
                            );
                        })}
                    </Box>
                </FormControl>
            </div>
        );
    }

    return (
        <FormControl className={styles.Licenses} required margin="normal">
            <FormLabel>License IDs</FormLabel>
            <List
                values={list}
                onChange={({ oldIndex, newIndex }) => {
                    handleListChange(
                        field,
                        arrayMove(list, oldIndex, newIndex),
                    );
                }}
                renderList={({ children, props, isDragged }) => {
                    return (
                        <div
                            {...props}
                            style={{
                                cursor: isDragged ? "grabbing" : "inherit",
                            }}>
                            {children}
                        </div>
                    );
                }}
                renderItem={({
                    value,
                    props,
                    isDragged,
                    isSelected,
                    index,
                }) => {
                    const handleChange = getHandler(index);
                    const handleClick = getDeleter(index);
                    return (
                        <div {...props}>
                            <Row
                                key={index}
                                isDragged={isDragged}
                                row={value}
                                handleChange={handleChange}
                                handleClick={handleClick}
                            />
                        </div>
                    );
                }}
            />
            <Box className={styles.add} marginTop={2}>
                <IconButton onClick={add} margin={0}>
                    <AddIcon />
                </IconButton>
            </Box>
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    );
};

export default Licenses;

const Row = ({ row, isDragged, handleChange, handleClick }) => {
    const { license = "NMLS ID", id = "55555" } = row;
    const cls = isDragged ? `${styles.row} ${styles.isDragged}` : styles.row;
    return (
        <Box className={cls} marginTop={1}>
            <DragHandleIcon data-movable-handle />
            <TextField
                className={styles.field}
                name="license"
                select
                value={license}
                onChange={handleChange}
                inputProps={{
                    "data-label": "License ID",
                }}>
                {options.map(opt => (
                    <option key={opt} value={opt}>
                        {opt}
                    </option>
                ))}
            </TextField>
            <TextField
                name="id"
                value={id}
                onChange={handleChange}
                inputProps={{
                    "data-lpignore": "true",
                    "data-label": "License ID",
                }}
            />
            <IconButton onClick={handleClick}>
                <DeleteIcon />
            </IconButton>
        </Box>
    );
};
