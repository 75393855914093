import React, { Component, createContext } from "react";

import {
    loginToDatabase,
    logOutOfDatabase,
    onAuthStateChanged,
} from "../../../config/cms.config";

import {
    init,
    login,
    onUserContext,
    close,
    logout,
} from "../../../lib/identity";

const initialAuthState = {
    user: null,
    isAuthenticated: false,
    isAdmin: false,
    isEditor: false,
    error: null,
};

export const AuthContext = createContext({ ...initialAuthState });

export default class AuthProvider extends Component {
    state = { ...initialAuthState };

    componentDidMount() {
        // when a user logs in with netlify, call handler
        onUserContext(this.handleUserContext);
        init(); // init netlify identity

        // firebase auth listener will log out netlify id if user session ends
        this.stopAuthListener = onAuthStateChanged(user => {
            if (user) {
                // a user session has been created, hold the user id in state
                close();
                this.setState({ uid: user.id });
            } else {
                // if no session with firebase, log out of netlify id
                logout();
                // immediately show login module
                login();
            }
        });
    }

    componentWillUnmount() {
        this.stopAuthListener();
    }

    handleUserContext = ctx => {
        const { user, isAdmin, isEditor } = ctx;
        const isAuthenticated = isAdmin || isEditor;
        // log in to firebase db
        loginToDatabase()
            .then(() => {
                // close the login window if open
                close();
                this.setState({
                    user,
                    isAuthenticated,
                    isAdmin,
                    isEditor,
                    error: null,
                });
            })
            .catch(error => {
                logout();
                this.setState({ error });
            });
    };

    /**
     * End netlify user session,
     * @return {Promise<boolean>}
     */
    logout = async () => {
        logout();
        try {
            await logOutOfDatabase();
            this.setState({ ...initialAuthState });
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    render() {
        let { user, isAuthenticated, isAdmin, isEditor, error } = this.state;

        return (
            <AuthContext.Provider
                value={{
                    user,
                    isAuthenticated,
                    isAdmin,
                    isEditor,
                    error,
                    login,
                    onUserContext,
                    logout: this.logout,
                }}>
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}
