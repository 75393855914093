import React from "react";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import DragHandleIcon from "@material-ui/icons/DragHandle";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import VisibityIcon from "@material-ui/icons/Visibility";
import VisibityOffIcon from "@material-ui/icons/VisibilityOff";

import Uploader from "../../Uploader";
import Editor from "../../Editor";

import styles from "../widgets.module.less";

const widget = "content";
const field = "display";
const name = `${widget}.${field}`;
const label = "Display Content?";
const id = `entry-module-${name}`;

const Content = ({
    widget,
    onChange,
    onEditorChange,
    onFileChange,
    setFile,
    errors,
    ...rest
}) => {
    const { display } = widget;

    return (
        <Accordion
            TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
            className={styles.Widget}>
            <AccordionSummary
                classes={{ content: styles.TitleBar }}
                expandIcon={<ExpandMoreIcon />}>
                <DragHandleIcon data-movable-handle />
                <Typography component="h4" variant="h6">
                    Content
                </Typography>
                {display ? (
                    <VisibityIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                ) : (
                    <VisibityOffIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                    <FormControlLabel
                        label={label}
                        control={
                            <Switch
                                color="primary"
                                id={id}
                                name={field}
                                checked={display}
                                onChange={onChange}
                                inputProps={{
                                    "data-label": label,
                                }}
                            />
                        }
                    />
                    <Typography component="p" variant="body1">
                        The content widget will display an image, title, and
                        text.
                        <br />
                        Show or hide the content in the widget manager.
                    </Typography>
                    <TextField
                        required
                        name="contentTitle"
                        label="Title"
                        onChange={onChange}
                        value={widget.contentTitle}
                        rowsMax={3}
                        multiline={true}
                        margin="normal"
                        fullWidth={true}
                        error={!!errors["content.contentTitle"]}
                        inputProps={{
                            "data-lpignore": "true",
                            "data-label": "Header Title",
                        }}
                    />
                    <div className={styles.contentEditor}>
                        <Editor
                            name="contentText"
                            entry={{ body: widget.contentText }}
                            onChange={body => {
                                onEditorChange("contentText", body);
                            }}
                            toolbarButtons="bold|italic|link|bullet|numbered"
                        />
                    </div>
                    <Uploader
                        name="contentImage"
                        label="Image"
                        value={widget.contentImage}
                        config={{
                            imagesOnly: true,
                            inputAcceptTypes: ".jpg, .jpeg, .png",
                            crop: "16:9",
                            imageShrink: "2881x2017",
                        }}
                        handleFile={onFileChange}
                        onChange={onChange}
                        logo
                    />
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default Content;
