import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { mapBlogs } from "../../lib/utility";
import BlogRoll from "../../components/BlogRoll";

import Layout from "../../components/Layout";
import Header from "../../components/Header";
import ProductHero from "../../components/ProductHero";
import FindLO from "../../components/FindLO";
import Calculators from "../../components/Calculators";
import ProductDetails from "../../components/ProductDetails";
import StaticText from "../../components/StaticText";

import background from "../../images/product-page-hero.jpg";
import icon from "../../images/typesImg/Icons_FixedRate_active.png";

import styles from "./styles.module.less";

const FixedRateMortgage = ({ data, staticTextItems }) => {
    const header = <Header active={[]} />;

    const { corpBlogs } = data;
    const { edges: corpBlobPosts = [] } = corpBlogs || {};
    const corporateBlogs = mapBlogs(corpBlobPosts);

    return (
        <Layout hasHero={true} header={header}>
            <Helmet>
                <title>Fixed-Rate Mortgage | Bay Equity Home Loans</title>
                <meta
                    name="description"
                    content="A fixed-rate mortgage is a home loan where the interest rate stays the same for the life of the loan. Interested in getting a fixed-rate home loan? Contact us today."
                />
                <script
                    src={`https://embed.signalintent.com/js/embedded.js?org-guid=${process.env.GATSBY_SIGNAL_INTENT_ORG_GUID}`}></script>
            </Helmet>
            <ProductHero
                header={intro}
                customPhoto={background}
                icon={icon}
                subhead={subhead}
                alt="Fixed Rate Mortage | Bay Equity Home Loans"
            />
            <ProductDetails
                title={"What is a fixed-rate mortgage?"}
                border={true}>
                <div className={styles.detailsItem}>
                    <StaticText
                        id="fixed-rate-mortgage-what-is"
                        items={staticTextItems}
                    />
                </div>
            </ProductDetails>
            <ProductDetails title={benefits} border={true}>
                <ul>
                    <li>{benefitsListOne}</li>
                    <li>{benefitsListTwo}</li>
                    <li>{benefitsListThree}</li>
                </ul>
            </ProductDetails>
            <ProductDetails title={terms} border={true}>
                <p className={styles.productSub}>{termsSub}</p>
                <p>
                    Interest rates on fixed-rate mortgages change all the time
                    based on market trends. While we can’t guarantee your
                    specific rate here and now,{" "}
                    <a
                        href="/find-your-loan-officer"
                        style={{ color: "#FF8A00" }}>
                        talk to one of our loan officers
                    </a>{" "}
                    to lock in your exact interest rate.
                </p>
            </ProductDetails>
            <ProductDetails title={qualify}>
                <p>{qualifyBody}</p>
                <p className={styles.productSub}>{qualifySub}</p>
                <ul className={styles.list}>
                    <li>{qualifyListOne}</li>
                    <li>{qualifyListTwo}</li>
                    <li>{qualifyListThree}</li>
                </ul>
            </ProductDetails>
            <Calculators
                showCalculatorMortgage={true}
                showCalculatorRentVsBuy={true}
                showCalculatorHomeAffordability={true}
                showCalculatorRequiredIncome={true}
            />
            <FindLO />
            {corporateBlogs.length >= 3 && (
                <BlogRoll
                    corporateBlogs={corporateBlogs}
                    blogs={corporateBlogs}
                    showBlogs={true}
                    word="our"
                    url="fixed-rate-mortgages"
                />
            )}
        </Layout>
    );
};

export default FixedRateMortgage;

const intro = "Fixed-Rate Home Loans";
const subhead = `The standard in mortgage lending,${"\n"} for a reason.`;

const benefits = "What are the benefits of a fixed-rate mortgage?";
const benefitsListOne =
    "Your monthly mortgage payment stays the same throughout the life of the loan";
const benefitsListTwo = "No prepayment penalties if you refinance or sell";
const benefitsListThree =
    "Nearly all government and conventional loan programs offer a fixed-rate term";

const terms = "How does a fixed rate mortgage work?";
const termsSub = "Lock in your rate";
const termsSubTwo = "Choose your loan term";
const termsDisclaimer =
    "Choosing a shorter fixed-rate period will get a lower interest rate, allowing you to see significant savings, as well as pay off the home loan faster than a traditional 30-year loan.";
const termsSubThree = "Enjoy the lifelong reward of a shorter-term benefit";
const termsBodyThree =
    "A fixed rate mortgage has its pros and cons, but with a simple monthly and total-term comparison, our loan officers can show you how a fixed-rate home loan is a great option for you and your family.";

const qualify = "How do I qualify for a fixed-rate mortgage?";
const qualifyBody =
    "Qualifying for a fixed-rate home loan is easier than you might think. Essentially, lenders calculate your income-to-debt ratio to gauge how efficiently you’d be able to pay off your loan. If they see that the numbers are doable, they’ll make it work for you.";
const qualifySub = "Qualifying for a fixed-rate loan comes down to:";
const qualifyListOne = (
    <span>
        Good credit: anything higher than 720 is a good starting point for a
        conventional fixed rate, but there are also fixed-rate loans that
        require lower credit scores available from the{" "}
        <a href="/fha-loan" style={{ color: "#FF8A00" }}>
            FHA
        </a>
        ,{" "}
        <a href="/va-loans" style={{ color: "#FF8A00" }}>
            VA
        </a>{" "}
        and others
    </span>
);
const qualifyListTwo =
    "Employment history: lenders are looking for consistency";
const qualifyListThree =
    " Monthly income: are you actually able to make the payments?";

export const pageQuery = graphql`
    query FixedRateMortgageQuery {
        corpBlogs: allBlog(
            sort: { fields: [date], order: DESC }
            filter: {
                type: { eq: "corporate" }
                tags: { in: ["Fixed Rate Mortgages"] }
            }
        ) {
            edges {
                node {
                    id
                    html
                    slug
                    title
                    date
                    feature
                    categories
                    tags
                    image
                    author {
                        slug
                        profile {
                            name
                        }
                        gallery {
                            photo
                        }
                    }
                    date
                    feature
                }
            }
        }
    }
`;
