import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import { blogTags } from "../../../lib/defaults";

const name = "tags";
const hint = "";
const label = "Tags";

const TagSelect = ({ entry, errors, onChange, ...rest }) => {
    const errorMsg = errors[name];
    const value = entry[name] || [];
    let helperText = errorMsg || hint;
    const id = `entry-${name}`;
    const inputProps = {
        "data-label": label,
    };

    const [isOpen, setIsOpen] = useState(false);

    const onOpen = () => {
        setIsOpen(true);
    };
    const onClose = () => {
        setIsOpen(false);
    };

    return (
        <TextField
            select
            open={isOpen}
            SelectProps={{ multiple: true, open: isOpen, onOpen, onClose }}
            id={id}
            name={name}
            label={label}
            error={!!errorMsg}
            helperText={helperText}
            value={value}
            inputProps={inputProps}
            fullWidth={true}
            onChange={e => {
                e.persist();
                onChange(e);
                onClose();
            }}
            margin="normal"
            {...rest}>
            {blogTags.map((opt, i) => {
                return (
                    <MenuItem key={opt} value={opt} onClick={onClose}>
                        {opt}
                    </MenuItem>
                );
            })}
        </TextField>
    );
};

export default TagSelect;
