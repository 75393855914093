import React, { PureComponent } from "react";

import Contact from "./components/Contact";
import withSurvey from "../withSurvey";

class NonOriginatorDetail extends PureComponent {
    render() {
        return <Contact {...this.props} />;
    }
}

export default withSurvey(NonOriginatorDetail);
