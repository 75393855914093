import React from "react";

import Hero from "../Hero";
import HeroQuote from "../HeroQuote";

import ModuleList from "../ModuleList";

import withSurvey from "../withSurvey";

const TeamDetail = ({
    firestoreId,
    applyNow = "",
    applyNowScript = "",
    slug,
    title,
    hero = {},
    logo,
    graphic,
    name,
    email,
    phone,
    mobilePhone,
    fax,
    modules = [],
    branch = {},
    testimonials: review = [],
    isSurveyInit: isReviewInit,
    hasSurveyErrored: hasReviewErrored,
    blogs = [],
    corporateBlogs = [],
    licenseIds = [],
    allowLink = false,
    loanOfficers = [],
    managers = [],
    staff = [],
    facebook,
    twitter,
    linkedin,
    youtube,
    instagram,
    isPreview,
    stars,
    ratingsCount,
    shouldDisplaySurvey,
    testimonials,
    isSurveyInit,
    hasSurveyErrored,
    profile,
}) => {
    const {
        heroBackground = "",
        quote = "",
        byline = "",
        customPhoto = "",
        photoAlignment = "center",
    } = hero || {};

    const { address1, address2, address3, city, state, zip } = branch;
    const contactInfo = {
        address1,
        address2,
        address3,
        city,
        state,
        zip,
        phone,
        mobilePhone,
        fax,
        email,
    };

    const socialIconsProps = {
        facebook,
        twitter,
        linkedin,
        youtube,
        instagram,
    };

    const moduleListProps = {
        firestoreId,
        modules,
        applyNow,
        applyNowScript,
        slug,
        twitter,
        branch,
        isPreview,
        blogs,
        corporateBlogs,
        title,
        review,
        isReviewInit,
        hasReviewErrored,
        blogWord: "our",
        teamLoanOfficers: [],
        managers,
        staff,
        teamMembersAllowLOLink: allowLink,
        templateKey: "team",
        gallery: { photo: profile.logo },
        licenseIds,
        name,
        contactInfo,
        email,
        phone,
        mobilePhone,
        fax,
        socialIcons: socialIconsProps,
        graphic,
        stars,
        ratingsCount,
        shouldDisplaySurvey,
        testimonials,
        profile,
        pageTitle: title,
        pageUrl: "",
        isSurveyInit,
        hasSurveyErrored,
        pageType: "team",
    };

    // flatten loan officers...
    const teamLoanOfficers = loanOfficers
        .filter(lo => !!lo && !lo.hidden)
        .map(lo => {
            if (!lo) return {};
            const {
                slug = "",
                gallery: { photo = "" },
                profile: {
                    name = "",
                    email = "",
                    jobTitle = "",
                    licenseIds = [],
                },
            } = lo;

            return { slug, photo, name, email, jobTitle, licenseIds };
        });

    moduleListProps.loanOfficers = teamLoanOfficers;

    return (
        <>
            <Hero
                background={heroBackground}
                customPhoto={customPhoto}
                photoAlignment={photoAlignment}>
                <HeroQuote quote={quote} subhead={byline} />
            </Hero>
            <ModuleList {...moduleListProps} />
        </>
    );
};

export default withSurvey(TeamDetail);
