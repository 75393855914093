import React from "react";

import { get, noop } from "lodash";

import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";

import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";

import { showDialog } from "../../../lib/uploadcare";

import styles from "./styles.module.less";

const Uploader = ({
    name,
    entry,
    label,
    hint,
    config,
    value,
    defaultValue = "",
    isImage = true,
    handleFile = noop,
    slim = false,
    avatarLarge = false,
    avatarRounded = false,
    logo = false,
}) => {
    const val = value || get(entry, name);
    const resolvedValue = val || defaultValue;
    const shouldPreviewImage = !!resolvedValue && isImage;

    const handleClick = e => {
        showDialog(resolvedValue, config, cdnUrl => {
            handleFile(name, cdnUrl, label);
        });
    };

    const handleDelete = e => {
        handleFile(name, defaultValue, label);
    };

    const classes = avatarLarge
        ? makeStyles(theme => ({
              large: {
                  width: theme.spacing(7),
                  height: theme.spacing(7),
              },
          }))
        : { large: {} };
    const variant = avatarRounded ? "rounded" : "circular";

    if (slim) {
        return (
            <Box display="flex" alignItems="center">
                {!!isImage && (
                    <Avatar
                        alt="Staff photo"
                        src={resolvedValue}
                        variant={variant}
                        className={classes.large}
                    />
                )}
                {!isImage && <Input readOnly={true} value={resolvedValue} />}
                <IconButton onClick={handleClick} aria-label="Upload">
                    <CloudUploadIcon />
                </IconButton>
                <IconButton onClick={handleDelete} aria-label="Delete">
                    <DeleteIcon />
                </IconButton>
            </Box>
        );
    }

    return (
        <Card className={styles.Uploader} elevation={0}>
            {!!shouldPreviewImage && (
                <CardMedia
                    className={logo ? styles.logo : styles.media}
                    image={resolvedValue}
                    component="img"
                />
            )}
            <CardContent>
                <Typography component="h4" variant="h6">
                    {label}
                </Typography>
                {!!hint && (
                    <Typography component="div" variant="body1">
                        {hint}
                    </Typography>
                )}
                <Input readOnly={true} value={resolvedValue} />
            </CardContent>
            <CardActions>
                <IconButton onClick={handleClick} aria-label="Upload">
                    <CloudUploadIcon />
                </IconButton>
                <IconButton onClick={handleDelete} aria-label="Delete">
                    <DeleteIcon />
                </IconButton>
            </CardActions>
        </Card>
    );
};

export default Uploader;
