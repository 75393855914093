import React, { useState, useEffect } from "react";
import { noop, orderBy, cloneDeep } from "lodash";

import GivingToHeroesDetail from "../../../../GivingToHeroesDetail";
import { mapGivingToHeroesData } from "../../../../GivingToHeroesDetail/lib/helpers";
import { getDocumentByRef } from "../../../../../lib/helpers";

import styles from "./styles.module.less";

const GivingToHeroesPreview = ({
    fetchCollection = noop,
    entry,
    staticTextItems,
}) => {
    const [mappedPageData, setMappedPageData] = useState({});
    const [news, setNews] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [socialId, setSocialId] = useState("");

    useEffect(() => {
        (async () => {
            let data = await mapPageData(entry, blogs, news);
            setMappedPageData(data);
        })();
    }, [entry]);

    useEffect(() => {
        // set social_id
        setSocialId(getTestimonialsSocialId());

        (async () => {
            // set corporate blogs
            let corporateBlogs = await fetchCollection(
                "corporateBlog",
                "published",
            );
            let corpSorted = orderBy(corporateBlogs, ["date"], ["desc"]);
            let items = corpSorted.slice(0, 3).map(item => {
                item.date = item.date.toDate();
                return item;
            });
            setNews(items);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            // set LO blogs
            let items = [];
            if (entry.loanOfficer && mappedPageData.profile) {
                let loBlogs = await fetchCollection("blog", "published");
                let loSorted = orderBy(loBlogs, ["date"], ["desc"]);
                let loFiltered = loSorted.filter(
                    blog => blog.owner === mappedPageData.profile.email,
                );
                items = loFiltered.slice(0, 3).map(item => {
                    item.date = item.date.toDate();
                    return item;
                });
            }
            setBlogs(items);
        })();
    }, [entry.loanOfficer, mappedPageData.profile]);

    useEffect(() => {
        // set social_id
        setSocialId(getTestimonialsSocialId());
    }, [entry.modules]);

    const getTestimonialsSocialId = () => {
        let { modules } = entry;
        const { social_id } =
            (modules &&
                modules.find(module => module.type === "testimonials")) ||
            {};
        return social_id;
    };

    if (!entry) return null;

    return (
        <div className={styles.mktBody}>
            <GivingToHeroesDetail
                {...{ ...cloneDeep(mappedPageData), firestoreId: entry.id }}
                corporateBlogs={news}
                blogs={blogs}
                social_id={socialId}
                isPreview={true}
                staticTextItems={staticTextItems}
            />
        </div>
    );
};

export default GivingToHeroesPreview;

/**
 *
 * Flatten an entry for use in GivingToHeroesDetail
 * Fetch loan-officer, branch and team from refs
 */
const mapPageData = async (entry, blogs, news) => {
    let pageData = { ...entry };

    // don't need scripts for preview
    pageData.headBeginScript = [];
    pageData.headEndScript = [];
    pageData.bodyBeginScript = [];
    pageData.bodyEndScript = [];

    // loan-officer selected, build out based on this...
    if (!!pageData.loanOfficer) {
        let lo = await getDocumentByRef(pageData.loanOfficer);
        if (lo) {
            pageData.loanOfficer = lo;
            let loBranch = await getDocumentByRef(lo.profile.branch);
            if (!loBranch) {
                console.log(
                    `Error: loan officer "${lo.profile.name}" is missing a branch`,
                );
                return null;
            }
            pageData.loanOfficer.profile.branch = loBranch;
            await getExtraData(pageData.loanOfficer.profile.branch);
        } else {
            pageData.loanOfficer = null;
            console.log(
                `Error: loan officer "${pageData.loanOfficer.id}" is missing. Set to null`,
            );
        }

        // lo-specific module info
        pageData.contactInfo = {
            address1: lo.profile.branch.address1,
            address2: lo.profile.branch.address2,
            address3: lo.profile.branch.address3,
            city: lo.profile.branch.city,
            state: lo.profile.branch.state,
            zip: lo.profile.branch.zip,
            phone: lo.contact.phone,
            mobilePhone: lo.contact.mobilePhone,
            fax: lo.contact.fax,
            email: lo.profile.email,
        };

        pageData.name = lo.profile.name;
        pageData.applyNow = lo.applyNow;
        pageData.applyNowScript = lo.applyNowScript;
        pageData.blogWord = "my";
        pageData.templateKey = "loan-officer";
        pageData.licenseIds = lo.profile.licenseIds;
        pageData.logIn = lo.login;
        pageData.jobTitle = lo.profile.jobTitle;
        pageData.team = null;
        pageData.profile = lo.profile;
        pageData.branch = lo.profile.branch;
    } else if (!!pageData.team) {
        let team = await getDocumentByRef(pageData.team);
        if (team) {
            pageData.team = team;
            let teamBranch = await getDocumentByRef(team.profile.branch);
            if (!teamBranch) {
                console.log(
                    `Error: team "${team.profile.name}" is missing a branch`,
                );
                return null;
            }
            team.profile.branch = teamBranch;
            await getExtraData(pageData.team);
        } else {
            team = null;
            console.log(
                `Error: team "${pageData.team.id}" is missing. Set to null`,
            );
        }

        // team-specific module info
        pageData.contactInfo = {
            address1: team.profile.branch.address1,
            address2: team.profile.branch.address2,
            address3: team.profile.branch.address3,
            city: team.profile.branch.city,
            state: team.profile.branch.state,
            zip: team.profile.branch.zip,
            phone: team.contact.phone,
            mobilePhone: team.contact.mobilePhone,
            fax: team.contact.fax,
            email: team.profile.email,
        };

        pageData.name = team.profile.name;
        pageData.applyNow = team.applyNow;
        pageData.applyNowScript = team.applyNowScript;
        pageData.blogWord = "our";
        pageData.templateKey = "team";
        pageData.licenseIds = team.profile.licenseIds;
        pageData.logIn = team.login;
        pageData.jobTitle = "";
        pageData.team = team;
        pageData.profile = team.profile;
    } else if (!!pageData.branch) {
        let branch = await getDocumentByRef(pageData.branch);
        if (branch) {
            pageData.branch = branch;
            await getExtraData(branch);
        } else {
            branch = null;
            console.log(
                `Error: branch "${pageData.branch.id}" is missing. Set to null`,
            );
        }

        // branch-specific module info
        pageData.contactInfo = {
            address1: branch.address1,
            address2: branch.address2,
            address3: branch.address3,
            city: branch.city,
            state: branch.state,
            zip: branch.zip,
            phone: branch.phone,
            mobilePhone: branch.mobilePhone,
            fax: branch.fax,
            email: branch.email,
        };

        pageData.name = branch.name;
        pageData.applyNow = null;
        pageData.applyNowScript = null;
        pageData.blogWord = "our";
        pageData.templateKey = "branch";
        pageData.licenseIds = branch.licenseIds;
        pageData.logIn = branch.login;
        pageData.jobTitle = "";
        pageData.branch = branch;
        pageData.profile = branch.profile;
    }

    // shared module info
    pageData.blogs = blogs;
    pageData.corporateBlogs = news;

    let result = mapGivingToHeroesData(pageData);
    return { ...result };
};

async function getExtraData(collection) {
    if (Array.isArray(collection.loanOfficers)) {
        let promisesPromises = collection.loanOfficers.map(
            async loanOfficer => {
                if (loanOfficer && loanOfficer.get) {
                    let lo = await getDocumentByRef(loanOfficer);
                    return lo;
                }
                return null;
            },
        );
        let loanOfficers = await Promise.all(promisesPromises);
        collection.loanOfficers = loanOfficers;
    } else {
        collection.loanOfficers = [];
    }

    if (Array.isArray(collection.teams)) {
        let promisesPromises = collection.teams.map(async team => {
            if (team && team.get) {
                let lo = await getDocumentByRef(team);
                return lo;
            }
            return null;
        });
        let teams = await Promise.all(promisesPromises);
        collection.teams = teams;
    } else {
        collection.teams = [];
    }

    // ensure managers and staff are set as arrays
    collection.staff = Array.isArray(collection.staff) ? collection.staff : [];
    collection.managers = Array.isArray(collection.managers)
        ? collection.managers
        : [];
}
