import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { initialize } from "../../config/cms.config";
// ensure upload care is initialized on load of admin page
import "../../components/Admin/lib/uploadcare";

/**
 * Proxy coomponent for Admin page.
 *
 * Admin page relies upon Firebase / FirestoreDB for CMS data CRUD operations
 *
 * The web-based firebase implementation shouldn't be executed during build or
 * server-side rendering.
 *
 * This component awaits "mount" then initializes firebase and dynamically loads Admin
 *
 * @param {Object} props
 */
const LoadAdmin = props => {
    const [ready, setReady] = useState(false);
    const defaultTextItems = props.data.allDefaultTextItems;

    useEffect(() => {
        // initialize firestore only in browser;
        initialize();
        // we are live
        setReady(true);
    });

    // render admin on demand, once looaded
    const renderAdmin = props => {
        const { default: Admin } = require("../../components/Admin");
        return <Admin {...props} defaultTextItems={defaultTextItems} />;
    };

    return <>{!!ready && renderAdmin(props)}</>;
};

export default LoadAdmin;

export const pageQuery = graphql`
    query DefaultTextQuery {
        allDefaultTextItems {
            id
            text
        }
    }
`;
