import React, { Component } from "react";
import { navigate } from "gatsby";
import { noop, set, get, has, debounce, uniqueId, cloneDeep } from "lodash";
import sluggo from "slug";
import shortid from "shortid";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Save from "@material-ui/icons/SaveAlt";
import Publish from "@material-ui/icons/Publish";
import Delete from "@material-ui/icons/Delete";
import Error from "@material-ui/icons/Error";
import Done from "@material-ui/icons/Done";
import FileCopy from "@material-ui/icons/FileCopy";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Loader from "../components/Loader";
import LoanOfficer from "./components/LoanOfficer";
import Branch from "./components/Branch";
import Team from "./components/Team";
import NonOriginator from "./components/NonOriginator";
import Blog from "./components/Blog";
import GivingToHeroes from "./components/GivingToHeroes";
import CorporateCare from "./components/CorporateCare";
import CommunityCare from "./components/CommunityCare";
import SplashPage from "./components/SplashPage";

import { hasError, isMissing, validateEntry } from "../lib/validations";
import {
    collectonToBodyLabel,
    defaultCollectionState,
    newEntries,
} from "../lib/defaults";

import styles from "./styles.module.less";
import { Typography } from "@material-ui/core";

export default class Entry extends Component {
    state = {
        entry: {},
        hasErrors: false,
        hasMissingFields: false,
        errors: {},
        missing: {},
        loaded: false,
        dirty: false,
        showNotSaved: false,
        showDelete: false,
        showDuplicateTitle: false,
    };

    eject() {
        const { collection } = this.props;
        navigate(`/admin/collections/${collection}`);
    }

    async componentDidMount() {
        const { collection, id, isAdmin } = this.props;
        // if no collection, navigate to home
        if (!collection) {
            navigate(`/admin`);
        }
        // if no entry, navigate to collection
        if (!id) {
            navigate(`/admin/collections/${collection}`);
        }

        // load doc
        await this.fetch();
        const { entry } = this.state;

        // update change log on global changes
        this.changelogListener = document.addEventListener(
            "change",
            this.handleChangeLog,
        );
        this.blurListener = document.addEventListener(
            "change",
            this.handleBlur,
        );
        // flag disclaimer
        if (!isAdmin && has(entry, "disclaimer")) {
            this.disclaimerListener = document.addEventListener(
                "change",
                this.handleDisclaimer,
            );
        }
    }

    componentWillUnmount() {
        if (this.changelogListener) {
            document.removeEventListener("change", this.changelogListener);
        }
        if (this.disclaimerListener) {
            document.removeEventListener("change", this.disclaimerListener);
        }
        if (this.blurListener) {
            document.removeEventListener("change", this.blurListener);
        }
    }

    async componentDidUpdate(prevProps) {
        if (
            prevProps.isNew !== this.props.isNew &&
            prevProps.id !== this.props.id
        ) {
            await this.fetch();
            return;
        }
    }

    async fetch() {
        const { collection, id, fetchDocument, isNew } = this.props;
        let entry = isNew
            ? await this.getNewEntry(id)
            : await fetchDocument(collection, id);
        let loaded = true;
        let dirty = false;
        // this.setState({ entry, loaded, dirty });
        this.setState(state => {
            return {
                ...state,
                entry,
                loaded,
                dirty,
            };
        });
    }

    getNewEntry(id) {
        const { collection, email } = this.props;
        let allNewEntries = newEntries(this.props.defaultTextItems);
        let defaultEntry = allNewEntries[collection];
        let newEntry = cloneDeep(
            Object.assign({}, defaultEntry, {
                id,
                owner: email,
                draft: true,
            }),
        );
        return Promise.resolve(newEntry);
    }

    /**
     * Validates entry and updates errors in state
     * @returns {bool} true if entry is valid
     */
    validate = entry => {
        let errors = validateEntry(entry);
        let hasErrors = this.hasErrors(errors);
        this.setState({ errors, hasErrors });
        return !hasErrors;
    };

    onDuplicateTitleChange = e => {
        this.setState({
            ...this.state,
            duplicateTitle: e.target.value,
        });
    };

    onCreateDuplicate = async e => {
        e.preventDefault();

        const { entry, duplicateTitle } = this.state;
        const { publishDocument, collection, email } = this.props;

        // ensure title is unique...
        var draftId = sluggo(duplicateTitle, {
            lower: true,
        });
        var idOk = await this.isUniqueId(collection, draftId);

        if (!idOk) {
            this.setState({
                duplicateError: "Title must be unique",
            });
            return;
        }

        let newEntry = cloneDeep(
            Object.assign({}, entry, {
                id: draftId,
                title: duplicateTitle,
                owner: email,
                hidden: true,
                urlSlug: "",
                vanityUrl: "",
                changelog: "",
            }),
        );

        this.setState({
            showDuplicateTitle: false,
            duplicateError: "",
        });

        await publishDocument(newEntry, collection, draftId);
        window.location = `/admin/collections/${collection}/entries/${draftId}`;
    };

    handleSaveDraft = async e => {
        e.preventDefault();
        // preflight check
        const { hasMissingFields, hasErrors } = this.state;
        if (hasMissingFields || hasErrors) {
            return;
        }
        // collect props and state
        const { saveDraft, collection, id, isNew } = this.props;
        const { entry: doc, errors } = this.state;
        const { title, profile } = doc;
        // validate entry doc
        let isValid = this.validate(doc);
        if (!isValid) {
            return;
        }
        // do work
        let draftId = "";
        if (collection === "non-originator")
            draftId = isNew ? sluggo(profile.name, { lower: true }) : id;
        else draftId = isNew ? sluggo(title, { lower: true }) : id;
        doc.id = draftId;
        if (isNew) {
            let idOk = await this.isUniqueId(collection, draftId);
            if (!idOk) {
                errors["title"] = "This title is already in use.";
                this.setState({ errors, hasErrors: true });
                return;
            }
        }
        await saveDraft(doc, collection, draftId);
        this.setState({ dirty: false });
        if (isNew) {
            // if new draft, navigate to update the URL
            navigate(`/admin/collections/${collection}/entries/${draftId}`);
        }
    };

    isUniqueId = async (collection, draftId) => {
        const { fetchDocument } = this.props;
        let doc = await fetchDocument(collection, draftId);
        return doc === null;
    };

    handleReadyForReview = async e => {
        e.preventDefault();
        const { hasMissingFields, hasErrors } = this.state;
        if (hasMissingFields || hasErrors) {
            return;
        }
        const { saveDraft, collection, id } = this.props;
        const { entry } = this.state;
        // set doc ready
        entry.readyForReview = true;
        await saveDraft(entry, collection, id, "mark ready for review");
        this.setState({ dirty: false });
        this.eject();
    };

    handlePublish = async e => {
        const {
            publishSite,
            publishDocument,
            collection,
            id,
            isNew,
        } = this.props;
        const { entry: doc, errors } = this.state;
        const { title } = doc;
        // doc is no longer ready for review upon publish
        doc.readyForReview = false;
        // do work
        let draftId = isNew ? sluggo(title, { lower: true }) : id;
        doc.id = draftId;
        if (isNew) {
            let idOk = await this.isUniqueId(collection, draftId);
            if (!idOk) {
                errors["title"] = "This title is already in use.";
                this.setState({ errors, hasErrors: true });
                return;
            }
        }
        await publishDocument(doc, collection, draftId);
        await publishSite();
        this.eject();
    };

    handleNavigateBack = e => {
        const { dirty } = this.state;
        if (!!dirty) {
            this.setState({ showNotSaved: true });
            return;
        }
        this.eject();
    };

    handleNavigateBackWithoutSaving = e => {
        this.setState({ showNotSaved: false });
        this.eject();
    };

    handleDiscarDraft = async e => {
        const { collection, id, discardDraft } = this.props;
        const { entry } = this.state;
        this.setState({ loaded: false, dirty: false }, async () => {
            await discardDraft(entry, collection, id);
            this.eject();
        });
    };

    handleShowDuplicateTitle = e => {
        const { entry } = this.state;
        this.setState({
            showDuplicateTitle: true,
            duplicateTitle: entry.title,
        });
    };

    handleShowDelete = e => {
        this.setState({ showDelete: true });
    };

    handleDelete = async e => {
        const {
            collection,
            id,
            discardDraft,
            deleteDocument,
            publishSite,
        } = this.props;
        const { entry } = this.state;
        this.setState(
            { loaded: false, dirty: false, showNotSaved: false },
            async () => {
                await discardDraft(entry, collection, id);
                await deleteDocument(entry, collection, id);
                await publishSite();
                this.eject();
            },
        );
    };

    hasMissingFields(missing) {
        let values = Object.values(missing);
        return values.reduce(
            (accumulator, current) => accumulator || current,
            false,
        );
    }

    hasErrors(errors) {
        let values = Object.values(errors);
        return values.reduce(
            (accumulator, current) => !!accumulator || !!current,
            false,
        );
    }

    handleChangeLog = e => {
        const logLabel = get(e.target, "dataset.label", "");
        this.createChangeLogEntry(
            this.getChangelogHeader(),
            this.getChangelogEntry(logLabel),
        );
    };

    handleDisclaimer = e => {
        const name = "disclaimer";
        const value = false;
        if (e.target.name !== name) {
            const { entry, errors } = this.state;
            errors[name] = hasError(name, value);
            set(entry, name, value);
            this.setState({
                entry,
                errors,
                hasErrors: this.hasErrors(errors),
            });
        }
    };

    handleChange = e => {
        const { name } = e.target;
        const value =
            e.target.type === "checkbox" ? e.target.checked : e.target.value;

        // pre validate, clear error states
        let { entry } = this.state;
        set(entry, name, value);
        this.setState({
            entry,
            dirty: true,
        });
    };

    handleBlur = e => {
        const { name } = e.target;
        const value =
            e.target.type === "checkbox" ? e.target.checked : e.target.value;
        let { errors, missing } = this.state;
        errors[name] = hasError(name, value);
        missing[name] = isMissing(name, value);
        this.setState({
            errors,
            missing,
            hasErrors: this.hasErrors(errors),
            hasMissingFields: this.hasMissingFields(missing),
        });
    };

    handleTerminate = e => {
        let { entry } = this.state;
        const { name, checked } = e.target;
        set(entry, name, !!checked);
        if (!!checked) {
            // terminated profiles should be hidden
            set(entry, "hidden", true);
        }
        this.setState({ entry });
    };

    handleFile = (name, value, label) => {
        let { errors, missing, entry } = this.state;
        errors[name] = hasError(name, value);
        missing[name] = isMissing(name, value);
        set(entry, name, value);
        this.setState({
            errors,
            missing,
            entry,
            hasErrors: this.hasErrors(errors),
            hasMissingFields: this.hasMissingFields(missing),
            dirty: true,
        });
        this.createChangeLogEntry(
            this.getChangelogHeader(),
            this.getChangelogEntry(label),
        );
    };

    handleRef = (name, ref, field = null) => {
        let { errors, missing, entry } = this.state;
        errors[name] = hasError(name, ref);
        missing[name] = isMissing(name, ref);
        set(entry, name, ref);
        if (field) set(entry, field.name, field.value);
        this.setState({
            errors,
            missing,
            entry,
            hasErrors: this.hasErrors(errors),
            hasMissingFields: this.hasMissingFields(missing),
            dirty: true,
        });
    };

    handleListChange = (name, list) => {
        let { errors, missing, entry } = this.state;
        let l = [].concat(list); // ensure a new array for state updates
        set(entry, name, l);
        this.setState({
            errors,
            missing,
            entry,
            hasErrors: this.hasErrors(errors),
            hasMissingFields: this.hasMissingFields(missing),
            dirty: true,
        });
    };

    handleBodyChange = (body, name = "body", label = "") => {
        let { collection } = this.props;
        let { entry } = this.state;
        set(entry, name, body);
        this.setState(
            {
                entry,
                dirty: true,
            },
            () => {
                this.logChanges(
                    label ? label : collectonToBodyLabel[collection],
                );
            },
        );
    };

    logChanges = debounce(label => {
        this.createChangeLogEntry(
            this.getChangelogHeader(),
            this.getChangelogEntry(label),
        );
    }, 250);

    getChangelogHeader() {
        let { roles, name } = this.props.user;
        let d = new Date();
        let today = d.toLocaleDateString();
        return `${today} ${name} (${roles.join(",")})`;
    }

    getChangelogEntry(label) {
        let d = new Date();
        return `${label} ${d.toLocaleString()}`;
    }

    createChangeLogEntry(header, line) {
        let { entry } = this.state;
        // get log JSON
        let txtLog = entry.changelog || "[]";
        // parse JSON
        let log = JSON.parse(txtLog);
        // nothing to log, return
        if (!line) return;
        // find / create day
        let day = log.find(l => {
            return l.header === header;
        });
        if (!day) {
            day = { header, events: [] };
            log.unshift(day);
        }
        // add line to day events
        day.events.unshift(line);
        set(entry, "changelog", JSON.stringify(log));
        this.setState({ entry });
    }

    jumpToError = e => {
        e.preventDefault();
        try {
            document.querySelector(".Mui-error").scrollIntoView(false);
        } catch (err) {
            document.querySelector(".Mui-error").scrollIntoView();
        } finally {
            return;
        }
    };

    render() {
        const { isAdmin, email, collection, isNew } = this.props;
        const {
            entry,
            errors,
            missing,
            hasErrors,
            hasMissingFields,
            loaded,
            dirty,
            showNotSaved,
            showDelete,
            showDuplicateTitle,
            duplicateTitle,
            duplicateError,
        } = this.state;

        const publishDisabled = hasErrors || hasMissingFields;
        const dirtyColor = !!dirty ? "secondary" : "inherit";
        const isDraft = !!entry.draft;

        const isLoanOfficer = collection === "loan-officer";
        const isTeam = collection === "team";
        const isBranch = collection === "branch";
        const isNonOriginator = collection === "non-originator";
        const isBlog = collection === "blog";
        const isCorporateBlog = collection === "corporateBlog";
        const isG2HPage = collection === "giving-to-heroes";
        const isCorporateCarePage = collection === "corporate-care";
        const isCommunityCarePage = collection === "community-care";
        const isSplashPage = collection === "splash";
        const disclaimerChecked =
            isBlog || isCorporateBlog || isNonOriginator || !!entry.disclaimer;
        const saveDisabled =
            !disclaimerChecked || !dirty || hasErrors || hasMissingFields;
        const {
            meta: { labelSingular },
        } = defaultCollectionState[collection];
        const isG2HBasePage =
            isG2HPage && entry.title === "Giving to Heroes Base Page";

        return (
            <div className={styles.Entry}>
                <DiscardChangesDialog
                    open={showNotSaved}
                    onCancel={e => this.setState({ showNotSaved: false })}
                    onContinue={this.handleNavigateBackWithoutSaving}
                />
                <DeleteDocumentDialog
                    open={showDelete}
                    onCancel={e => this.setState({ showDelete: false })}
                    onContinue={this.handleDelete}
                />
                <DuplicateTitleDialog
                    title={duplicateTitle}
                    error={duplicateError}
                    open={showDuplicateTitle}
                    onCancel={e =>
                        this.setState({
                            showDuplicateTitle: false,
                        })
                    }
                    onContinue={this.onCreateDuplicate}
                    onChange={this.onDuplicateTitleChange}
                />
                <AppBar position="sticky" color="default">
                    <Toolbar>
                        <Button
                            color="inherit"
                            onClick={this.handleNavigateBack}>
                            <ArrowBack />
                            Back
                        </Button>
                        <Typography
                            component="span"
                            variant="h6"
                            color={dirtyColor}
                            className={styles.Title}>
                            {labelSingular}: {entry.title}
                        </Typography>
                        {!!hasErrors && (
                            <Button
                                className={styles.ToolbarButton}
                                onClick={this.jumpToError}>
                                <Error color="error" />
                            </Button>
                        )}

                        {!!isAdmin && isSplashPage && (
                            <Button
                                color="default"
                                variant="contained"
                                disabled={publishDisabled}
                                onClick={this.handleShowDuplicateTitle}
                                className={styles.ToolbarButton}>
                                Duplicate
                                <FileCopy />
                            </Button>
                        )}
                        {!!isDraft && !isNew && (
                            <Button
                                color="default"
                                variant="contained"
                                onClick={this.handleDiscarDraft}
                                className={styles.ToolbarButton}>
                                Discard Draft
                                <Delete />
                            </Button>
                        )}
                        {isAdmin && !isDraft && !isNew && !isG2HBasePage && (
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={this.handleShowDelete}
                                className={styles.ToolbarButton}>
                                Delete {labelSingular}
                                <Delete />
                            </Button>
                        )}
                        <Button
                            color="default"
                            variant="contained"
                            disabled={saveDisabled}
                            onClick={this.handleSaveDraft}
                            className={styles.ToolbarButton}>
                            Save Draft
                            <Save />
                        </Button>
                        {!isNew && (
                            <Button
                                color="default"
                                variant="contained"
                                disabled={publishDisabled}
                                onClick={this.handleReadyForReview}
                                className={styles.ToolbarButton}>
                                Ready for Review
                                <Done />
                            </Button>
                        )}
                        {!!isAdmin && (
                            <Button
                                color="default"
                                variant="contained"
                                disabled={publishDisabled}
                                onClick={this.handlePublish}
                                className={styles.ToolbarButton}>
                                Publish
                                <Publish />
                            </Button>
                        )}
                    </Toolbar>
                </AppBar>
                <Grid container spacing={0} component="div">
                    <Grid item xs={12}>
                        <Loader loading={!loaded} />
                        {isLoanOfficer && (
                            <LoanOfficer
                                loaded={loaded}
                                entry={entry}
                                errors={errors}
                                missing={missing}
                                isAdmin={isAdmin}
                                email={email}
                                handleChange={this.handleChange}
                                handleBlur={this.handleBlur}
                                handleListChange={this.handleListChange}
                                handleFile={this.handleFile}
                                handleRef={this.handleRef}
                                handleDisclaimerChange={
                                    this.handleDisclaimerChange
                                }
                                handleTerminate={this.handleTerminate}
                                fetchCollection={this.props.fetchCollection}
                            />
                        )}
                        {isTeam && (
                            <Team
                                loaded={loaded}
                                entry={entry}
                                errors={errors}
                                missing={missing}
                                isAdmin={isAdmin}
                                email={email}
                                handleChange={this.handleChange}
                                handleBlur={this.handleBlur}
                                handleListChange={this.handleListChange}
                                handleFile={this.handleFile}
                                handleRef={this.handleRef}
                                handleDisclaimerChange={
                                    this.handleDisclaimerChange
                                }
                                handleTerminate={this.handleTerminate}
                                fetchCollection={this.props.fetchCollection}
                            />
                        )}
                        {isBranch && (
                            <Branch
                                loaded={loaded}
                                entry={entry}
                                errors={errors}
                                missing={missing}
                                isAdmin={isAdmin}
                                email={email}
                                handleChange={this.handleChange}
                                handleBlur={this.handleBlur}
                                handleListChange={this.handleListChange}
                                handleFile={this.handleFile}
                                handleRef={this.handleRef}
                                handleDisclaimerChange={
                                    this.handleDisclaimerChange
                                }
                                handleTerminate={this.handleTerminate}
                                fetchCollection={this.props.fetchCollection}
                            />
                        )}
                        {isNonOriginator && (
                            <NonOriginator
                                loaded={loaded}
                                entry={entry}
                                errors={errors}
                                missing={missing}
                                isAdmin={isAdmin}
                                isNew={isNew}
                                email={email}
                                handleChange={this.handleChange}
                                handleBlur={this.handleBlur}
                                handleListChange={this.handleListChange}
                                handleFile={this.handleFile}
                                handleRef={this.handleRef}
                                handleBodyChange={this.handleBodyChange}
                                handleDisclaimerChange={
                                    this.handleDisclaimerChange
                                }
                                handleTerminate={this.handleTerminate}
                                fetchCollection={this.props.fetchCollection}
                            />
                        )}
                        {isBlog && (
                            <Blog
                                loaded={loaded}
                                entry={entry}
                                errors={errors}
                                isAdmin={isAdmin}
                                email={email}
                                handleChange={this.handleChange}
                                handleBlur={this.handleBlur}
                                handleListChange={this.handleListChange}
                                handleFile={this.handleFile}
                                handleBodyChange={this.handleBodyChange}
                            />
                        )}
                        {isCorporateBlog && (
                            <Blog
                                loaded={loaded}
                                entry={entry}
                                errors={errors}
                                isAdmin={isAdmin}
                                email={email}
                                handleChange={this.handleChange}
                                handleBlur={this.handleBlur}
                                handleListChange={this.handleListChange}
                                handleFile={this.handleFile}
                                handleBodyChange={this.handleBodyChange}
                            />
                        )}
                        {isG2HPage && (
                            <GivingToHeroes
                                loaded={loaded}
                                {...entry}
                                errors={errors}
                                missing={missing}
                                isAdmin={isAdmin}
                                isNew={isNew}
                                isBasePage={isG2HBasePage}
                                handleChange={this.handleChange}
                                handleBlur={this.handleBlur}
                                handleListChange={this.handleListChange}
                                handleFile={this.handleFile}
                                handleRef={this.handleRef}
                                handleBodyChange={this.handleBodyChange}
                                handleDisclaimerChange={
                                    this.handleDisclaimerChange
                                }
                                handleTerminate={this.handleTerminate}
                                fetchCollection={this.props.fetchCollection}
                            />
                        )}
                        {isCorporateCarePage && (
                            <CorporateCare
                                loaded={loaded}
                                entry={entry}
                                errors={errors}
                                missing={missing}
                                isAdmin={isAdmin}
                                isNew={isNew}
                                email={email}
                                handleChange={this.handleChange}
                                handleBlur={this.handleBlur}
                                handleListChange={this.handleListChange}
                                handleFile={this.handleFile}
                                handleRef={this.handleRef}
                                handleBodyChange={this.handleBodyChange}
                                handleDisclaimerChange={
                                    this.handleDisclaimerChange
                                }
                                handleTerminate={this.handleTerminate}
                                fetchCollection={this.props.fetchCollection}
                            />
                        )}
                        {isCommunityCarePage && (
                            <CommunityCare
                                loaded={loaded}
                                entry={entry}
                                errors={errors}
                                missing={missing}
                                isAdmin={isAdmin}
                                isNew={isNew}
                                email={email}
                                handleChange={this.handleChange}
                                handleBlur={this.handleBlur}
                                handleListChange={this.handleListChange}
                                handleFile={this.handleFile}
                                handleRef={this.handleRef}
                                handleBodyChange={this.handleBodyChange}
                                handleDisclaimerChange={
                                    this.handleDisclaimerChange
                                }
                                handleTerminate={this.handleTerminate}
                                fetchCollection={this.props.fetchCollection}
                            />
                        )}
                        {isSplashPage && (
                            <SplashPage
                                dirty={dirty}
                                loaded={loaded}
                                {...entry}
                                errors={errors}
                                missing={missing}
                                isAdmin={isAdmin}
                                email={email}
                                handleChange={this.handleChange}
                                handleBlur={this.handleBlur}
                                handleListChange={this.handleListChange}
                                handleFile={this.handleFile}
                                handleRef={this.handleRef}
                                handleDisclaimerChange={
                                    this.handleDisclaimerChange
                                }
                                handleTerminate={this.handleTerminate}
                                fetchCollection={this.props.fetchCollection}
                            />
                        )}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

Entry.defaultProps = {
    collection: "loan-officer",
    id: null,
    isAdmin: false,
    isNew: false,
    email: "",
    user: { email: "", roles: [] },
    fetchDocument: noop,
    fetchCollection: noop,
    saveDraft: noop,
    discardDraft: noop,
    deleteDocument: noop,
    publishDocument: noop,
    publishSite: noop,
};

const DiscardChangesDialog = ({ open, onCancel, onContinue }) => {
    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogTitle>Discard changes?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    There are unsaved changes.
                    <br />
                    Click "No" to continue working and save changes.
                    <br />
                    Click "Yes" to discard and return to the list.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>No, continue working</Button>
                <Button onClick={onContinue}>Yes, discard changes</Button>
            </DialogActions>
        </Dialog>
    );
};

const DeleteDocumentDialog = ({ open, onCancel, onContinue, label }) => {
    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogTitle>Delete {label} Permanently?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    This will remove the published profile and any drafts.
                    <br />
                    Click "No" to continue working and save changes.
                    <br />
                    Click "Delete" to delete and return to the list.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>No, continue working</Button>
                <Button onClick={onContinue}>Delete.</Button>
            </DialogActions>
        </Dialog>
    );
};

const DuplicateTitleDialog = ({
    open,
    onCancel,
    onContinue,
    onChange,
    title,
    error,
}) => {
    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogTitle>Duplicate Page</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="New Page Title"
                    value={title}
                    error={!!error}
                    helperText={error}
                    fullWidth
                    onChange={onChange}
                    onFocus={event => {
                        const target = event.target;
                        setTimeout(() => target.select(), 0);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Cancel</Button>
                <Button onClick={onContinue}>OK</Button>
            </DialogActions>
        </Dialog>
    );
};

export const NewEntry = ({ collection, ...rest }) => {
    const id = uniqueId(`${collection}-${shortid.generate()}-`); // create a unique placeholder id for this collection entry
    return <Entry isNew={true} id={id} collection={collection} {...rest} />;
};
