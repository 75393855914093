import React from "react";

import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import DragHandleIcon from "@material-ui/icons/DragHandle";

import VisibityIcon from "@material-ui/icons/Visibility";
import VisibityOffIcon from "@material-ui/icons/VisibilityOff";

// Profile
import Uploader from "../../Uploader";
import Text from "../../Text";
import Number from "../../Number";

import Editor from "../../Editor";
import HeroImage from "../../HeroImage";

// contact form fields
import CustomContactForm from "../../CustomContactForm";
import predefinedInputs from "../../../../../G2HCalculatorModule/lib/predefinedInputs.json";

// generic toggle
import Boolean from "../../Boolean";

import styles from "./styles.module.less";

const G2HCalculator = ({
    widget,
    onChange,
    onEditorChange,
    onFileChange,
    setField,
    isAdmin,
    errors,
    hasErrors,
    ...rest
}) => {
    const {
        display,
        g2hCalculatorTitle,
        g2hCalculatorText,
        darkTitleText,
        backgroundImage,
        backgroundImageCustom,
        g2hCalculatorFormTitle,
        g2hCalculatorFormFields,
        g2hCalculatorBammUrl,
        lenderCreditPercentage,
        realtorCommissionPercentage,
        realtorCreditPercentage,
    } = widget;

    const hasErrorsInFields = (errors, fields = []) =>
        fields.some(field => !!errors[field]);

    let formSettingsErrorColor = React.useCallback(
        hasErrorsInFields(errors, ["g2hCalculatorTitle"]) ? "error" : "inherit",
        [errors],
    );
    let creditInfoErrorColor = hasErrorsInFields(errors, [
        "lenderCreditPercentage",
        "realtorCommissionPercentage",
        "realtorCreditPercentage",
    ])
        ? "error"
        : "inherit";

    const handleReorderList = list => {
        setField("g2hCalculatorFormFields", list);
    };

    return (
        <Accordion
            TransitionProps={{
                mountOnEnter: true,
                unmountOnExit: true,
            }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <DragHandleIcon data-movable-handle />
                <Typography
                    component="h3"
                    variant="h6"
                    color={formSettingsErrorColor}>
                    G2H Calculator
                </Typography>
                {display ? (
                    <VisibityIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                ) : (
                    <VisibityOffIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Box padding={1} margin={1}>
                    <Box marginBottom={3}>
                        <FormControlLabel
                            label="Display G2H Calculator"
                            control={
                                <Switch
                                    color="primary"
                                    id="entry-module-g2hCalculator.display"
                                    name="display"
                                    checked={display}
                                    onChange={onChange}
                                    inputProps={{
                                        "data-label": "Display G2HCalculator",
                                    }}
                                />
                            }
                        />
                    </Box>
                    <Text
                        required
                        name="g2hCalculatorTitle"
                        label="Title"
                        disabled={!isAdmin}
                        hidden={!isAdmin}
                        onChange={onChange}
                        error={!!errors["g2hCalculator.g2hCalculatorTitle"]}
                        errors={errors}
                        value={g2hCalculatorTitle}
                    />
                    <div className={styles.contactUsEditor}>
                        <Editor
                            name="g2hCalculatorText"
                            entry={{ body: g2hCalculatorText }}
                            onChange={body => {
                                onEditorChange("g2hCalculatorText", body);
                            }}
                            toolbarButtons="bold|italic|link|bullet|numbered"
                        />
                    </div>
                    <Boolean
                        name="darkTitleText"
                        value={darkTitleText}
                        label="Use Dark Text?"
                        hint="Display the Title and text in black for better contrast"
                        onChange={onChange}
                    />
                    <Paper>
                        <Box padding={2} margin={2}>
                            <Typography component="h3" variant="h6">
                                Background Image
                            </Typography>
                            <br />
                            <HeroImage
                                isAdmin={isAdmin}
                                name="backgroundImage"
                                value={backgroundImage}
                                collections={["Body"]}
                                onChange={onChange}
                            />
                            <Uploader
                                name="backgroundImageCustom"
                                label="Custom Image"
                                value={backgroundImageCustom}
                                config={{
                                    imagesOnly: true,
                                    inputAcceptTypes: ".jpg, .jpeg, .png",
                                    crop: "16:9",
                                    imageShrink: "2881x2017",
                                }}
                                handleFile={onFileChange}
                                onChange={onChange}
                                logo
                            />
                        </Box>
                    </Paper>
                    <CustomContactForm
                        field="g2hCalculatorFormFields"
                        titleField="g2hCalculatorFormTitle"
                        contactFormTitle={g2hCalculatorFormTitle}
                        contactFormFields={g2hCalculatorFormFields}
                        predefinedInputs={predefinedInputs}
                        errors={errors}
                        isAdmin={isAdmin}
                        onChange={onChange}
                        handleReorderList={handleReorderList}
                        nonRemovableFields={["purchasePrice", "downPayment"]}
                        setField={setField}
                    />
                    <Box>
                        <Paper>
                            <Box padding={2} margin={2}>
                                <Typography component="h3" variant="h6">
                                    Lead/Email Settings
                                </Typography>
                                <Typography component="p" variant="body1">
                                    Information used when form is submitted
                                </Typography>
                                <Text
                                    className={styles.field}
                                    name="g2hCalculatorBammUrl"
                                    label="BAMM URL"
                                    hint="The BAMM URL to capture the lead information on form submission"
                                    disabled={!isAdmin}
                                    hidden={!isAdmin}
                                    onChange={onChange}
                                    value={g2hCalculatorBammUrl}
                                    errors={
                                        !!errors[
                                            "contactUs.g2hCalculatorBammUrl"
                                        ]
                                    }
                                />
                            </Box>
                        </Paper>
                    </Box>
                    <Box>
                        <Paper>
                            <Box padding={2} margin={2}>
                                <Typography
                                    component="h3"
                                    variant="h6"
                                    color={creditInfoErrorColor}>
                                    Calculator Settings
                                </Typography>
                                <Number
                                    required
                                    name="lenderCreditPercentage"
                                    label="Lender Credit Percentage"
                                    hint="Used to calculate lender credit amount"
                                    onChange={onChange}
                                    value={lenderCreditPercentage}
                                    errors={errors}
                                />
                                <Number
                                    required
                                    name="realtorCommissionPercentage"
                                    label="Realtor Commission Percentage"
                                    hint="Used to calculate reatlor commission amount"
                                    onChange={onChange}
                                    value={realtorCommissionPercentage}
                                    errors={errors}
                                />
                                <Number
                                    required
                                    name="realtorCreditPercentage"
                                    label="Realtor Credit Percentage"
                                    hint="Used to calculate realtor credit amount"
                                    select
                                    onChange={onChange}
                                    value={realtorCreditPercentage}
                                    errors={errors}
                                    fullWidth
                                    margin="normal">
                                    <option key="0.1" value="0.1">
                                        0.1
                                    </option>
                                    <option key="0.25" value="0.25">
                                        0.25
                                    </option>
                                </Number>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default G2HCalculator;
