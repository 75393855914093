import React from "react";
import Helmet from "react-helmet";
import Layout from "../../components/Layout";
import Header from "../../components/Header";
import HeroLarge from "../../components/HeroLarge";
import IntroBlock from "../../components/IntroBlock";
import LeadershipTeam from "../../components/LeadershipTeam";
import { HTMLContent } from "../../components/Content";
import salesHero from "./images/hero.jpg";
import { graphql } from "gatsby";

const SalesLeadershipPage = ({ data }) => {
    const { header = "", leaders = [] } = data.sales.nodes[0];
    const layoutHeader = <Header active={["sales-leadership"]} theme="light" />;
    return (
        <Layout hasHero={true} header={layoutHeader}>
            <Helmet>
                <title>
                    Meet The Sales Leadership Team | Bay Equity Home Loans
                </title>
                <meta
                    name="description"
                    content="Bay Equity is made up of a team of the most accomplished sales professionals. Meet the team that’s here to get you home."
                />
            </Helmet>
            <HeroLarge customPhoto={salesHero} />
            <IntroBlock
                header="Meet our sales leadership team"
                par={<HTMLContent content={header}></HTMLContent>}
            />
            <LeadershipTeam team={leaders} />
        </Layout>
    );
};
export default SalesLeadershipPage;

export const query = graphql`
    {
        sales: allLeadership(filter: { id: { eq: "sales" } }) {
            nodes {
                id
                header
                leaders {
                    name
                    title
                    location
                    license
                    photoUrl
                    bio
                }
            }
        }
    }
`;
