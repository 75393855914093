import React from "react";
import NonOriginatorDetail from "../../../../NonOriginatorDetail";

const NonOriginatorPreview = ({ entry }) => {
    if (!entry) return null;

    let { profile, contact } = entry;

    return (
        <div id="preview" style={{ pointerEvents: "auto" }}>
            <NonOriginatorDetail {...entry} {...profile} {...contact} />
        </div>
    );
};

export default NonOriginatorPreview;
