import React from "react";
import Hero from "../Hero/";

import ModuleList from "../ModuleList";
import withSurvey from "../withSurvey";

const BranchDetail = ({
    firestoreId,
    title,
    hero = {},
    logo,
    address1,
    address2,
    address3,
    city,
    state,
    zip,
    lat,
    lon,
    modules = [],
    blogs = [],
    corporateBlogs = [],
    licenseIds = [],
    loanOfficers = [],
    teams = [],
    managers = [],
    staff = [],
    email,
    phone,
    mobilePhone,
    fax,
    facebook,
    twitter,
    linkedin,
    youtube,
    instagram,
    isPreview,
    testimonials: review = [],
    isSurveyInit: isReviewInit,
    hasSurveyErrored: hasReviewErrored,
    stars,
    ratingsCount,
    shouldDisplaySurvey,
    testimonials,
    isSurveyInit,
    hasSurveyErrored,
    profile,
}) => {
    const { heroBackground = "", customPhoto = "", photoAlignment = "center" } =
        hero || {};

    const branch = {
        lat,
        lon,
        address1,
        address2,
        address3,
        city,
        state,
        zip,
        title,
        email,
    };

    const socialIconsProps = {
        facebook,
        twitter,
        linkedin,
        youtube,
        instagram,
    };

    const contactInfo = {
        address1,
        address2,
        address3,
        city,
        state,
        zip,
        phone,
        mobilePhone,
        fax,
        email,
    };

    const moduleListProps = {
        firestoreId,
        modules,
        twitter,
        isPreview,
        blogs,
        corporateBlogs,
        ownerImg: logo,
        title,
        review,
        isReviewInit,
        hasReviewErrored,
        branch,
        blogWord: "our",
        loanOfficers,
        managers,
        staff,
        templateKey: "branch",
        socialIcons: socialIconsProps,
        gallery: { photo: logo },
        licenseIds,
        name: title,
        email,
        phone,
        mobilePhone,
        fax,
        address1,
        address2,
        address3,
        city,
        state,
        zip,
        contactInfo,
        stars,
        ratingsCount,
        shouldDisplaySurvey,
        testimonials,
        profile,
        pageTitle: title,
        pageUrl: "",
        isSurveyInit,
        hasSurveyErrored,
        pageType: "branch",
    };

    let branchLoanOfficers = loanOfficers
        .filter(lo => !!lo && !lo.hidden)
        .map(lo => {
            const emptyLo = {
                slug: "",
                photo: "",
                name: "",
                email: "",
                jobTitle: "",
                licenseIds: "",
            };
            try {
                const {
                    slug = "",
                    gallery,
                    profile: {
                        name = "",
                        email = "",
                        jobTitle = "",
                        licenseIds = [],
                    },
                } = lo;

                const photo = gallery && gallery.photo ? gallery.photo : "";

                return { slug, photo, name, email, jobTitle, licenseIds };
            } catch (e) {
                return emptyLo;
            }
        });

    let branchTeams = teams
        .filter(t => !!t && !t.hidden)
        .map(team => {
            const emptyTeam = {
                slug: "",
                photo: "",
                name: "",
                email: "",
                jobTitle: "",
                licenseIds: "",
            };
            try {
                const {
                    slug = "",
                    profile: {
                        name = "",
                        email = "",
                        licenseIds = [],
                        logo: photo,
                    },
                } = team;

                return { slug, photo, name, email, jobTitle: "", licenseIds };
            } catch (e) {
                return emptyTeam;
            }
        });

    let officers = branchLoanOfficers.concat(branchTeams);
    moduleListProps.loanOfficers = officers;

    return (
        <>
            <Hero
                background={heroBackground}
                customPhoto={customPhoto}
                photoAlignment={photoAlignment}
                compact={true}
            />
            <ModuleList {...moduleListProps} />
        </>
    );
};

export default withSurvey(BranchDetail);
