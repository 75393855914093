import uploadcare from "uploadcare-widget";
import uploadcareTabEffects from "uploadcare-widget-tab-effects";

const USER_AGENT = "NetlifyCMS-Uploadcare-MediaLibrary";
const CDN_BASE_URL = "https://ucarecdn.com";

const defaultConfig = {
    previewStep: true,
    integration: USER_AGENT,
    multiple: false,
};

try {
    if (window) {
        window.UPLOADCARE_LIVE = false;
        window.UPLOADCARE_MANUAL_START = true;
        window.UPLOADCARE_PUBLIC_KEY = process.env.GATSBY_UPLOADCARE_PUBLIC_KEY;
        uploadcare.registerTab("preview", uploadcareTabEffects);
    }
} catch (err) {
    console.log("Uploadcare is not initialized during server-side-render");
}

/**
 * Convert a single url to an Uploadcare file object wrapped in a promise-like
 * object. Group urls that get passed here were not a part of a complete and
 * untouched group, so they'll be uploaded as new images (only way to do it).
 */
function getFile(url) {
    const groupPattern = /~\d+\/nth\/\d+\//;
    const uploaded = url.startsWith(CDN_BASE_URL) && !groupPattern.test(url);
    return uploadcare.fileFrom(uploaded ? "uploaded" : "url", url);
}

/**
 * Convert a url or array/List of urls to Uploadcare file objects wrapped in
 * promises, or Uploadcare groups when possible. Output is wrapped in a promise
 * because the value we're returning may be a promise that we created.
 */
function getFiles(value) {
    if (Array.isArray(value)) {
        const arr = Array.isArray(value) ? value : value.toJS();
        return isFileGroup(arr)
            ? getFileGroup(arr)
            : Promise.all(arr.map(val => getFile(val)));
    }
    return value && typeof value === "string" ? getFile(value) : null;
}

/**
 * Determine whether an array of urls represents an unaltered set of Uploadcare
 * group urls. If they've been changed or any are missing, a new group will need
 * to be created to represent the current values.
 */
function isFileGroup(files) {
    const basePatternString = `~${files.length}/nth/`;
    const mapExpression = (val, idx) =>
        new RegExp(`${basePatternString}${idx}/$`);
    const expressions = Array.from({ length: files.length }, mapExpression);
    return expressions.every(exp => files.some(url => exp.test(url)));
}

/**
 * Returns a fileGroupInfo object wrapped in a promise-like object.
 */
function getFileGroup(files) {
    /**
     * Capture the group id from the first file in the files array.
     */
    const groupId = new RegExp(`^.+/([^/]+~${files.length})/nth/`).exec(
        files[0],
    )[1];

    /**
     * The `openDialog` method handles the jQuery promise object returned by
     * `fileFrom`, but requires the promise returned by `loadFileGroup` to provide
     * the result of it's `done` method.
     */
    return new Promise(resolve =>
        uploadcare.loadFileGroup(groupId).done(group => resolve(group)),
    );
}

/**
 * Open the standalone dialog. A single instance is created and destroyed for
 * each use.
 */
function openDialog(files, config, handleUpload) {
    uploadcare.openDialog(files, config).done(({ promise }) =>
        promise().then(({ cdnUrl, name }) => {
            handleUpload(`${cdnUrl}${encodeURIComponent(name)}`);
        }),
    );
}

export function showDialog(value, instanceConfig = {}, handleUpload) {
    const config = { ...defaultConfig, ...instanceConfig };
    const files = getFiles(value);
    /**
     * Resolve the promise only if it's ours. Only the jQuery promise objects
     * from the Uploadcare library will have a `state` method.
     */
    if (files && !files.state) {
        return files.then(result => openDialog(result, config, handleUpload));
    } else {
        return openDialog(files, config, handleUpload);
    }
}
