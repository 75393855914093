import React from "react";
import TextField from "@material-ui/core/TextField";

const label = "Page Title (Name)";
const inputProps = {
    "data-lpignore": "true",
    "data-label": label,
};

const Title = ({
    isAdmin,
    name = "title",
    hint = "Should be Loan Officer’s name for display, as well as URL slug (e.g. “John Smith” display; “john-smith” slug)",
    value,
    errors,
    disabled = false,
    ...rest
}) => {
    const errorMsg = errors[name];
    const helperText = errorMsg || hint;
    const id = `entry-${name}`;

    return (
        <TextField
            required
            autoComplete="off"
            id={id}
            name={name}
            label={label}
            disabled={!isAdmin || disabled}
            error={!!errorMsg}
            helperText={helperText}
            value={value}
            hidden={!isAdmin}
            fullWidth={true}
            margin="normal"
            inputProps={inputProps}
            {...rest}
        />
    );
};

export default Title;
