import React, { useState, useEffect } from "react";

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Radio from "@material-ui/core/Radio";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { getFirestoreDb } from "../../../../../config/cms.config";
import { scaleCropTo } from "../../../../../lib/ucImage";

import styles from "./styles.module.less";

const db = getFirestoreDb();

const name = "bloghero";

const BlogHero = ({ isAdmin, entry, errors, ...rest }) => {
    const [heroes, setHeroes] = useState([]);
    const value = entry.bloghero;

    useEffect(() => {
        if (heroes.length === 0) {
            db.collection(`heroes`)
                .where("collection", "==", "blog")
                .get()
                .then(querySnapshot => {
                    let heroes = querySnapshot.docs.map(doc => {
                        const { className, collection, uuid, url } = doc.data();
                        return {
                            className,
                            collection,
                            uuid,
                            url,
                        };
                    });
                    setHeroes(heroes);
                });
        }
    }, []);

    const renderHeroes = () => {
        return heroes.map(image => {
            const { className, uuid, url } = image;
            const thumb = scaleCropTo(uuid, 50, 50);
            return (
                <GridListTile key={uuid}>
                    <div>
                        <label htmlFor={uuid}>
                            <img
                                className={styles.img}
                                src={thumb}
                                alt={className}
                                width={50}
                                height={50}
                            />
                            <Radio
                                color="primary"
                                id={uuid}
                                name={name}
                                {...rest}
                                value={url}
                                checked={value === url}
                                inputProps={{
                                    "data-label": "Featured Image",
                                }}
                            />
                        </label>
                    </div>
                </GridListTile>
            );
        });
    };

    return (
        <div className={styles.BlogHero}>
            <FormControl>
                <Typography component="h3" variant="subtitle1">
                    Featured Image
                </Typography>
                <Box margin={1}>
                    <GridList
                        cellHeight={100}
                        cols={6}
                        className={styles.gridlist}>
                        {renderHeroes()}
                    </GridList>
                </Box>
                <FormHelperText>
                    Add a featured image to your blog post.
                </FormHelperText>
            </FormControl>
        </div>
    );
};

export default BlogHero;
