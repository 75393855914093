import React, { useState, useEffect } from "react";
import { groupBy } from "lodash";

import FormControl from "@material-ui/core/FormControl";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Radio from "@material-ui/core/Radio";
import Box from "@material-ui/core/Box";

import Typography from "@material-ui/core/Typography";

import { getFirestoreDb } from "../../../../../config/cms.config";
import { scaleCropTo } from "../../../../../lib/ucImage";

import styles from "./styles.module.less";

const db = getFirestoreDb();

const name = "hero.heroBackground";

const HeroImage = ({
    isAdmin,
    value,
    errors,
    collections = ["Generic", "Regional", "Site Specific"],
    ...rest
}) => {
    const [heroes, setHeroes] = useState([]);
    const grouped = groupBy(heroes, "collection");
    const groups = Object.keys(grouped);

    useEffect(() => {
        if (heroes.length === 0) {
            db.collection(`heroes`)
                .where("hidden", "==", false)
                .get()
                .then(querySnapshot => {
                    let heroes = querySnapshot.docs.map(doc => {
                        const { className, collection, uuid } = doc.data();
                        return {
                            className,
                            collection,
                            uuid,
                        };
                    });
                    setHeroes(
                        heroes.filter(h => collections.includes(h.collection)),
                    );
                });
        }
    }, []);

    const renderCollection = collection => {
        let images = grouped[collection];
        return images.map(image => {
            const { className, uuid } = image;
            const thumb = scaleCropTo(uuid, 50, 50);
            return (
                <GridListTile key={uuid} className={styles.gridlisttile}>
                    <div>
                        <label htmlFor={uuid}>
                            <img
                                className={styles.img}
                                src={thumb}
                                alt={className}
                                width={50}
                                height={50}
                            />
                            <Radio
                                color="primary"
                                id={uuid}
                                name={name}
                                {...rest}
                                value={className}
                                checked={value === className}
                                inputProps={{
                                    "data-label": "Hero Image",
                                }}
                            />
                        </label>
                    </div>
                </GridListTile>
            );
        });
    };

    return (
        <div className={styles.HeroImage}>
            {groups.map((group, i) => {
                return (
                    <FormControl key={group}>
                        {collections.length > 1 && (
                            <Typography component="h3" variant="subtitle1">
                                {group}
                            </Typography>
                        )}
                        <Box margin={1}>
                            <GridList
                                cellHeight={100}
                                cols={6}
                                className={styles.gridlist}>
                                {renderCollection(group)}
                            </GridList>
                        </Box>
                    </FormControl>
                );
            })}
        </div>
    );
};

export default HeroImage;
