import React from "react";
import { noop } from "lodash";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { List, arrayMove } from "react-movable";

import DragHandleIcon from "@material-ui/icons/DragHandle";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import FormHelperText from "@material-ui/core/FormHelperText";

import VisibityIcon from "@material-ui/icons/Visibility";
import VisibityOffIcon from "@material-ui/icons/VisibilityOff";

import Uploader from "../../Uploader";
import Editor from "../../Editor";

import predefinedIcons from "../../../../../AboutG2HModule/lib/predefinedIcons.json";

import { isEmpty } from "lodash";

import styles from "../widgets.module.less";

const type = "aboutG2HCustomIcons";
const widget = "aboutG2H";
const field = "display"; // used for saving widget data
const name = `${widget}.${field}`; // used for validation and error messages
const label = "Display About G2H?";
const id = `entry-module-${name}`;

const AboutG2H = ({
    widget,
    onChange = noop,
    setField = noop,
    onFileChange = noop,
    onEditorChange = noop,
    errors,
    ...rest
}) => {
    const {
        display,
        aboutG2HHtml,
        aboutG2HCobrandUrl,
        aboutG2HIndustryIcons,
    } = widget;

    const handleChangeList = list => {
        setField("aboutG2HIndustryIcons", list);
    };

    return (
        <Accordion
            TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
            className={styles.Widget}>
            <AccordionSummary
                classes={{ content: styles.TitleBar }}
                expandIcon={<ExpandMoreIcon />}>
                <DragHandleIcon data-movable-handle />
                <Typography component="h4" variant="h6">
                    About G2H
                </Typography>
                {display ? (
                    <VisibityIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                ) : (
                    <VisibityOffIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                    <FormControlLabel
                        label={label}
                        control={
                            <Switch
                                color="primary"
                                id={id}
                                name={field}
                                checked={display}
                                onChange={onChange}
                                inputProps={{
                                    "data-label": label,
                                }}
                            />
                        }
                        style={{ paddingBottom: "20px" }}
                    />
                    <div className={styles.contactUsEditor}>
                        <Editor
                            name="aboutG2HHtml"
                            entry={{ body: aboutG2HHtml }}
                            onChange={body => {
                                onEditorChange("aboutG2HHtml", body);
                            }}
                            toolbarButtons="bold|italic|link|bullet|numbered"
                        />
                    </div>
                    <Box mt={4}>
                        <Typography component="h3" variant="h6">
                            Cobrand Logo
                        </Typography>
                        <Box>
                            <Uploader
                                name="aboutG2HCobrandUrl"
                                label="Photo"
                                hint="Upload cobrand photo (images must be in jpg/png format)"
                                value={aboutG2HCobrandUrl}
                                config={{
                                    imagesOnly: true,
                                    inputAcceptTypes: ".jpg, .jpeg, .png",
                                }}
                                handleFile={onFileChange}
                                onChange={onChange}
                                logo
                            />
                        </Box>
                    </Box>
                    <Box>
                        <Typography component="h3" variant="h6">
                            Industry Icons
                        </Typography>
                        <Typography component="p" variant="body1">
                            Select the icons that will be displayed.
                        </Typography>
                        <IndustryIconsList
                            icons={aboutG2HIndustryIcons}
                            onChangeList={handleChangeList}
                            errors={errors}
                        />
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

const IndustryIconsList = ({ icons, errors, onChangeList = noop }) => {
    const errorMsg = errors[field];
    let helperText = errorMsg || "";

    const getHandler = index => {
        return e => {
            const value =
                e.target.type === "checkbox"
                    ? e.target.checked
                    : e.target.value;
            const name = e.target.name;
            icons[index][name] = value;
            onChangeList(icons);
        };
    };

    const getDeleter = index => {
        return e => {
            icons.splice(index, 1);
            onChangeList(icons);
        };
    };

    const getHandlerFile = index => {
        return (name, value, label) => {
            icons[index][name] = value;
            onChangeList(icons);
        };
    };

    const add = () => {
        icons.push({ type: "" });
        onChangeList(icons);
    };

    // setup field type options
    let typeOptions = [];
    for (let key in predefinedIcons.icons) {
        let icon = predefinedIcons.icons[key];
        typeOptions.push({
            id: key,
            label: icon.label,
        });
    }
    typeOptions.push({
        id: "custom",
        label: "Custom Icon",
    });

    return (
        <Box>
            <Paper>
                <Box padding={2} margin={2}>
                    <List
                        values={icons}
                        onChange={({ oldIndex, newIndex }) => {
                            const reordered = arrayMove(
                                icons,
                                oldIndex,
                                newIndex,
                            );
                            onChangeList(reordered);
                        }}
                        renderList={({ children, props, isDragged }) => {
                            return (
                                <Box
                                    marginTop={2}
                                    {...props}
                                    errors={props.errors}
                                    missing={props.missing}
                                    style={{
                                        cursor: isDragged
                                            ? "grabbing"
                                            : "inherit",
                                    }}>
                                    {children}
                                </Box>
                            );
                        }}
                        renderItem={({ value, props, isDragged, index }) => {
                            const onChange = getHandler(index);
                            const handleClick = getDeleter(index);
                            const onFileChange = getHandlerFile(index);

                            return (
                                <div {...props}>
                                    <IconListItem
                                        index={index}
                                        row={value}
                                        type={value.type}
                                        required={value.required}
                                        isDragged={isDragged}
                                        onChange={onChange}
                                        onDelete={handleClick}
                                        onFileChange={onFileChange}
                                        typeOptions={typeOptions}
                                        icons={icons}
                                    />
                                </div>
                            );
                        }}
                    />
                    <Box className={styles.add} marginTop={2}>
                        <IconButton onClick={add} margin={0}>
                            <AddIcon />
                        </IconButton>
                    </Box>
                    <FormHelperText>{helperText}</FormHelperText>
                </Box>
            </Paper>
        </Box>
    );
};

const IconListItem = ({
    isDragged = false,
    index,
    icons,
    row,
    typeOptions,
    type = "",
    onChange = noop,
    onDelete = noop,
    onFileChange = noop,
    errors = [],
}) => {
    const { label = "", iconUrl = "" } = row;
    const cls = isDragged ? `${styles.row} ${styles.isDragged}` : styles.row;

    return (
        <Box
            className={cls}
            marginTop={1}
            style={{
                cursor: isDragged ? "grabbing" : "inherit",
            }}>
            <Grid container>
                <Grid item xs>
                    <DragHandleIcon data-movable-handle />
                    <TextField
                        className={styles.field}
                        name="type"
                        select
                        value={type}
                        onChange={onChange}
                        inputProps={{
                            "data-label": "Icon Type",
                        }}>
                        {typeOptions.map(opt => (
                            <option key={opt.id} value={opt.id}>
                                {opt.label}
                            </option>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <Box display="flex" justifyContent="flex-end">
                        <IconButton onClick={onDelete}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
            {type === "custom" && (
                <Box>
                    <CustomIcon
                        index={index}
                        label={label}
                        iconUrl={iconUrl}
                        onChange={onChange}
                        onFileChange={onFileChange}
                        icons={icons}
                    />
                </Box>
            )}
        </Box>
    );
};

const CustomIcon = ({
    index,
    icons,
    onChange = noop,
    onFileChange = noop,
    label = "",
    iconUrl = "",
}) => {
    return (
        <Box marginLeft={"2em"}>
            <Box maxWidth={200}>
                <Uploader
                    name="iconUrl"
                    label="Icon"
                    hint="Upload icon (images must be in jpg/png format)"
                    value={iconUrl}
                    config={{
                        imagesOnly: true,
                        inputAcceptTypes: ".jpg, .jpeg, .png",
                    }}
                    handleFile={onFileChange}
                    onChange={onChange}
                    logo
                />
            </Box>
            <Box marginTop={1}>
                <TextField
                    className={styles.field}
                    name="label"
                    value={label}
                    label="Label"
                    onChange={onChange}
                    inputProps={{
                        "data-label": "Label",
                    }}></TextField>
            </Box>
        </Box>
    );
};

export default AboutG2H;
