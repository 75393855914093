import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Router } from "@reach/router";
import { navigate } from "gatsby";
import { get, set, filter } from "lodash";

import Collection from "./Collection";
import Entry, { NewEntry } from "./Entry";
import Redirects from "./Redirects";
import DefaultText from "./DefaultText";
import StaticText from "./StaticText";
import SalesLeadership from "./SalesLeadership";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import Button from "@material-ui/core/Button";

import { MuiThemeProvider } from "@material-ui/core/styles";

import { createMuiTheme } from "@material-ui/core/styles";

import { getFirestoreDb, timestamp } from "../../config/cms.config";

import logo from "./images/cms-logo.png";

import {
    collectionMap,
    defaultCollectionState,
    newEntries,
} from "../../components/Admin/lib/defaults";

import AuthProvider, { AuthContext } from "../../components/Admin/AuthProvider";

import styles from "./styles.module.less";

const db = getFirestoreDb();

const palette = {
    primary: { main: "#182539" },
    secondary: { main: "#FF8A00" },
};
const themeName = "Bay Equity Material Theme";

const theme = createMuiTheme({ palette, themeName });

class Admin extends Component {
    static contextType = AuthContext;

    state = {
        isAdmin: false,
        isAuthenticated: false,
        ...defaultCollectionState,
    };

    handleLogin = () => {
        this.context.login();
    };

    handleLogout = async () => {
        await this.context.logout();
        navigate("/admin");
    };

    getCollectionMeta = collection => {
        let meta = get(defaultCollectionState, `${collection}.meta`, {});
        return meta;
    };

    getUpdatedBy = () => {
        let { name, email } = this.context.user;
        return `${name} <${email}>`;
    };

    setCollectionLoaded = collection => {
        this.setState({
            [collection]: {
                loaded: true,
            },
        });
    };

    fetchCollection = async (
        collection,
        status = "published",
        where = null,
        orderBy = null,
    ) => {
        // get cached docs
        // not doibgn this for now - running into caching problems
        // let exists = get(this.state, `${collection}.${status}`, []);
        // if (exists.length) {
        //     return exists;
        // }
        // load from db and store in state
        let mapped = collectionMap[collection];
        let ref = db.collection(`entries/${mapped}/${status}`);
        // get filter for collection (blogs)
        let filter = get(defaultCollectionState, `${collection}.filter`, null);
        if (filter) {
            let { field, operator, value } = filter;
            ref = ref.where(field, operator, value);
        }
        // apply where clause if present
        if (where) {
            let { field, operator, value } = where;
            ref = ref.where(field, operator, value);
        }
        if (orderBy) {
            let { field, direction } = orderBy;
            ref = ref.orderBy(field, direction);
        }
        let querySnapshot = await ref.get();
        let docs = querySnapshot.docs.map(doc => {
            let { id } = doc;
            let data = doc.data();
            return {
                id,
                ...data,
            };
        });
        // update doc list in place, leaving other collectiosn alone
        set(this.state, `${collection}.${status}`, docs);
        this.setState(this.state);
        return docs;
    };

    fetchDocument = async (collection, id) => {
        // get draft
        let ref, doc;
        let mapped = collectionMap[collection];
        let allNewEntries = newEntries(this.props.defaultTextItems);
        let defaultEntry = allNewEntries[collection];
        ref = db.doc(`entries/${mapped}/draft/${id}`);
        doc = await ref.get();
        if (doc.exists) {
            const { id } = doc;
            let data = doc.data();
            // ensure defaults for entry type are set at fetch
            return Object.assign({}, defaultEntry, {
                id,
                draft: true,
                ...data,
            });
        }
        // get published
        ref = db.doc(`entries/${mapped}/published/${id}`);
        doc = await ref.get();
        if (doc.exists) {
            const { id } = doc;
            let data = doc.data();
            // ensure defaults for entry type are set at fetch
            return Object.assign({}, defaultEntry, {
                id,
                draft: false,
                ...data,
            });
        }
        return null;
    };

    updateDocumentInState = (document, collection, status = "published") => {
        const { id } = document;
        let found = false;
        let docs = get(this.state, `${collection}.${status}`, []);
        docs.forEach(doc => {
            if (doc.id === id) {
                Object.assign(doc, document);
                found = true;
            }
        });
        if (!found) {
            docs.push(document);
        }
        this.setState(this.state);
    };

    deleteDocumentFromState = (document, collection, status = "published") => {
        const { id } = document;
        const path = `${collection}.${status}`;
        let docs = get(this.state, path, []);
        let filtered = filter(docs, doc => doc.id !== id);
        set(this.state, path, filtered);
        this.setState(this.state);
    };

    /**
     * logLabel can be changed to mark ready for review
     */
    saveDraft = async (doc, collection, id, logLabel = "save draft") => {
        try {
            let mapped = collectionMap[collection];
            doc.updatedOn = timestamp();
            doc.updatedBy = this.getUpdatedBy();
            doc.draft = true;
            this.updateDocumentInState(doc, collection, "draft");
            await db.doc(`entries/${mapped}/draft/${id}`).set(doc);
            await this.logChange(logLabel, collection, id);
        } catch (err) {
            console.log(err);
        }
    };

    discardDraft = async (doc, collection, id) => {
        try {
            let mapped = collectionMap[collection];
            await this.logChange("discard draft", collection, id);
            await db.doc(`entries/${mapped}/discardedDrafts/${id}`).set(doc);
            await db.doc(`entries/${mapped}/draft/${id}`).delete();
            this.deleteDocumentFromState(doc, collection, "draft");
        } catch (err) {
            console.log(err);
        }
    };

    deleteDocument = async (doc, collection, id) => {
        try {
            let mapped = collectionMap[collection];
            await this.logChange("delete", collection, id);
            await db.doc(`entries/${mapped}/published/${id}`).delete();
            await db.doc(`entries/${mapped}/deleted/${id}`).set(doc);
            this.deleteDocumentFromState(doc, collection, "published");
        } catch (err) {
            console.log(err);
        }
    };

    publishDocument = async (doc, collection, id) => {
        try {
            let mapped = collectionMap[collection];
            doc.updatedOn = timestamp();
            doc.updatedBy = this.getUpdatedBy();
            doc.draft = false;
            this.updateDocumentInState(doc, collection, "published");
            this.deleteDocumentFromState(doc, collection, "draft");
            await db.doc(`entries/${mapped}/published/${id}`).set(doc);
            await this.discardDraft(doc, collection, id);
            await this.logChange("publish", collection, id);
        } catch (err) {
            console.log(err);
        }
    };

    publishDocument2 = async (doc, collection, id) => {
        try {
            doc.updatedOn = timestamp();
            doc.updatedBy = this.getUpdatedBy();
            await db.doc(`${collection}/${id}`).set(doc);
        } catch (err) {
            console.log(err);
        }
    };

    logChange = async (action = "save draft", collection, id) => {
        let mapped = collectionMap[collection];
        let log = {
            action,
            collection,
            id,
            updatedBy: this.getUpdatedBy(),
            updatedOn: timestamp(),
        };
        let logId = new Date().toISOString();
        await db.doc(`entries/${mapped}/changes/${logId}`).set(log);
    };

    publishSite = async () => {
        return await fetch(process.env.GATSBY_PUBLISH_SITE_WEBHOOK, {
            method: "POST",
        });
    };

    fetchRedirects = async () => {
        let querySnapshot = await db
            .collection("redirects")
            .orderBy("sort", "asc")
            .get();
        let redirects = querySnapshot.docs.map(doc => {
            let data = doc.data();
            return {
                id: doc.id,
                ...data,
            };
        });
        return redirects;
    };

    batchRedirects = async redirects => {
        const batch = db.batch();
        console.log("Batch processing redirects…");
        redirects.forEach(redirect => {
            const { id, deleted, dirty } = redirect;
            const path = `redirects/${id}`;
            const ref = db.doc(path);
            if (deleted) {
                console.log("deleting", id);
                batch.delete(ref);
            } else if (dirty) {
                delete redirect.dirty; // freshly edited
                delete redirect.isNew; // freshly created
                console.log("saving", id);
                batch.set(ref, redirect);
            }
        });
        await batch.commit();
    };

    fetchDefaultTextItems = async () => {
        let querySnapshot = await db
            .collection("default-text")
            .orderBy("title", "asc")
            .get();
        let defaultTextItems = querySnapshot.docs.map(doc => {
            let data = doc.data();
            return {
                id: doc.id,
                ...data,
            };
        });
        return defaultTextItems;
    };

    batchDefaultTextItems = async defaultTextItems => {
        const batch = db.batch();
        defaultTextItems.forEach(defaultTextItem => {
            const { id, dirty } = defaultTextItem;
            const path = `default-text/${id}`;
            const ref = db.doc(path);
            if (dirty) {
                delete defaultTextItem.dirty; // freshly edited
                delete defaultTextItem.isNew;
                batch.set(ref, defaultTextItem);
            }
        });
        await batch.commit();
    };

    fetchStaticTextItems = async () => {
        let querySnapshot = await db
            .collection("static-text")
            .orderBy("adminOrderBy", "asc")
            .get();
        let staticTextItems = querySnapshot.docs.map(doc => {
            let data = doc.data();
            return {
                id: doc.id,
                ...data,
            };
        });
        return staticTextItems;
    };

    batchStaticTextItems = async staticTextItems => {
        const batch = db.batch();
        staticTextItems.forEach(staticTextItem => {
            const { id, dirty } = staticTextItem;
            const path = `static-text/${id}`;
            const ref = db.doc(path);
            if (dirty) {
                delete staticTextItem.dirty; // freshly edited
                delete staticTextItem.isNew;
                batch.set(ref, staticTextItem);
            }
        });
        await batch.commit();
    };

    renderNoAuth() {
        return (
            <div className={styles.NoAuth}>
                <div>
                    <img src={logo} alt="Bay Equity" width="300" height="160" />
                </div>
                <Button onClick={this.handleLogin}>Log in</Button>
            </div>
        );
    }

    render() {
        const { isAdmin, isAuthenticated, user } = this.context;
        if (!isAuthenticated) {
            return this.renderNoAuth();
        }
        const { email } = user;

        const collectionActions = {
            getCollectionMeta: this.getCollectionMeta,
            fetchCollection: this.fetchCollection,
        };

        const entryActions = {
            fetchDocument: this.fetchDocument,
            fetchCollection: this.fetchCollection,
            saveDraft: this.saveDraft,
            discardDraft: this.discardDraft,
            deleteDocument: this.deleteDocument,
            publishDocument: this.publishDocument,
            publishSite: this.publishSite,
        };

        const redirectActions = {
            publishSite: this.publishSite,
            fetchRedirects: this.fetchRedirects,
            batchRedirects: this.batchRedirects,
        };

        const defaultTextActions = {
            publishSite: this.publishSite,
            fetchDefaultTextItems: this.fetchDefaultTextItems,
            batchDefaultTextItems: this.batchDefaultTextItems,
        };

        const staticTextActions = {
            publishSite: this.publishSite,
            fetchStaticTextItems: this.fetchStaticTextItems,
            batchStaticTextItems: this.batchStaticTextItems,
        };

        const salesLeadershipActions = {
            publishSite: this.publishSite,
            publishDocument: this.publishDocument2,
        };

        return (
            <div className={styles.Admin}>
                <Helmet>
                    <link
                        rel="stylesheet"
                        href={`https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap`}
                    />
                    <link
                        rel="stylesheet"
                        href="https://fonts.googleapis.com/icon?family=Material+Icons"
                    />
                </Helmet>
                <Router basepath="/admin">
                    <Collection
                        default
                        isAdmin={isAdmin}
                        email={email}
                        isAuthenticated={isAuthenticated}
                        logout={this.handleLogout}
                        defaultTextItems={this.props.defaultTextItems}
                        {...collectionActions}
                    />
                    <Collection
                        path="/collections/:collection"
                        isAdmin={isAdmin}
                        email={email}
                        isAuthenticated={isAuthenticated}
                        logout={this.handleLogout}
                        defaultTextItems={this.props.defaultTextItems}
                        {...collectionActions}
                    />
                    <NewEntry
                        path="/collections/:collection/new"
                        isAdmin={isAdmin}
                        isNew={true}
                        email={email}
                        user={user}
                        isAuthenticated={isAuthenticated}
                        logout={this.handleLogout}
                        defaultTextItems={this.props.defaultTextItems}
                        {...entryActions}
                    />
                    <Entry
                        path="/collections/:collection/entries/:id"
                        isAdmin={isAdmin}
                        isNew={false}
                        email={email}
                        user={user}
                        isAuthenticated={isAuthenticated}
                        logout={this.handleLogout}
                        defaultTextItems={this.props.defaultTextItems}
                        {...entryActions}
                    />
                    {isAdmin && (
                        <Redirects
                            path="/redirects"
                            isAdmin={isAdmin}
                            {...redirectActions}
                        />
                    )}
                    {isAdmin && (
                        <DefaultText
                            path="/default-text"
                            isAdmin={isAdmin}
                            {...defaultTextActions}
                        />
                    )}
                    {isAdmin && (
                        <StaticText
                            path="/static-text"
                            isAdmin={isAdmin}
                            {...staticTextActions}
                        />
                    )}
                    {isAdmin && (
                        <SalesLeadership
                            path="/sales-leadership"
                            {...salesLeadershipActions}
                        />
                    )}
                </Router>
            </div>
        );
    }
}

export default props => (
    <AuthProvider>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <MuiThemeProvider theme={theme}>
                <Admin {...props} />
            </MuiThemeProvider>
        </MuiPickersUtilsProvider>
    </AuthProvider>
);
