import React, { useState, useEffect } from "react";

import { noop, orderBy, cloneDeep } from "lodash";
import createSlug from "../../../../../lib/createSlug";

import { getDocumentByRef, toHtml } from "../../../../../lib/helpers";
import { getDataByPageType } from "../../../../SplashDetail/lib/helpers";

import SplashDetail from "../../../../SplashDetail";

/**
 *
 * Flatten an entry for use in SplashDetail
 * Fetch loan officer, team, branch or non-Originator as needed from refs
 * Fetch lo blogs and corporate blogs
 */
const SplashPagePreview = ({ fetchCollection = noop, entry }) => {
    const [pageData, setPageData] = useState({});
    const [news, setNews] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [socialId, setSocialId] = useState("");

    useEffect(() => {
        (async () => {
            let data = await mapPageData(entry);
            data.isPreview = true;
            setPageData(data);
        })();
    }, [entry]);

    useEffect(() => {
        // set social_id
        let { modules } = entry;
        const { social_id } =
            (modules &&
                modules.find(module => module.type === "testimonials")) ||
            {};
        setSocialId(social_id);

        (async () => {
            // set corporate blogs
            let corporateBlogs = await fetchCollection(
                "corporateBlog",
                "published",
            );
            let corpSorted = orderBy(corporateBlogs, ["date"], ["desc"]);
            let itemsCorp = corpSorted.slice(0, 3).map(item => {
                item.date = item.date.toDate();
                return item;
            });
            setNews(itemsCorp);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            // set LO blogs
            let blogs = [];
            if (entry.loanOfficer && pageData.profile) {
                let loBlogs = await fetchCollection("blog", "published");
                let loSorted = orderBy(loBlogs, ["date"], ["desc"]);
                let loFiltered = loSorted.filter(
                    blog => blog.owner === pageData.profile.email,
                );
                blogs = loFiltered.slice(0, 3).map(item => {
                    item.date = item.date.toDate();
                    return item;
                });
                // blogs = loFiltered.slice(0, 3);
            }
            setBlogs(blogs);
        })();
    }, [entry.loanOfficer]);

    if (!entry) return null;

    return (
        <div id="preview" style={{ pointerEvents: "auto" }}>
            <SplashDetail
                blogs={blogs}
                corporateBlogs={news}
                social_id={socialId}
                {...pageData.profile}
                {...pageData.contact}
                {...{ ...cloneDeep(pageData), firestoreId: entry.id }}
            />
        </div>
    );
};

export default SplashPagePreview;

/**
 *
 * Flatten an entry for use in SplashDetail
 * Fetch loan-officer, branch and team from refs
 */
const mapPageData = async entry => {
    let pageData = { ...entry };

    delete pageData.name; // Remove Splash page entries that conflict with
    delete pageData.email; // individual LO/Team/Branch/NO properties

    // don't need scripts for preview
    pageData.headBeginScript = [];
    pageData.headEndScript = [];
    pageData.bodyBeginScript = [];
    pageData.bodyEndScript = [];

    // loan-officer selected, build out based on this...
    if (entry.loanOfficer) {
        let lo = await getDocumentByRef(entry.loanOfficer);
        if (lo) {
            pageData.loanOfficer = lo; //pick(lo, splashLoFields);
            let loBranch = await getDocumentByRef(lo.profile.branch);
            if (!loBranch) {
                console.log(
                    `Error: loan officer "${lo.profile.name}" is missing a branch`,
                );
                return null;
            }
            pageData.loanOfficer.profile.branch = loBranch; //pick(loBranch, splashBranchFields,);
            let loTeam = await getDocumentByRef(lo.team);
            if (loTeam) {
                loTeam.slug = createSlug(loTeam.urlslug, loTeam.title);
                pageData.loanOfficer.team = loTeam;
            }
            pageData.templateKey = "loan-officer";
            pageData.blogWord = "my";
            pageData.ownerSlug = createSlug(lo.urlslug, lo.title);
        } else {
            pageData.loanOfficer = null;
            console.log(
                `Error: loan officer "${entry.loanOfficer.id}" is missing. Set to null`,
            );
        }
    } else if (entry.team) {
        let team = await getDocumentByRef(entry.team);
        if (team) {
            pageData.team = team; // pick(team, splashTeamFields);
            let teamBranch = await getDocumentByRef(team.profile.branch);
            if (!teamBranch) {
                console.log(
                    `Error: team "${team.profile.name}" is missing a branch`,
                );
                return null;
            }
            pageData.templateKey = "team";
            pageData.blogWord = "our";
            pageData.ownerSlug = createSlug(team.urlslug, team.title);
            pageData.team.profile.branch = teamBranch; // pick(teamBranch, splashBranchFields);
            await getExtraData(pageData.team);
        } else {
            pageData.team = null;
            console.log(
                `Error: team "${entry.team.id}" is missing. Set to null`,
            );
        }
    } else if (entry.branch) {
        let branch = await getDocumentByRef(entry.branch);
        if (branch) {
            pageData.branch = branch;
            pageData.templateKey = "branch";
            pageData.blogWord = "our";
            pageData.ownerSlug = createSlug(branch.urlslug, branch.title);
            await getExtraData(pageData.branch);
        } else {
            pageData.branch = null;
            console.log(
                `Error: branch "${entry.branch.id}" is missing. Set to null`,
            );
        }
    } else if (entry.nonOriginator) {
        let no = await getDocumentByRef(entry.nonOriginator);
        if (no) {
            pageData.nonOriginator = no;
            pageData.templateKey = "non-originator";
            pageData.blogWord = "my";
        } else {
            pageData.nonOriginator = null;
            console.log(
                `Error: non-originator "${entry.nonOriginator.id}" is missing. Set to null`,
            );
        }
    }

    // Don't overwrite page modules with those from selected LO, Team, etc.
    let { modules, ...pageTypeData } = getDataByPageType(pageData);

    return { ...pageData, ...pageTypeData };
};

async function getExtraData(collection) {
    if (Array.isArray(collection.loanOfficers)) {
        let promisesPromises = collection.loanOfficers.map(
            async loanOfficer => {
                if (loanOfficer && loanOfficer.get) {
                    let lo = await getDocumentByRef(loanOfficer);
                    return lo;
                }
                return null;
            },
        );
        let loanOfficers = await Promise.all(promisesPromises);
        collection.loanOfficers = loanOfficers;
    } else {
        collection.loanOfficers = [];
    }

    if (Array.isArray(collection.teams)) {
        let promisesPromises = collection.teams.map(async team => {
            if (team && team.get) {
                let lo = await getDocumentByRef(team);
                return lo;
            }
            return null;
        });
        let teams = await Promise.all(promisesPromises);
        collection.teams = teams;
    } else {
        collection.teams = [];
    }

    // ensure managers and staff are set as arrays
    collection.staff = Array.isArray(collection.staff) ? collection.staff : [];
    collection.managers = Array.isArray(collection.managers)
        ? collection.managers
        : [];
}
