import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { mapBlogs } from "../../lib/utility";
import BlogRoll from "../../components/BlogRoll";
import Layout from "../../components/Layout";
import Header from "../../components/Header";
import ProductHero from "../../components/ProductHero";
import FindLO from "../../components/FindLO";
import Calculators from "../../components/Calculators";
import ProductDetails from "../../components/ProductDetails";
import StaticText from "../../components/StaticText";

import background from "../../images/fhaLoanbg.jpg";
import icon from "./Icons_FHA_active.png";

import styles from "./styles.module.less";

const FHALoan = ({ data, staticTextItems }) => {
    const header = <Header active={["fha-loan"]} />;

    const { corpBlogs } = data;
    const { edges: corpBlobPosts = [] } = corpBlogs || {};
    const corporateBlogs = mapBlogs(corpBlobPosts);

    const intro = "FHA Loans";
    const subhead = <StaticText id="fha-loan-header" items={staticTextItems} />;

    return (
        <Layout hasHero={true} header={header}>
            <Helmet>
                <title>FHA Loan - FHA Home Loans | Bay Equity Home Loans</title>
                <meta
                    name="description"
                    content="A Federal Housing Association (FHA) Loan is a government insured loan that helps people buy and refinance real estate. Want to find out if you qualify? Contact us today."
                />
                <meta
                    name="keywords"
                    content="fha loan,fha loan requirements,fha loan calculator,what is a fha loan,fha home loan,fha loan vs conventional loan,fha loan rates,fha loan income requirements,how to qualify for a fha loan,how to apply for a fha loan"
                />
                <script
                    src={`https://embed.signalintent.com/js/embedded.js?org-guid=${process.env.GATSBY_SIGNAL_INTENT_ORG_GUID}`}></script>
            </Helmet>
            <ProductHero
                header={intro}
                customPhoto={background}
                icon={icon}
                subhead={subhead}
                alt="FHA Loans | Bay Equity Home Loans"
            />
            <ProductDetails title={"What is an FHA loan?"} border={true}>
                <div className={styles.detailsItem}>
                    <StaticText id="fha-loan-what-is" items={staticTextItems} />
                </div>
            </ProductDetails>
            <ProductDetails
                title={"What are the benefits of an FHA loan?"}
                border={true}>
                <div className={styles.detailsItem}>
                    <StaticText
                        id="fha-loan-benefits"
                        items={staticTextItems}
                    />
                </div>
            </ProductDetails>
            <ProductDetails
                title={"What are the current FHA loan rates?"}
                border={true}>
                <div className={styles.detailsItem}>
                    <StaticText
                        id="fha-loan-current-rates"
                        items={staticTextItems}
                    />
                </div>
            </ProductDetails>
            <ProductDetails title={"How do I qualify for an FHA loan?"}>
                <div className={styles.detailsItem}>
                    <StaticText id="fha-loan-qualify" items={staticTextItems} />
                </div>
            </ProductDetails>
            <Calculators
                showCalculatorMortgage={true}
                showCalculatorRentVsBuy={true}
                showCalculatorHomeAffordability={true}
                showCalculatorRequiredIncome={true}
            />
            <FindLO />
            {corporateBlogs.length >= 3 && (
                <BlogRoll
                    corporateBlogs={corporateBlogs}
                    blogs={corporateBlogs}
                    showBlogs={true}
                    word="our"
                    url="fha-loans"
                />
            )}
        </Layout>
    );
};

export default FHALoan;

export const pageQuery = graphql`
    query FHALoanQuery {
        corpBlogs: allBlog(
            sort: { fields: [date], order: DESC }
            filter: { type: { eq: "corporate" }, tags: { in: ["FHA Loans"] } }
        ) {
            edges {
                node {
                    id
                    html
                    slug
                    title
                    date
                    feature
                    categories
                    tags
                    image
                    author {
                        slug
                        profile {
                            name
                        }
                        gallery {
                            photo
                        }
                    }
                    date
                    feature
                }
            }
        }
    }
`;
