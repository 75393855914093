import React from "react";
import TextField from "@material-ui/core/TextField";

import defaultSocialMedia from "../../../../../lib/defaultSocialMedia.json";

const name = "contact.twitter";
const hint = `Enter the full URL for your X profile like ${defaultSocialMedia.twitter}`;
const label = "X Profile URL";

const Twitter = ({ isAdmin, entry, errors, ...rest }) => {
    const errorMsg = errors[name];
    const value = entry.contact.twitter;
    const helperText = errorMsg || hint;
    const id = `entry-${name}`;

    return (
        <TextField
            autoComplete="off"
            id={id}
            name={name}
            label={label}
            error={!!errorMsg}
            helperText={helperText}
            value={value || defaultSocialMedia.twitter}
            fullWidth={true}
            margin="normal"
            inputProps={{
                "data-lpignore": "true",
                "data-label": label,
            }}
            {...rest}
        />
    );
};

export default Twitter;
