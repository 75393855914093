import React from "react";
import TextField from "@material-ui/core/TextField";

const Email = ({
    name = "email",
    hint = "",
    label = "Contact email",
    isAdmin,
    value: inValue,
    entry = null,
    errors,
    ...rest
}) => {
    const errorMsg = errors[name];
    const value = entry ? entry[name] : inValue;
    let helperText = errorMsg || hint;
    const id = `entry-${name}`;
    const inputProps = {
        readOnly: !isAdmin,
        "data-lpignore": "true",
        "data-label": label,
    };

    return (
        <TextField
            required
            autoComplete="off"
            id={id}
            name={name}
            label={label}
            error={!!errorMsg}
            helperText={helperText}
            value={value}
            inputProps={inputProps}
            fullWidth={true}
            margin="normal"
            {...rest}
        />
    );
};

export default Email;
