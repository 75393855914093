import React from "react";

import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import DragHandleIcon from "@material-ui/icons/DragHandle";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import AttachmentIcon from "@material-ui/icons/AttachFile";
import LinkIcon from "@material-ui/icons/Link";
import DeleteIcon from "@material-ui/icons/Delete";

import VisibityIcon from "@material-ui/icons/Visibility";
import VisibityOffIcon from "@material-ui/icons/VisibilityOff";

import MoveUpIcon from "@material-ui/icons/KeyboardArrowUp";
import MoveDownIcon from "@material-ui/icons/KeyboardArrowDown";

import Uploader from "../../Uploader";

import { isEmpty } from "lodash";

import styles from "../widgets.module.less";

const type = "resources";
const field = "display";
const name = `${type}.${field}`;
const label = "Display Resources?";
const id = `entry-module-${name}`;

const defaultIntro =
    "That’s why we’re here. Download a variety of helpful documents and resources to make your loan process that much easier.";

const Resources = ({
    widget,
    onChange,
    setField,
    errors,
    hasErrors,
    ...rest
}) => {
    const { display, resourceIntro = defaultIntro, resourceList = [] } = widget;
    const titleColor = !!hasErrors ? "error" : "inherit";

    const handleChangeList = list => {
        setField("resourceList", [...list]);
    };

    //resourceIntro
    const introValidatorName = `${type}.resourceIntro`;
    const introErrorMsg = errors[introValidatorName];
    const usernameHelperTxt = introErrorMsg || "";

    return (
        <Accordion
            TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
            className={styles.Widget}>
            <AccordionSummary
                classes={{ content: styles.TitleBar }}
                expandIcon={<ExpandMoreIcon />}>
                <DragHandleIcon data-movable-handle />
                <Typography component="h4" variant="h6" color={titleColor}>
                    Resources
                </Typography>
                {display ? (
                    <VisibityIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                ) : (
                    <VisibityOffIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Box className={styles.grow}>
                    <Box marginBottom={3}>
                        <FormControlLabel
                            label={label}
                            control={
                                <Switch
                                    color="primary"
                                    id={id}
                                    name={field}
                                    checked={display}
                                    onChange={onChange}
                                    inputProps={{
                                        "data-label": label,
                                    }}
                                />
                            }
                        />
                        <TextField
                            required
                            autoComplete="off"
                            multiline
                            id={id}
                            name={"resourceIntro"}
                            label={"Resource Intro"}
                            error={!!introErrorMsg}
                            helperText={usernameHelperTxt}
                            value={resourceIntro}
                            fullWidth={true}
                            margin="normal"
                            inputProps={{
                                "data-lpignore": "true",
                                "data-label": "Resource Intro",
                            }}
                            onChange={onChange}
                        />
                    </Box>

                    <ResourceList
                        resourceList={resourceList}
                        onChangeList={handleChangeList}
                    />
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default Resources;

const ResourceList = ({ resourceList, onChangeList }) => {
    const getHandleChange = index => e => {
        const { name, value } = e.target;
        let obj = resourceList[index];
        // create new object on change to force editor updates
        resourceList[index] = Object.assign({}, obj, { [name]: value });
        onChangeList(resourceList);
    };

    const addFile = e => {
        e.preventDefault();
        const item = {
            type: "file",
            resourceDescription: "",
            resourceFile: "",
        };
        onChangeList([...resourceList, item]);
    };

    const addLink = e => {
        e.preventDefault();
        const item = {
            type: "link",
            resourceUrl: "",
            resourceDescription: "",
        };
        onChangeList([...resourceList, item]);
    };

    const getDeleter = index => {
        return e => {
            resourceList.splice(index, 1);
            onChangeList(resourceList);
        };
    };

    const getMoveUp = index => {
        return e => {
            e.preventDefault();
            const to = Math.max(index - 1, 0);
            resourceList.splice(to, 0, resourceList.splice(index, 1)[0]);
            onChangeList(resourceList);
        };
    };

    const getMoveDown = index => {
        return e => {
            e.preventDefault();
            const to = Math.min(index + 1, resourceList.length - 1);
            resourceList.splice(to, 0, resourceList.splice(index, 1)[0]);
            onChangeList(resourceList);
        };
    };

    const getFileHandle = index => {
        return (name, cdnUrl) => {
            resourceList[index][name] = cdnUrl;
            onChangeList(resourceList);
        };
    };

    return (
        <Box>
            {!!resourceList &&
                !isEmpty(resourceList) &&
                resourceList.map((resource, i) => {
                    return (
                        <Paper key={i}>
                            <Box p={2} m={2}>
                                <ResourceListItem
                                    resource={resource}
                                    onChange={getHandleChange(i)}
                                    onFile={getFileHandle(i)}
                                    onDelete={getDeleter(i)}
                                    onMoveUp={getMoveUp(i)}
                                    onMoveDown={getMoveDown(i)}
                                />
                            </Box>
                        </Paper>
                    );
                })}
            <Box mt={2}>
                <Button onClick={addFile}>
                    <AttachmentIcon />
                    Add a file
                </Button>

                <Button onClick={addLink}>
                    <LinkIcon />
                    Add a link
                </Button>
            </Box>
        </Box>
    );
};

const ResourceListItem = ({
    resource,
    onChange,
    onFile,
    onDelete,
    onMoveUp,
    onMoveDown,
}) => {
    const { type, resourceUrl, resourceFile, resourceDescription } = resource;
    const isLink = type === "link";
    const isFile = type === "file";
    return (
        <Grid container direction="row" spacing={2}>
            <Grid item xs={1}>
                <IconButton onClick={onMoveUp}>
                    <MoveUpIcon />
                </IconButton>
                <IconButton onClick={onMoveDown}>
                    <MoveDownIcon />
                </IconButton>
            </Grid>

            <Grid item xs={6}>
                <TextField
                    fullWidth={true}
                    name="resourceDescription"
                    label="Resource Description"
                    value={resourceDescription}
                    onChange={onChange}
                    helperText=""
                    margin="dense"
                    inputProps={{
                        "data-lpignore": "true",
                        "data-label": "Resource Description",
                    }}
                />

                {isLink && (
                    <TextField
                        fullWidth={true}
                        name="resourceUrl"
                        label="Resource URL"
                        value={resourceUrl}
                        onChange={onChange}
                        helperText="Add a link to a web site or online resource"
                        margin="dense"
                        inputProps={{
                            "data-lpignore": "true",
                            "data-label": "Resource URL",
                        }}
                    />
                )}
            </Grid>

            {isFile && (
                <Grid item>
                    <Uploader
                        name="resourceFile"
                        label="Resource File"
                        entry={resource}
                        value={resourceFile}
                        hint=""
                        handleFile={onFile}
                        isImage={false}
                        config={{
                            crop: "1:1",
                            imageShrink: "200x200",
                        }}
                    />
                </Grid>
            )}

            <Grid
                item
                container
                className={styles.ResourceDelete}
                xs
                justify="flex-end"
                alignItems="flex-start">
                <IconButton onClick={onDelete}>
                    <DeleteIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
};
