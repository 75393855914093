// TODO:  remove function
export const getApplyUrl = (applyNow, applyNowScript, slug) => {
    const consumerConnect = /bayequityhomeloans\.mymortgage-online\.com/i;
    const pageLink = `https://bayequityhomeloans.com${slug}applynow/`;
    if (!!applyNowScript) {
        return pageLink;
    }
    return consumerConnect.test(applyNow) ? applyNow : pageLink;
};

export const getDataByPageType = data => {
    if (data.loanOfficer) {
        return {
            ...data.loanOfficer,
            branch: data.loanOfficer.profile.branch,
        };
    } else if (data.nonOriginator) {
        return {
            ...data.nonOriginator,
            branch: data.nonOriginator.profile.branch,
        };
    } else if (data.team) {
        data.team.loanOfficers = flattenLoanOfficers(data.team.loanOfficers);
        return {
            ...data.team,
            branch: data.team.profile.branch,
        };
    } else if (data.branch) {
        let branchLoanOfficers = flattenLoanOfficers(data.branch.loanOfficers);
        let branchTeams = flattenTeams(data.branch.teams);
        data.branch.loanOfficers = branchLoanOfficers.concat(branchTeams);
        return {
            ...data.branch,
            profile: { name: data.branch.title },
            branch: data.branch,
        };
    }

    return { modules: [] };
};

// flatten loan officers...
const flattenLoanOfficers = loanOfficers => {
    if (!!!loanOfficers) return [];

    let result = loanOfficers
        .filter(lo => !!lo && !lo.hidden)
        .map(lo => {
            const emptyLo = {
                slug: "",
                photo: "",
                name: "",
                email: "",
                jobTitle: "",
                licenseIds: "",
            };
            try {
                const {
                    slug = "",
                    gallery: { photo = "" },
                    profile: {
                        name = "",
                        email = "",
                        jobTitle = "",
                        licenseIds = [],
                    },
                } = lo;

                return { slug, photo, name, email, jobTitle, licenseIds };
            } catch (e) {
                return emptyLo;
            }
        });
    return result;
};

// flatten teams...
const flattenTeams = teams => {
    if (!!!teams) return [];

    let result = teams
        .filter(t => !!t && !t.hidden)
        .map(team => {
            const emptyTeam = {
                slug: "",
                photo: "",
                name: "",
                email: "",
                jobTitle: "",
                licenseIds: "",
            };
            try {
                const {
                    slug = "",
                    profile: {
                        name = "",
                        email = "",
                        licenseIds = [],
                        logo: photo,
                    },
                } = team;

                return {
                    slug,
                    photo,
                    name,
                    email,
                    jobTitle: "",
                    licenseIds,
                };
            } catch (e) {
                return emptyTeam;
            }
        });
    return result;
};
