import React from "react";
import TextField from "@material-ui/core/TextField";

const name = "profile.name";
const hint = "Name as appears on license";
const label = "Team name";

const TeamName = ({ isAdmin, entry, errors, ...rest }) => {
    const errorMsg = errors[name];
    const value = entry.profile.name;
    let helperText = errorMsg || hint;
    const id = `entry-${name}`;
    const inputProps = {
        readOnly: !isAdmin,
        "data-lpignore": "true",
        "data-label": label,
    };

    return (
        <TextField
            required
            autoComplete="off"
            id={id}
            name={name}
            label={label}
            error={!!errorMsg}
            helperText={helperText}
            value={value}
            inputProps={inputProps}
            fullWidth={true}
            margin="normal"
            {...rest}
        />
    );
};

export default TeamName;
