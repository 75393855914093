import { has, get } from "lodash";

/**
 *
 * @param {String}} name
 * @param {Object} value
 */
export const hasError = (name, value) => {
    let rule = rules[name] || {};
    if (isMissing(name, value)) {
        return "This field is required.";
    }
    if (!!rule.pattern) {
        try {
            let flags = rule.flags ?? "i";
            let r = new RegExp(rule.pattern, flags);
            // if value doesn't match the pattern, return the error message
            if (value && rule.useMatch) {
                let m = value.match(r);
                if (m.length > 2) {
                    return rule.error;
                }
            } else if (value && !r.test(value)) {
                return rule.error;
            }
        } catch (e) {
            console.log(
                "This is not a valid regular expression pattern string",
                rule.pattern,
            );
            return "";
        }
    }
    if (!!rule.value) {
        if (value !== rule.value) {
            return rule.error;
        }
        return "";
    }
    return "";
};

/**
 * Returns true if the given value is empty or unset
 */
export const isMissing = (name, value) => {
    let rule = rules[name] || {};
    if (!!rule.required) {
        if (Array.isArray(value)) {
            return value.length === 0;
        }
        // Boolean false is allowed
        if (value === false) return false;
        // Return true (missing) if the value is falsey
        return !!!value;
    }
};

export const validateEntry = entry => {
    let errors = {};
    Object.keys(rules).forEach(field => {
        if (has(entry, field)) {
            let val = get(entry, field);
            errors[field] = hasError(field, val);
        }
    });
    return errors;
};

const rules = {
    title: {
        required: true,
    },
    name: {
        required: true,
    },
    owner: {
        required: true,
    },
    email: {
        required: true,
    },
    urlslug: {
        required: false,
        pattern: "^[a-z0-9_]+(-[a-z0-9_]+)*$",
        error:
            "URL Slugs should be lowercase, have no special characters, and no spaces",
    },
    applyNow: {
        required: false,
        pattern:
            "[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)",
        error: "Please enter a valid URL",
    },
    logIn: {
        required: false,
        pattern:
            "[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)",
        error: "Please enter a valid URL",
    },
    hidden: {
        required: false,
    },
    disclaimer: {
        required: true,
        value: true,
        error: "You must agree to the disclaimer.",
    },
    "profile.name": {
        required: true,
    },
    "profile.email": {
        required: true,
        pattern:
            "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$",
        error: "Please enter a valid email address",
    },
    "profile.jobTitle": {
        required: true,
    },
    "profile.branch": {
        required: true,
    },
    "profile.licenseIds": {
        required: true,
    },
    "profile.licenseLink": {
        required: false,
        pattern:
            "[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)",
        error: "Please enter a valid URL",
    },
    "hero.heroBackground": {
        required: false,
    },
    "hero.customPhoto": {
        required: false,
    },
    "hero.quote": {
        required: false,
        pattern: "^.{0,150}$",
        error: "Must be fewer than 150 characters.",
    },
    "hero.byline": {
        required: false,
        pattern: "^.{0,150}$",
        error: "Must be fewer than 150 characters.",
    },
    "hero.title": {
        required: false,
    },
    "hero.html": {
        required: false,
    },
    "gallery.photo": {
        required: false,
    },
    "gallery.image1": {
        required: false,
    },
    "gallery.image2": {
        required: false,
    },
    "gallery.image3": {
        required: false,
    },
    "contact.facebook": {
        required: false,
        pattern:
            "(?:https?://)?(?:www\\.)?(mbasic.facebook|m\\.facebook|facebook|fb)\\.(com|me)\\/(?:(?:\\w.)*#!\\/)?(?:pages\\/)?(?:[\\w\\-\\.]*\\/)*([\\w\\-\\.]*)",
        error: "Please enter a valid Facebook Profile URL",
    },
    "contact.linkedin": {
        required: false,
        pattern:
            "[(https://www.linkedin.com)]{20}.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_+.~#?&/=]*)+",
        error: "Please enter a valid LinkedIn Profile URL",
    },
    "contact.youtube": {
        required: false,
        pattern:
            "(?:https?:\\/\\/)?(?:(?:(?:www\\.?)?youtube\\.com(?:\\/(?:(?:watch\\?.*?(v=[^&\\s]+).*)|(?:v(\\/.*))|(channel\\/.+)|(?:user\\/(.+))|(?:results\\?(search_query=.+))))?)|(?:youtu\\.be(\\/.*)?))",
        error: "Please enter a valid YouTube Profile URL",
    },
    "contact.instagram": {
        required: false,
        pattern:
            "(https?:\\/\\/)(www\\.)?instagram\\.com\\/[A-Za-z0-9_.]{1,30}\\/?",
        error: "Please enter a valid Instagram Profile URL",
    },
    "contact.twitter": {
        required: false,
        pattern: "http(?:s)?://(?:www.)?x\\.com/([a-zA-Z0-9_]+)",
        error: "Please enter a valid X Profile URL",
    },
    "contact.phone": {
        required: false,
        pattern:
            "^(1[- .]?)?(\\(\\d{3}\\)[. -]?|\\d{3}[. -]?)\\d{3}[- .]?\\d{4}(\\s?x\\s?\\d+)?$",
        error:
            "Please enter your telephone number in the following format (extension is optional): 555-555-5555 x5555",
    },
    "contact.mobilePhone": {
        required: false,
        pattern: "([\\+1\\-\\(]{4})?(\\d{3}\\)?[\\.\\-]?){2}(\\d{4})",
        error:
            "Please enter your mobile phone number in the following format: 555-555-5555",
    },
    "contact.fax": {
        required: false,
        pattern: "([\\+1\\-\\(]{4})?(\\d{3}\\)?[\\.\\-]?){2}(\\d{4})",
        error:
            "Please enter your fax number in the following format: 555-555-5555",
    },
    phone: {
        required: false,
        pattern:
            "^(1[- .]?)?(\\(\\d{3}\\)[. -]?|\\d{3}[. -]?)\\d{3}[- .]?\\d{4}(\\s?x\\s?\\d+)?$",
        error:
            "Please enter your telephone number in the following format (extension is optional): 555-555-5555 x5555",
    },
    mobilePhone: {
        required: false,
        pattern: "([\\+1\\-\\(]{4})?(\\d{3}\\)?[\\.\\-]?){2}(\\d{4})",
        error:
            "Please enter your mobile phone number in the following format: 555-555-5555",
    },
    fax: {
        required: false,
        pattern: "([\\+1\\-\\(]{4})?(\\d{3}\\)?[\\.\\-]?){2}(\\d{4})",
        error:
            "Please enter your fax number in the following format: 555-555-5555",
    },
    body: {
        required: false,
    },
    changelog: {
        required: false,
    },
    headBeginScript: {
        required: false,
    },
    headEndScript: {
        required: false,
    },
    bodyBeginScript: {
        required: false,
    },
    bodyEndScript: {
        required: false,
    },
    // branch
    address1: {
        required: true,
    },
    city: {
        required: true,
    },
    state: {
        required: true,
    },
    zip: {
        required: true,
    },
    lat: {
        required: true,
    },
    lon: {
        required: true,
    },
    // modules
    "map.display": {
        required: true,
    },
    "calculator.display": {
        required: true,
    },
    "video.display": {
        required: true,
    },
    "video.videoTitle": {
        required: true,
    },
    "video.videoCaption": {
        required: true,
    },
    "video.videoUrl": {
        required: true,
        pattern:
            "((http(s)?://)?(www\\.)?(youtube(\\.com)?|youtu(\\.)?be)/(watch\\?v=)?.{11})|((http(s)?://vimeo\\.com)/(\\w\\d)*)|((http(s)?://)?(www\\.)?(facebook\\.com)/(\\w\\d)*)",
        error: "Please enter a valid YouTube, Vimeo, or Facebook link",
    },
    "blog.display": {
        required: true,
    },
    "facebookpage.display": {
        required: true,
    },
    "facebookpage.facebookPageUrl": {
        required: false,
        pattern:
            "(?:(?:http|https)://)?(?:www.)?facebook.com/(?:(?:\\w)*#!/)?(?:pages/)?(?:[?\\w\\-]*/)?(?:profile.php\\?id=(?=\\d.*))?([\\w\\-]*)?",
        error: "Please enter a valid Facebook Page URL",
    },
    "calendar.display": {
        required: true,
    },
    "calendar.calendar": {
        required: true,
    },
    "bookings.display": {
        required: true,
    },
    "bookings.bookings": {
        required: true,
        pattern:
            "(?:(?:http|https)://)?(?:www.)?outlook.office.com/bookwithme/user/([\\w\\-]*)?",
        error: "Please enter a valid Bookings URL",
    },
    "instagram.display": {
        required: true,
    },
    "instagram.instagramToken": {
        required: true,
    },
    "resources.display": {
        required: true,
    },
    "resources.resourceIntro": {
        required: true,
    },
    "resources.resourceList": {
        required: true,
    },
    "twitter.display": {
        required: true,
    },

    "events.display": {
        required: true,
    },
    "events.event1.eventName": {
        required: false,
    },
    "events.event1.eventDate": {
        required: false,
    },
    "events.event1.eventTime": {
        required: false,
    },
    "events.event1.eventLocation": {
        required: false,
    },
    "events.event1.eventImage": {
        required: false,
    },
    "events.event1.eventCustomImage": {
        required: false,
    },
    "events.event1.eventDescription": {
        required: false,
    },
    "events.event1.eventUrl": {
        required: false,
        pattern:
            "[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)",
        error: `Please enter a valid URL beginning with "https://" or "http://`,
    },
    "events.event1.eventCta": {
        required: false,
    },

    "events.event2.eventName": {
        required: false,
    },
    "events.event2.eventDate": {
        required: false,
    },
    "events.event2.eventTime": {
        required: false,
    },
    "events.event2.eventLocation": {
        required: false,
    },
    "events.event2.eventImage": {
        required: false,
    },
    "events.event2.eventCustomImage": {
        required: false,
    },
    "events.event2.eventDescription": {
        required: false,
    },
    "events.event2.eventUrl": {
        required: false,
        pattern:
            "[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)",
        error: `Please enter a valid URL beginning with "https://" or "http://`,
    },
    "events.event2.eventCta": {
        required: false,
    },

    "events.event3.eventName": {
        required: false,
    },
    "events.event3.eventDate": {
        required: false,
    },
    "events.event3.eventTime": {
        required: false,
    },
    "events.event3.eventLocation": {
        required: false,
    },
    "events.event3.eventImage": {
        required: false,
    },
    "events.event3.eventCustomImage": {
        required: false,
    },
    "events.event3.eventDescription": {
        required: false,
    },
    "events.event3.eventUrl": {
        required: false,
        pattern:
            "[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)",
        error: `Please enter a valid URL beginning with "https://" or "http://`,
    },
    "events.event3.eventCta": {
        required: false,
    },

    "awards.display": {
        required: true,
    },
    "awards.awards": {
        required: false,
    },

    "testimonials.display": {
        required: true,
    },
    "testimonials.social_id": {
        required: false,
    },
    "testimonials.social_page": {
        required: false,
    },
    "contact.applyNow": {
        required: false,
        pattern:
            "[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)",
        error: "Please enter a valid URL",
    },
    realtorCommissionPercentage: {
        required: true,
    },
    lenderCreditPercentage: {
        required: true,
    },
    realtorCreditPercentage: {
        required: true,
    },
    messageFrom: {
        required: true,
    },
    headerTitle: {
        required: true,
    },
    "content.contentTitle": {
        required: false,
    },
    "contactUs.contactUsTitle": {
        required: false,
    },
    "contactUs.contactFormTitle": {
        required: false,
    },
    "contactUs.contactFormSubtitle": {
        required: false,
    },
    vanityUrl: {
        required: false,
        pattern:
            "^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$",
        error: "Please enter a valid URL",
    },
    bammUrl: {
        required: false,
        pattern:
            "^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$",
        error: "Please enter a valid URL",
    },
    leadEmailCcList: {
        required: false,
        pattern: "([\\w+-.%]+@[\\w-.]+\\.[A-Za-z]{2,4}(?:(?:,?\\s*)|$))*",
        useMatch: true,
        flags: "g",
        error:
            "Please enter a valid email address (or multiple separated by a comma)",
    },
    "homebot.id": {
        required: false,
    },
    confirmEmailText: {
        required: true,
    },
};
