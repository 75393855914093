import React, { ChangeEvent } from "react";
import { TextField } from "@material-ui/core";

const name = "profile.licenseLink";
const hint = "Enter URL to page containing state licenses";
const label = "State Licenses URL";

type Errors = { [index: string]: string };

export interface LicenseLinkProps {
    isAdmin: boolean;
    entry: { profile: { licenseLink: string } };
    errors: Errors;
    onChange: (e: ChangeEvent) => void;
}

const LicenseLink = (props: LicenseLinkProps) => {
    const { isAdmin, entry, errors, ...rest } = props;
    const errorMsg = errors[name];
    const value = entry.profile.licenseLink;
    let helperText = errorMsg || hint;
    const id = `entry-${name}`;
    const inputProps = {
        "data-label": label,
        "data-lpignore": "true",
    };
    return (
        <TextField
            disabled={!isAdmin}
            autoComplete="off"
            id={id}
            name={name}
            label={label}
            error={!!errorMsg}
            helperText={helperText}
            inputProps={inputProps}
            value={value}
            fullWidth={true}
            margin="normal"
            {...rest}
        />
    );
};

export default LicenseLink;
