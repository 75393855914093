import React from "react";
import { List, arrayMove } from "react-movable";

import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import DragHandleIcon from "@material-ui/icons/DragHandle";

import styles from "./customFormField.module.less";

const CustomFormField = ({
    index,
    contactFormFields,
    contactFormFieldName,
    onChange,
    setField,
    customFieldType = "",
    label = "",
    bammName = "",
    options = [{ label: "", value: "", hasCustomLabel: false }],
}) => {
    const inputTypeOptions = [
        { id: "field-type-1", label: "Text", value: "text" },
        { id: "field-type-2", label: "Select", value: "select" },
        { id: "field-type-3", label: "Radio", value: "radio" },
        { id: "field-type-4", label: "Checkbox List", value: "checkboxList" },
        { id: "field-type-5", label: "Checkbox", value: "checkbox" },
    ];

    const field = `${contactFormFieldName}[${index}]`;

    const getFieldChangeHandler = () => {
        return e => {
            const contactFormFieldsValue = contactFormFields;
            const { value } = e.target;

            contactFormFieldsValue[index].customFieldType = value;

            if (value === "text" || value === "checkbox") {
                delete contactFormFieldsValue[index].options;
            }

            setField("contactFormFields", contactFormFieldsValue);
        };
    };

    const getOptionHandler = index => {
        return e => {
            const { name, value } = e.target;
            options[index][name] = value;

            if (options[index]["hasCustomLabel"] === false) {
                if (name === "value") {
                    options[index]["label"] = value;
                } else if (name === "label") {
                    options[index]["hasCustomLabel"] = true;
                }
            }

            setField(field + ".options", options);
        };
    };

    const getOptionDeleter = index => {
        return e => {
            options.splice(index, 1);
            setField(field + ".options", options);
        };
    };

    const addOption = () => {
        options.push({ label: "", value: "", hasCustomLabel: false });
        setField(field + ".options", options);
    };

    const handleFieldTypeChange = getFieldChangeHandler(index);

    return (
        <Box marginLeft={"2em"}>
            <TextField
                className={styles.field}
                name="customFieldType"
                select
                value={customFieldType}
                label="Input Type"
                onChange={handleFieldTypeChange}
                style={{ minWidth: "100px" }}
                inputProps={{
                    "data-label": "Input Type",
                }}>
                {inputTypeOptions.map(opt => (
                    <option key={opt.id} value={opt.value}>
                        {opt.label}
                    </option>
                ))}
            </TextField>
            <Box marginTop={1}>
                <TextField
                    className={styles.field}
                    name="label"
                    value={label}
                    label="Label"
                    onChange={onChange}
                    inputProps={{
                        "data-label": "Label",
                    }}></TextField>
            </Box>
            <Box marginTop={1}>
                <TextField
                    className={styles.field}
                    name="bammName"
                    value={bammName}
                    label="BAMM Name"
                    onChange={onChange}
                    inputProps={{
                        "data-label": "BAMM Name",
                    }}></TextField>
            </Box>
            {(customFieldType === "select" ||
                customFieldType === "radio" ||
                customFieldType === "checkboxList") && (
                <Box marginTop="1em">
                    <div>Field Options</div>
                    {Object.keys(options).length > 0 && (
                        <List
                            values={options}
                            onChange={({ oldIndex, newIndex }) => {
                                setField(
                                    field + ".options",
                                    arrayMove(options, oldIndex, newIndex),
                                );
                            }}
                            renderList={({ children, props, isDragged }) => {
                                return (
                                    <Box
                                        marginTop={2}
                                        {...props}
                                        style={{
                                            cursor: isDragged
                                                ? "grabbing"
                                                : "inherit",
                                        }}>
                                        {children}
                                    </Box>
                                );
                            }}
                            renderItem={({
                                value,
                                props,
                                isDragged,
                                index,
                            }) => {
                                const handleOptionChange = getOptionHandler(
                                    index,
                                );
                                const handleOptionClick = getOptionDeleter(
                                    index,
                                );
                                return (
                                    <div {...props}>
                                        <Option
                                            key={index}
                                            index={index}
                                            label={value.label}
                                            value={value.value}
                                            isDragged={isDragged}
                                            onChange={handleOptionChange}
                                            handleClick={handleOptionClick}
                                        />
                                    </div>
                                );
                            }}
                        />
                    )}
                    <Box marginLeft={"2em"}>
                        <Box className={styles.add} marginTop={2}>
                            <IconButton onClick={addOption} margin={0}>
                                <AddIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

const Option = ({ value = "", label = "", onChange, handleClick }) => {
    return (
        <Box marginTop={1}>
            <DragHandleIcon data-movable-handle />
            <TextField
                className={styles.field}
                name="value"
                value={value}
                label="Option Value"
                onChange={onChange}
                inputProps={{
                    "data-label": "Option Value",
                }}></TextField>
            <TextField
                className={styles.field}
                name="label"
                value={label}
                label="Option Label"
                onChange={onChange}
                inputProps={{
                    "data-label": "Option Label",
                }}></TextField>
            <IconButton onClick={handleClick}>
                <DeleteIcon />
            </IconButton>
        </Box>
    );
};

export default CustomFormField;
