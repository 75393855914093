import React, { useState, useEffect } from "react";
import { noop, orderBy, cloneDeep } from "lodash";

import { getDocumentByRef } from "../../../../../lib/helpers";
import createSlug from "../../../../../lib/createSlug";

import LoanOfficerDetail from "../../../../LoanOfficerDetail";

/**
 *
 * Flatten an entry for use in LoanOfficerDetail
 * Fetch branche and team from refs
 * Fetch lo blogs and corporate blogs
 */
const LoanOfficerPreview = ({ fetchCollection = noop, entry }) => {
    const [branch, setBranch] = useState({});
    const [team, setTeam] = useState({});
    const [news, setNews] = useState([]);
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        (async () => {
            let branchRef = entry.profile.branch;
            let branchDoc = !!branchRef ? await branchRef.get() : null;
            let b = branchDoc && branchDoc.exists ? branchDoc.data() : {};
            let data = await mapPageData(b);
            setBranch(data);
        })();
    }, [entry.profile.branch]);

    useEffect(() => {
        (async () => {
            try {
                let teamRef = entry.team;
                let teamDoc = !!teamRef ? await teamRef.get() : null;
                let t = teamDoc && teamDoc.exists ? teamDoc.data() : {};
                setTeam(t);
            } catch (e) {
                console.log("error getting team");
                console.log(e);
            }
        })();
    }, [entry.team]);

    useEffect(() => {
        (async () => {
            let corporateBlogs = await fetchCollection(
                "corporateBlog",
                "published",
            );
            let corpSorted = orderBy(corporateBlogs, ["date"], ["desc"]);
            let itemsCorp = corpSorted.slice(0, 3).map(item => {
                item.date = item.date.toDate();
                return item;
            });
            setNews(itemsCorp);

            let loBlogs = await fetchCollection("blog", "published");
            let loSorted = orderBy(loBlogs, ["date"], ["desc"]);
            let loFiltered = loSorted.filter(
                blog => blog.owner === entry.owner,
            );
            let itemsLO = loFiltered.slice(0, 3).map(item => {
                item.date = item.date.toDate();
                return item;
            });
            setBlogs(itemsLO);
        })();
    }, []);

    if (!entry) return null;

    let { profile, contact, urlSlug, title, modules } = entry;
    const { social_id } =
        (modules && modules.find(module => module.type === "testimonials")) ||
        {};

    return (
        <div id="preview" style={{ pointerEvents: "auto" }}>
            <LoanOfficerDetail
                {...{ ...cloneDeep(entry), firestoreId: entry.id }}
                {...profile}
                {...contact}
                slug={createSlug(urlSlug, title)}
                branch={branch}
                team={team}
                corporateBlogs={news}
                blogs={blogs}
                social_id={social_id}
                isPreview={true}
            />
        </div>
    );
};

/**
 *
 * Flatten an entry for use in SplashDetail
 * Fetch loan-officer, branch and team from refs
 */
const mapPageData = async branchData => {
    // branch selected, build out based on this...
    if (branchData) {
        await getExtraData(branchData);
    } else {
        console.log(`Error: branch "${branchData.id}" is missing. Set to null`);
    }

    return { ...branchData };
};

async function getExtraData(collection) {
    if (Array.isArray(collection.loanOfficers)) {
        let promisesPromises = collection.loanOfficers.map(
            async loanOfficer => {
                if (loanOfficer && loanOfficer.get) {
                    let lo = await getDocumentByRef(loanOfficer);

                    if (!!lo && !lo.hidden) {
                        return {
                            slug: lo.slug,
                            gallery: lo.gallery,
                            profile: {
                                name: lo.profile.name,
                                email: lo.profile.email,
                                jobTitle: lo.profile.jobTitle,
                                licenseIds: lo.profile.licenseIds,
                            },
                        };
                    } else return null;
                }
                return null;
            },
        );
        let loanOfficers = await Promise.all(promisesPromises);
        collection.loanOfficers = loanOfficers;
    } else {
        collection.loanOfficers = [];
    }

    if (Array.isArray(collection.teams)) {
        let promisesPromises = collection.teams.map(async team => {
            if (team && team.get) {
                let lo = await getDocumentByRef(team);

                if (!!lo && !lo.hidden) {
                    return {
                        slug: lo.slug,
                        title: lo.profile.name,
                        profile: {
                            licenseIds: lo.profile.licenseIds,
                            email: lo.profile.email,
                        },
                    };
                } else return null;
            }
            return null;
        });
        let teams = await Promise.all(promisesPromises);
        collection.teams = teams;
    } else {
        collection.teams = [];
    }

    // ensure managers and staff are set as arrays
    collection.staff = Array.isArray(collection.staff) ? collection.staff : [];
    collection.managers = Array.isArray(collection.managers)
        ? collection.managers
        : [];
}

export default LoanOfficerPreview;
