import React from "react";
import TextField from "@material-ui/core/TextField";

import defaultSocialMedia from "../../../../../lib/defaultSocialMedia.json";

const name = "contact.youtube";
const hint = `Enter the full URL for your Youtube profile like ${defaultSocialMedia.youtube}`;
const label = "Youtube Profile URL";

const YouTube = ({ isAdmin, entry, errors, ...rest }) => {
    const errorMsg = errors[name];
    const value = entry.contact.youtube;
    const helperText = errorMsg || hint;
    const id = `entry-${name}`;

    return (
        <TextField
            autoComplete="off"
            id={id}
            name={name}
            label={label}
            error={!!errorMsg}
            helperText={helperText}
            value={value || defaultSocialMedia.youtube}
            fullWidth={true}
            margin="normal"
            inputProps={{
                "data-lpignore": "true",
                "data-label": label,
            }}
            {...rest}
        />
    );
};

export default YouTube;
