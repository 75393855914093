import React from "react";
import TextField from "@material-ui/core/TextField";

const options = ["top", "center", "bottom"];

const HeroQuote = ({
    name,
    isAdmin,
    entry,
    defaultValue,
    errors,
    value,
    hint = "Set the alignment of your Hero Image. Image is centered by default",
    label = "Photo Alignment",
    ...rest
}) => {
    const errorMsg = errors[name];
    const val = value || defaultValue;
    const helperText = errorMsg || hint;
    const id = `entry-${name}`;

    return (
        <TextField
            id={id}
            name={name}
            label={label}
            error={!!errorMsg}
            helperText={helperText}
            value={val}
            fullWidth={true}
            margin="normal"
            select
            {...rest}
            inputProps={{
                "data-lpignore": "true",
                "data-label": "License ID",
            }}>
            {options.map(opt => (
                <option key={opt} value={opt}>
                    {opt}
                </option>
            ))}
        </TextField>
    );
};

export default HeroQuote;
