import React, { PureComponent } from "react";
import { get } from "lodash";
import StarRatings from "../../../StarRatings";

import Gallery from "../../../Gallery";
import SocialIcons from "../../../SocialIcons";
import LinkButton from "../../../LinkButton";

import defaultLogo from "../../../Header/images/be_logo_black.png";

import {
    getApplyUrl,
    returnStateAbbrev,
    formatPhoneAreaCode,
    formatPhoneNumber,
} from "../../../../lib/helpers";

import styles from "./styles.module.less";

const TeamLink = ({ team }) => {
    if (!team) return null;
    const { slug = "", title = "" } = team;
    if (!slug || !title) return null;
    return (
        <div>
            <p className={styles.teamLink}>
                <a href={slug}>{title}</a>
            </p>
        </div>
    );
};

class Contact extends PureComponent {
    render() {
        const {
            ownerSlug,
            applyNow = "",
            applyNowScript = "",
            jobTitle,
            name,
            email,
            phone,
            mobilePhone,
            fax,
            modules = [],
            branch = {},
            team = {},
            licenseIds = [],
            facebook,
            twitter,
            linkedin,
            youtube,
            instagram,
            gallery = {},
            stars,
            ratingsCount,
            isSurveyInit: isReviewInit,
            shouldDisplaySurvey,
            templateKey,
        } = this.props;

        let { photo = "", image1 = "", image2 = "", image3 = "" } =
            gallery || {};
        let imageList = [photo, image1, image2, image3];

        const socialIconsProps = {
            facebook,
            twitter,
            linkedin,
            youtube,
            instagram,
        };

        const showGallery = !["team", "branch"].includes(templateKey);
        const logoPath = get(branch, "dbaLogoBlack", null) || defaultLogo;
        const siteUrl = `https://bayequityhomeloans.com${ownerSlug}`;

        return (
            <section className={styles.ContactDetail} data-background="dark">
                <div className={`${styles.root} ${styles.mobile}`}>
                    <div className={styles.logoContainer}>
                        {showGallery && (
                            <div className={styles.gallery}>
                                <Gallery
                                    imageList={imageList.filter(Boolean)}
                                    alt={name}
                                />
                            </div>
                        )}
                        <div className={styles.shortInfo}>
                            <div className={styles.shortInfoContent}>
                                <div className={styles.shortInfoWrapper}>
                                    <h1 className={styles.h2}>{name}</h1>
                                    <div className={styles.shortInfoSecond}>
                                        <h2 className={styles.firstChild}>
                                            {jobTitle}
                                        </h2>
                                        <h3 className={styles.lastChild}>
                                            {licenseIds.map((l, i) => {
                                                return (
                                                    !!l.license && (
                                                        <span
                                                            className={
                                                                styles.licenseeIds
                                                            }
                                                            key={
                                                                l.license +
                                                                l.id +
                                                                i
                                                            }>
                                                            {l.license}# {l.id}
                                                        </span>
                                                    )
                                                );
                                            })}
                                        </h3>
                                    </div>
                                    <div className={styles.infoContainer}>
                                        <div className={styles.moreInfo}>
                                            <div>
                                                {phone && (
                                                    <div>
                                                        <div
                                                            className={
                                                                styles.first
                                                            }
                                                            aria-label="Telephone number">
                                                            P:
                                                        </div>
                                                        <div>
                                                            <a
                                                                href={`tel:${formatPhoneNumber(
                                                                    phone,
                                                                )}`}>
                                                                {formatPhoneAreaCode(
                                                                    phone,
                                                                )}
                                                            </a>
                                                        </div>
                                                    </div>
                                                )}
                                                {mobilePhone && (
                                                    <div>
                                                        <div
                                                            className={
                                                                styles.first
                                                            }
                                                            aria-label="Mobile telephone number">
                                                            M:
                                                        </div>
                                                        <div>
                                                            <a
                                                                href={`tel:${formatPhoneNumber(
                                                                    mobilePhone,
                                                                )}`}>
                                                                {formatPhoneAreaCode(
                                                                    mobilePhone,
                                                                )}
                                                            </a>
                                                        </div>
                                                    </div>
                                                )}
                                                {email && (
                                                    <div>
                                                        <div
                                                            className={
                                                                styles.first
                                                            }
                                                            aria-label="Email address">
                                                            E:
                                                        </div>
                                                        <div>
                                                            <a
                                                                href={`mailto:${email}`}>
                                                                {email}
                                                            </a>
                                                        </div>
                                                    </div>
                                                )}
                                                {fax && (
                                                    <div>
                                                        <div
                                                            className={
                                                                styles.first
                                                            }
                                                            aria-label="Fax number">
                                                            F:
                                                        </div>
                                                        <div>
                                                            {formatPhoneAreaCode(
                                                                fax,
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                                {branch && branch.address1 && (
                                                    <div>
                                                        <div
                                                            className={
                                                                styles.first
                                                            }
                                                            aria-label="Address">
                                                            A:
                                                        </div>
                                                        <div>
                                                            {branch.address1}
                                                        </div>
                                                    </div>
                                                )}
                                                {branch && branch.address2 && (
                                                    <div>
                                                        <div
                                                            className={
                                                                styles.first
                                                            }
                                                        />
                                                        <div>
                                                            {branch.address2}
                                                        </div>
                                                    </div>
                                                )}
                                                {branch && branch.address3 && (
                                                    <div>
                                                        <div
                                                            className={
                                                                styles.first
                                                            }
                                                        />
                                                        <div>
                                                            {branch.address3}
                                                        </div>
                                                    </div>
                                                )}
                                                <div>
                                                    <div
                                                        className={styles.first}
                                                    />
                                                    <div>
                                                        {branch && branch.city}
                                                        {branch && branch.state
                                                            ? ", " +
                                                              returnStateAbbrev(
                                                                  branch.state,
                                                              )
                                                            : ""}{" "}
                                                        {branch && branch.zip}
                                                    </div>
                                                </div>
                                                {!!!team &&
                                                    templateKey !==
                                                        "non-originator" && (
                                                        <div
                                                            className={
                                                                styles.ownerLink
                                                            }>
                                                            <a
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                href={siteUrl}>
                                                                {siteUrl.replace(
                                                                    /^https?:\/\//i,
                                                                    "",
                                                                )}
                                                            </a>
                                                        </div>
                                                    )}
                                            </div>
                                            <div
                                                className={
                                                    styles.iconContainer
                                                }>
                                                <SocialIcons
                                                    {...socialIconsProps}
                                                />
                                            </div>
                                            <TeamLink team={team} />
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.ctaContainer}>
                                    {!!applyNow && (
                                        <div
                                            className={
                                                styles.applyNowContainer
                                            }>
                                            <div className={styles.starButton}>
                                                <LinkButton
                                                    label="Apply Now / Log In"
                                                    href={`${getApplyUrl(
                                                        applyNow,
                                                        applyNowScript,
                                                        ownerSlug,
                                                    )}`}
                                                    target="_blank"
                                                />
                                            </div>
                                            {isReviewInit &&
                                                shouldDisplaySurvey &&
                                                stars && (
                                                    <div
                                                        className={
                                                            styles.starRatings
                                                        }>
                                                        <StarRatings
                                                            modules={modules}
                                                            stars={stars}
                                                            ratingsCount={
                                                                ratingsCount
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            <div className={styles.starLogo}>
                                                <img
                                                    src={logoPath}
                                                    alt="Bay Equity"
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {!!!applyNow && (
                                        <div
                                            className={
                                                styles.nonOriginatorContainer
                                            }>
                                            <img
                                                src={logoPath}
                                                alt="Bay Equity"
                                            />
                                            {logoPath === defaultLogo && (
                                                <>
                                                    <p>
                                                        We're here to get you
                                                        home.
                                                    </p>
                                                    <a href="https://bayequityhomeloans.com">
                                                        www.BayEquityHomeLoans.com
                                                    </a>
                                                </>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Contact;
