import React, { useState, useEffect } from "react";
import { find, get } from "lodash";

import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ListItemText } from "@material-ui/core";

import { getFirestoreDb } from "../../../../../config/cms.config";

import states from "../../../../../lib/states.json";

const db = getFirestoreDb();

const hint = "";
const label = "Search Visibility";

const SearchVisibilitySelect = ({
    isAdmin,
    name = "searchVisibility",
    value: inValue,
    entry = null,
    errors,
    onChange,
    ...rest
}) => {
    const [disabled, setDisabled] = useState(false);

    const errorMsg = errors[name];
    const id = `entry-${name}`;
    const inputProps = {
        readOnly: !isAdmin,
        "data-label": label,
    };

    const displayValue = () => {
        return (
            entry?.searchVisibility
                .map(
                    licensedState =>
                        states.find(state => state.label === licensedState)
                            .value,
                )
                .sort()
                .join(", ") || ""
        );
    };

    return (
        <Select
            name={name}
            id={id}
            placeholder="Select a branch…"
            label={label}
            value={entry.searchVisibility || []}
            disabled={disabled}
            error={!!errorMsg}
            onChange={onChange}
            fullWidth={true}
            multiple
            renderValue={displayValue}
            {...inputProps}>
            {states.map(state => {
                return (
                    <MenuItem key={state.label} value={state.label}>
                        <ListItemText>{state.value}</ListItemText>
                    </MenuItem>
                );
            })}
        </Select>
    );
};

export default SearchVisibilitySelect;
