import React, { Component } from "react";
import { noop } from "lodash";
import shortid from "shortid";
import { navigate } from "gatsby";
import { List, arrayMove } from "react-movable";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Loader from "../components/Loader";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";

import Save from "@material-ui/icons/SaveAlt";
import Publish from "@material-ui/icons/Publish";
import ArrowBack from "@material-ui/icons/ArrowBack";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import { Typography } from "@material-ui/core";

import styles from "./styles.module.less";

export default class Redirects extends Component {
    static defaultProps = {
        publishSite: noop,
        fetchRedirects: noop,
        batchRedirects: noop,
        isAdmin: noop,
    };

    state = {
        redirects: [],
        loaded: false,
        dirty: false,
    };

    componentDidMount = async () => {
        const { isAdmin } = this.props;
        if (!isAdmin) {
            this.eject();
        }
        await this.fetch();
    };

    fetch = async () => {
        const { fetchRedirects } = this.props;
        let redirects = await fetchRedirects();
        this.setState({ redirects, loaded: true, dirty: false });
    };

    handleAdd = e => {
        const { redirects } = this.state;
        redirects.push({
            sort: ++redirects.length,
            from: "",
            to: "",
            response: "301",
            id: `redirect-${shortid.generate()}`,
            dirty: true,
            isNew: true,
        });
        this.setState({ redirects });
    };

    handleListOrder = ({ oldIndex, newIndex }) => {
        // reorder
        let redirects = arrayMove(this.state.redirects, oldIndex, newIndex);
        // resset sort
        redirects.forEach((r, i) => {
            r.sort = ++i;
            r.dirty = true;
        });
        // set
        this.setState({
            redirects,
        });
    };

    getListItemChangeHandler(index) {
        return e => {
            let { name, value } = e.target;
            let { redirects } = this.state;
            redirects[index][name] = value;
            redirects[index].dirty = true;
            redirects[index].isNew = false;
            this.setState({
                redirects: [].concat(redirects),
                dirty: true,
            });
        };
    }

    getListItemDelete(index) {
        return e => {
            let { redirects } = this.state;
            redirects[index].deleted = true;
            redirects[index].dirty = true;
            this.setState({
                redirects: [].concat(redirects),
                dirty: true,
            });
        };
    }

    /**
     * save and publish
     */
    handlePublish = async e => {
        const { batchRedirects, publishSite } = this.props;
        const { redirects } = this.state;
        // do work
        await batchRedirects(redirects);
        // publish site
        await publishSite();
        this.eject();
    };

    handleSave = async e => {
        const { batchRedirects } = this.props;
        const { redirects } = this.state;
        this.setState({ loaded: false });
        // do work
        await batchRedirects(redirects);
        // reload
        await this.fetch();
    };

    eject() {
        navigate(`/admin/`);
    }

    render() {
        const { redirects, loaded, dirty } = this.state;
        const dirtyColor = !!dirty ? "secondary" : "inherit";
        return (
            <div className={styles.Redirects}>
                <AppBar position="sticky" color="default">
                    <Toolbar>
                        <Button color="inherit" onClick={this.eject}>
                            <ArrowBack />
                            Back
                        </Button>
                        <Typography
                            component="span"
                            variant="h6"
                            color={dirtyColor}
                            className={styles.Title}>
                            URL Redirects
                        </Typography>

                        <Button
                            onClick={this.handleAdd}
                            variant="contained"
                            className={styles.ToolbarButton}>
                            Add Redirect Rule
                            <AddIcon />
                        </Button>

                        <Button
                            color={dirtyColor}
                            variant="contained"
                            onClick={this.handleSave}
                            className={styles.ToolbarButton}>
                            Save
                            <Save />
                        </Button>

                        <Button
                            color="default"
                            variant="contained"
                            onClick={this.handlePublish}
                            className={styles.ToolbarButton}>
                            Publish
                            <Publish />
                        </Button>
                    </Toolbar>
                </AppBar>
                <Grid container spacing={0} component="div" justify="center">
                    <Grid item xs={8}>
                        <Loader loading={!loaded} />
                        <Box hidden={!loaded} margin={1} padding={2}>
                            <Typography
                                component="h1"
                                variant="h6"
                                color={dirtyColor}>
                                URL Redirect Rules
                            </Typography>
                            <Typography
                                component="p"
                                variant="body1"
                                color={dirtyColor}>
                                Manage custom URL redirect rules below. Save all
                                changes before publishing.
                                <br />
                                Upon publishing, redirect rules will applied to
                                the live site.
                                <br />
                                The first matching rule, read from top to
                                bottom, will be processed.
                                <br />
                                <Link
                                    href="https://docs.netlify.com/routing/redirects/"
                                    target="_blank"
                                    color="secondary">
                                    See Netlify documentation for more details
                                </Link>
                            </Typography>
                        </Box>

                        {loaded && (
                            <List
                                values={redirects}
                                onChange={this.handleListOrder}
                                renderList={({
                                    children,
                                    props,
                                    isDragged,
                                }) => {
                                    return (
                                        <div
                                            {...props}
                                            style={{
                                                cursor: isDragged
                                                    ? "grabbing"
                                                    : "inherit",
                                            }}>
                                            {children}
                                        </div>
                                    );
                                }}
                                renderItem={({
                                    value,
                                    props,
                                    isDragged,
                                    isSelected,
                                    index,
                                }) => {
                                    return (
                                        <div {...props}>
                                            <Rule
                                                key={value.id}
                                                isDragged={isDragged}
                                                rule={value}
                                                onChange={this.getListItemChangeHandler(
                                                    index,
                                                )}
                                                onDelete={this.getListItemDelete(
                                                    index,
                                                )}
                                            />
                                        </div>
                                    );
                                }}
                            />
                        )}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const Rule = ({ rule, isDragged, onChange, onDelete }) => {
    const {
        from,
        to,
        response,
        dirty = false,
        deleted = false,
        isNew = false,
    } = rule;
    const cls = isDragged ? `${styles.Rule} ${styles.isDragged}` : styles.Rule;
    const dirtyColor = !!dirty ? "secondary" : "inherit";
    const classes = {
        root: styles.RulePaper,
    };
    // don't display soft-deleted redirects
    if (deleted) return null;
    return (
        <Paper elevation={1} classes={classes}>
            <Box className={cls} margin={1} padding={2}>
                <DragHandleIcon color={dirtyColor} data-movable-handle />
                <TextField
                    name="from"
                    label="Redirect from…"
                    value={from}
                    onChange={onChange}
                    autoFocus={isNew}
                />
                <TextField
                    name="to"
                    label="…to…"
                    value={to}
                    onChange={onChange}
                />
                <TextField
                    name="response"
                    label="…with response code"
                    value={response}
                    onChange={onChange}
                />
                <IconButton onClick={onDelete}>
                    <DeleteIcon color={dirtyColor} />
                </IconButton>
            </Box>
        </Paper>
    );
};
