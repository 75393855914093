import React from "react";
import { get } from "lodash";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import states from "./states.json";

const hint = "";

const State = ({ name = "state", label = "State", entry, errors, ...rest }) => {
    const errorMsg = errors[name];
    const value = get(entry, name) || "";
    const helperText = errorMsg || hint;
    const id = `entry-${name}`;

    return (
        <TextField
            autoComplete="off"
            id={id}
            select
            name={name}
            label={label}
            error={!!errorMsg}
            helperText={helperText}
            value={value}
            fullWidth={true}
            margin="normal"
            inputProps={{
                "data-lpignore": "true",
                "data-label": label,
            }}
            {...rest}>
            {states.map(state => (
                <MenuItem key={state.value} value={state.value}>
                    {state.label}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default State;
