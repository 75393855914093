import React from "react";
import { isDate } from "lodash";

import BlogDetail from "../../../../BlogDetail";

const normalizeDate = val => {
    if (isDate(val)) {
        return val;
    } else {
        return val && val.toDate ? val.toDate() : null;
    }
};

/**
 *
 * Flatten an entry for use in LoanOfficerDetail
 * Fetch branche and team from refs
 * Fetch lo blogs and corporate blogs
 */
const BlogPreview = ({ entry, author = null }) => {
    if (!entry) return null;

    let { body } = entry;
    let blogs = { recommendedBlogs: [], exactBlogs: [] };

    let previewDate = !!entry.date ? normalizeDate(entry.date) : new Date();

    return (
        <div style={{ pointerEvents: "none" }}>
            <BlogDetail
                {...entry}
                date={previewDate}
                html={body}
                blogs={blogs}
                preview={true}
                author={author}
            />
        </div>
    );
};

export default BlogPreview;
