import React from "react";

import Switch from "@material-ui/core/Switch";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import VisibityIcon from "@material-ui/icons/Visibility";
import VisibityOffIcon from "@material-ui/icons/VisibilityOff";

import styles from "../widgets.module.less";

const Homebot = ({ widget, onChange, errors, hasErrors, ...rest }) => {
    const { display, id } = widget;
    const titleColor = !!hasErrors ? "error" : "inherit";

    const idValidatorName = "homebot.id";
    const idErrorMsg = errors[idValidatorName];
    const idHelperTxt =
        idErrorMsg ||
        "Enter the id associated with your Homebot account to display the Homebot widget on your page";

    return (
        <Accordion
            TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
            className={styles.Widget}>
            <AccordionSummary
                classes={{ content: styles.TitleBar }}
                expandIcon={<ExpandMoreIcon />}>
                <DragHandleIcon data-movable-handle />
                <Typography component="h4" variant="h6" color={titleColor}>
                    Homebot
                </Typography>
                {display ? (
                    <VisibityIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                ) : (
                    <VisibityOffIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Box className={styles.grow}>
                    <Box marginBottom={3}>
                        <FormControlLabel
                            label="Display Homebot?"
                            control={
                                <Switch
                                    color="primary"
                                    id="entry-module-homebot.display"
                                    name="display"
                                    checked={display}
                                    onChange={onChange}
                                    inputProps={{
                                        "data-label": "Display Homebot?",
                                    }}
                                />
                            }
                        />
                        <TextField
                            required
                            autoComplete="off"
                            id="homebot.id"
                            name={"id"}
                            label={"Homebot ID"}
                            error={!!idErrorMsg}
                            helperText={idHelperTxt}
                            value={id}
                            fullWidth={true}
                            margin="normal"
                            inputProps={{
                                "data-lpignore": "true",
                                "data-label": "Homebot ID",
                            }}
                            onChange={onChange}
                        />
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default Homebot;
