import React from "react";

import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import DragHandleIcon from "@material-ui/icons/DragHandle";

import VisibityIcon from "@material-ui/icons/Visibility";
import VisibityOffIcon from "@material-ui/icons/VisibilityOff";

// Profile
import Uploader from "../../Uploader";
import Text from "../../Text";

import Editor from "../../Editor";
import Email from "../../Email";
import HeroImage from "../../HeroImage";

// contact form fields
import CustomContactForm from "../../CustomContactForm";
import predefinedInputs from "../../../../../ContactUsModule/lib/predefinedInputs.json";

// generic toggle
import Boolean from "../../Boolean";

import styles from "./styles.module.less";

const ContactUs = ({
    widget,
    onChange,
    onEditorChange,
    onFileChange,
    setField,
    isAdmin,
    errors,
    hasErrors,
    ...rest
}) => {
    const {
        display,
        contactUsTitle,
        contactUsText,
        darkTitleText,
        backgroundImage,
        backgroundImageCustom,
        contactFormTitle,
        contactFormFields,
        contactUsConfirmEmailFrom,
        contactUsLeadEmailCcList,
        contactUsConfirmEmailText,
        contactUsBammUrl,
    } = widget;

    const hasErrorsInFields = (errors, fields = []) =>
        fields.some(field => !!errors[field]);

    let formSettingsErrorColor = React.useCallback(
        hasErrorsInFields(errors, ["contactUsTitle"]) ? "error" : "inherit",
        [errors],
    );

    const handleReorderList = list => {
        setField("contactFormFields", list);
    };

    return (
        <Accordion
            TransitionProps={{
                mountOnEnter: true,
                unmountOnExit: true,
            }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <DragHandleIcon data-movable-handle />
                <Typography
                    component="h3"
                    variant="h6"
                    color={formSettingsErrorColor}>
                    Contact Us
                </Typography>
                {display ? (
                    <VisibityIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                ) : (
                    <VisibityOffIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Box padding={1} margin={1}>
                    <Box marginBottom={3}>
                        <FormControlLabel
                            label="Display Contact Us"
                            control={
                                <Switch
                                    color="primary"
                                    id="entry-module-contactUs.display"
                                    name="display"
                                    checked={display}
                                    onChange={onChange}
                                    inputProps={{
                                        "data-label": "Display Contact Us",
                                    }}
                                />
                            }
                        />
                    </Box>
                    <Text
                        required
                        name="contactUsTitle"
                        label="Title"
                        disabled={!isAdmin}
                        hidden={!isAdmin}
                        onChange={onChange}
                        error={!!errors["contactUs.contactUsTitle"]}
                        errors={errors}
                        value={contactUsTitle}
                    />
                    <div className={styles.contactUsEditor}>
                        <Editor
                            name="contactUsText"
                            entry={{ body: contactUsText }}
                            onChange={body => {
                                onEditorChange("contactUsText", body);
                            }}
                            toolbarButtons="bold|italic|link|bullet|numbered"
                        />
                    </div>
                    <Boolean
                        name="darkTitleText"
                        value={darkTitleText}
                        label="Use Dark Text?"
                        hint="Display the Title and text in black for better contrast"
                        onChange={onChange}
                    />
                    <Paper>
                        <Box padding={2} margin={2}>
                            <Typography component="h3" variant="h6">
                                Background Image
                            </Typography>
                            <br />
                            <HeroImage
                                isAdmin={isAdmin}
                                name="backgroundImage"
                                value={backgroundImage}
                                collections={["Body"]}
                                onChange={onChange}
                            />
                            <Uploader
                                name="backgroundImageCustom"
                                label="Custom Image"
                                value={backgroundImageCustom}
                                config={{
                                    imagesOnly: true,
                                    inputAcceptTypes: ".jpg, .jpeg, .png",
                                    crop: "16:9",
                                    imageShrink: "2881x2017",
                                }}
                                handleFile={onFileChange}
                                onChange={onChange}
                                logo
                            />
                        </Box>
                    </Paper>
                    <CustomContactForm
                        field="contactFormFields"
                        contactFormTitle={contactFormTitle}
                        contactFormFields={contactFormFields}
                        predefinedInputs={predefinedInputs}
                        errors={errors}
                        isAdmin={isAdmin}
                        onChange={onChange}
                        handleReorderList={handleReorderList}
                        setField={setField}
                    />
                    <Box>
                        <Paper>
                            <Box padding={2} margin={2}>
                                <Typography component="h3" variant="h6">
                                    Lead/Email Settings
                                </Typography>
                                <Typography component="p" variant="body1">
                                    Information used when form is submitted
                                </Typography>
                                <Email
                                    required={false}
                                    isAdmin={isAdmin}
                                    onChange={onChange}
                                    name="contactUsLeadEmailCcList"
                                    label="Lead Email Cc List"
                                    hint="Comma separated list of addresses to 'Cc:' on lead emails (e.g. jdoe@bayeq.com, kdoe@bayeq.com)"
                                    value={contactUsLeadEmailCcList}
                                    errors={
                                        !!errors[
                                            "contactUs.contactUsLeadEmailCcList"
                                        ]
                                    }
                                />
                                <Text
                                    required
                                    className={styles.field}
                                    name="contactUsConfirmEmailText"
                                    label="Confirmation Email Text"
                                    hint="Text to display in confirmation email"
                                    disabled={!isAdmin}
                                    hidden={!isAdmin}
                                    onChange={onChange}
                                    value={contactUsConfirmEmailText}
                                    errors={
                                        !!errors[
                                            "contactUs.contactUsConfirmEmailText"
                                        ]
                                    }
                                    rowsMax={3}
                                    multiline={true}
                                    fullWidth={true}
                                    inputProps={{
                                        "data-lpignore": "true",
                                        "data-label": "Confirmation Email Text",
                                    }}
                                />
                                <Text
                                    className={styles.field}
                                    name="contactUsBammUrl"
                                    label="Lead URL"
                                    hint="The URL to capture the lead information on form submission (only BAMM or Velocify are currently supported)"
                                    disabled={!isAdmin}
                                    hidden={!isAdmin}
                                    onChange={onChange}
                                    value={contactUsBammUrl}
                                    errors={
                                        !!errors["contactUs.contactUsBammUrl"]
                                    }
                                />
                            </Box>
                        </Paper>
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default ContactUs;
