import React, { PureComponent } from "react";

import ModuleList from "../ModuleList";
import withSurvey from "../withSurvey";
import HeroLarge from "./components/Hero";

import styles from "./styles.module.less";

class SplashDetail extends PureComponent {
    render() {
        const {
            firestoreId,
            applyNow,
            applyNowScript,
            name,
            slug,
            title,
            jobTitle,
            licenseIds,
            vanityUrl,
            modules = [],
            branch = {},
            team = {},
            blogs = [],
            corporateBlogs = [],
            gallery = {},
            isPreview = false,
            owner,
            testimonials: review = [],
            isSurveyInit: isReviewInit,
            hasSurveyErrored: hasReviewErrored,
            loanOfficers = [],
            managers = [],
            staff = [],
            headerImage,
            headerImageCustom,
            headerImageAlignment,
            headerTitle,
            headerSubTitle,
            headerDarkText,
            blogWord,
            templateKey,
            pageTitle,
            profile,
            ownerSlug,
            staticTextItems = [],
            facebook,
            twitter,
            linkedin,
            youtube,
            instagram,
            email,
            phone,
            mobilePhone,
            fax,
            stars,
            ratingsCount,
            shouldDisplaySurvey,
            testimonials,
            isSurveyInit,
            hasSurveyErrored,
            licenseLink,
        } = this.props;

        let galleryTmp = gallery;

        if (templateKey === "team") {
            galleryTmp = { photo: profile.logo };
        }

        const { photo = "" } = galleryTmp || {};

        const contactInfo = {
            address1: "",
            address2: "",
            address3: "",
            city: "",
            state: "",
            zip: "",
            phone,
            mobilePhone,
            fax,
            email,
        };

        if (branch) {
            contactInfo.address1 = branch.address1;
            contactInfo.address2 = branch.address2;
            contactInfo.address3 = branch.address3;
            contactInfo.city = branch.city;
            contactInfo.state = branch.state;
            contactInfo.zip = branch.zip;
        }

        const moduleListProps = {
            firestoreId,
            applyNow,
            applyNowScript,
            name,
            slug,
            ownerSlug: ownerSlug ? ownerSlug : "",
            title,
            jobTitle,
            licenseIds,
            modules,
            branch,
            team,
            blogs,
            corporateBlogs,
            twitter,
            contactInfo,
            isPreview,
            owner,
            ownerImg: photo,
            review,
            isReviewInit,
            hasReviewErrored,
            blogWord,
            loanOfficers,
            managers,
            staff,
            templateKey,
            socialIcons: {
                facebook: facebook,
                twitter: twitter,
                linkedin: linkedin,
                youtube: youtube,
                instagram: instagram,
            },
            gallery: galleryTmp,
            email,
            phone,
            mobilePhone,
            fax,
            stars,
            ratingsCount,
            testimonials,
            profile: profile,
            pageTitle,
            pageUrl: vanityUrl,
            isSurveyInit,
            hasSurveyErrored,
            shouldDisplaySurvey,
            pageType: "splash",
            licenseLink,
        };

        let homeHeroClassName = headerDarkText
            ? `${styles.homeHero} ${styles.homeHeroDark}`
            : styles.homeHero;

        return (
            <>
                <div>
                    <HeroLarge
                        background={headerImage}
                        customPhoto={headerImageCustom}
                        photoAlignment={headerImageAlignment}></HeroLarge>
                    <div className={styles.heroCopy}>
                        <div className={styles.inner}>
                            <div className={homeHeroClassName}>
                                <div className={styles.title}>
                                    {headerTitle}
                                </div>
                                <br />
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: headerSubTitle,
                                    }}></p>
                            </div>
                        </div>
                    </div>
                </div>
                <ModuleList
                    {...moduleListProps}
                    staticTextItems={staticTextItems}
                />
            </>
        );
    }
}

export default withSurvey(SplashDetail);
