import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import TestimonialsCarousel from "../TestimonialsCarousel";

import styles from "./styles.module.less";

const TestimonialsCareers = ({ staticTextItems }) => {
    const title = "WHAT OUR TEAM IS SAYING";

    const getItems = data => {
        var testimonials = data.split("\n\n");
        var items = [];
        for (var i = 0; i < testimonials.length; i += 3) {
            items.push({
                id: i.toString(),
                name: testimonials[i] ?? "",
                title: testimonials[i + 1] ?? "",
                text: `“${testimonials[i + 2]}”` ?? "",
            });
        }
        return items;
    };

    // get static text data
    let staticTextData = [];

    if (staticTextItems && staticTextItems.length > 0)
        staticTextData = staticTextItems;
    else {
        staticTextData = useStaticQuery(graphql`
            {
                allStaticText {
                    nodes {
                        id
                        html
                        title
                        page
                        adminOrderBy
                    }
                }
            }
        `).allStaticText.nodes;
    }

    const staticItem = staticTextData.find(
        item => item.id === "careers-testimonials",
    );

    const items = staticItem ? getItems(staticItem.html) : [];

    return (
        <div className={styles.TeamTestimonials}>
            <section
                className={styles.TestimonialSlider}
                data-background="photo">
                <div className={styles.inner}>
                    <TestimonialsCarousel title={title} items={items} useHtml />
                </div>
            </section>
        </div>
    );
};

export default TestimonialsCareers;
