import React from "react";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import DragHandleIcon from "@material-ui/icons/DragHandle";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import Uploader from "../../Uploader";
import VisibityIcon from "@material-ui/icons/Visibility";
import VisibityOffIcon from "@material-ui/icons/VisibilityOff";

import HeroImage from "../../HeroImage";

import styles from "../widgets.module.less";

const widget = "teamMembers";
const field = "display";
const name = `${widget}.${field}`;
const label = "Display Team Members?";
const id = `entry-module-${name}`;

const TeamMembers = ({ widget, onChange, onFileChange, isAdmin, ...rest }) => {
    const {
        display,
        backgroundImage = "",
        backgroundImageCustom = "",
    } = widget;

    return (
        <Accordion
            TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
            className={styles.Widget}>
            <AccordionSummary
                classes={{ content: styles.TitleBar }}
                expandIcon={<ExpandMoreIcon />}>
                <DragHandleIcon data-movable-handle />
                <Typography component="h4" variant="h6">
                    Team Members
                </Typography>
                {display ? (
                    <VisibityIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                ) : (
                    <VisibityOffIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                    <FormControlLabel
                        label={label}
                        control={
                            <Switch
                                color="primary"
                                id={id}
                                name={field}
                                checked={display}
                                onChange={onChange}
                                inputProps={{
                                    "data-label": label,
                                }}
                            />
                        }
                    />
                    <Typography component="p" variant="body1">
                        The team members widget will display loan officers,
                        managers, and staff members that are assigned to your
                        page.
                        <br />
                        Show or hide the team members in the widget manager.
                    </Typography>
                    <Paper>
                        <Box padding={2} margin={2}>
                            <Typography component="h3" variant="h6">
                                Background Image
                            </Typography>
                            <br />
                            <HeroImage
                                isAdmin={isAdmin}
                                name="backgroundImage"
                                value={backgroundImage}
                                collections={["Body"]}
                                onChange={onChange}
                            />
                            <Uploader
                                id={`entry-module-${widget}.backgroundImageCustom`}
                                entry={widget}
                                name={"backgroundImageCustom"}
                                label={"Custom Image"}
                                hint="Upload a background image (images must be in jpg format)"
                                value={backgroundImageCustom}
                                config={{
                                    imagesOnly: true,
                                    inputAcceptTypes: ".jpg, .jpeg",
                                    crop: "16:9",
                                    imageShrink: "2881x2017",
                                }}
                                handleFile={onFileChange}
                            />
                        </Box>
                    </Paper>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default TeamMembers;
