import React, { useState, useEffect } from "react";
import Frame from "react-frame-component";
import SplitPane from "react-split-pane";
import { noop, isEmpty } from "lodash";
import PropTypes from "prop-types";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Typography from "@material-ui/core/Typography";

// hero
import HeroImage from "../HeroImage";
import PhotoAlignment from "../PhotoAlignment";

// intro
import Title from "../Title";
import UrlSlug from "../UrlSlug";
import HidePage from "../HidePage";
import Disclaimer from "../Disclaimer";

// Profile
import Uploader from "../Uploader";
import Telephone from "../Telephone";
import AddressLine from "../AddressLine";
import City from "../City";
import State from "../State";
import ZipCode from "../Zip";
import Text from "../Text";
import LoanOfficerSelect from "../LoanOfficerSelect";
import TeamSelect from "../TeamSelect";
import Editor from "../Editor";

import Widgets from "../Widgets";

// change log
import ChangeLog from "../ChangeLog";

// scripts
import CustomScript from "../CustomScript";

// preview view
import CorporateCarePreview from "../CorporateCarePreview";

import styles from "./styles.module.less";

/**
 * Collect style elements from the current page for injection into preview iframe
 */
const getStyles = () => {
    let head = "";
    const sheets = Array.from(
        document.querySelectorAll("link[rel=stylesheet]"),
    );
    const styles = Array.from(document.querySelectorAll("head style"));

    sheets.forEach(link => {
        head += link.outerHTML;
    });

    styles.forEach(style => {
        head += style.outerHTML;
    });

    return head;
};

const stylesExt = theme => ({
    details: {
        flexDirection: "column",
    },
    button: {
        margin: theme.spacing(1),
    },
});

const CorporateCare = ({
    loaded = false,
    entry,
    errors,
    missing,
    isAdmin,
    classes,
    handleChange = noop,
    handleListChange = noop,
    handleBodyChange = noop,
    handleFile = noop,
    handleRef = noop,
    fetchCollection = noop,
}) => {
    // container for preview frame styles
    const [previewStyles, setPreviewStyles] = useState(null);
    // enables / disables pointer events on editor and preview during resizing
    const [blockInput, setBlockInput] = useState(false);

    useEffect(() => {
        if (!previewStyles) {
            setPreviewStyles(getStyles());
        }
    }, [previewStyles]);

    const onDragStarted = () => {
        setBlockInput(true);
    };

    const onDragFinished = () => {
        setBlockInput(false);
    };

    const hasErrorsInFields = (errors, fields = []) =>
        fields.some(field => !!errors[field]);

    let heroErrorColor = hasErrorsInFields(errors, [
        "hero.heroBackground",
        "hero.customPhoto",
        "hero.photoAlignment",
    ])
        ? "error"
        : "inherit";
    let companyLogoErrorColor = hasErrorsInFields(errors, ["companyLogo"])
        ? "error"
        : "inherit";
    let companyPhotoErrorColor = hasErrorsInFields(errors, ["companyPhoto"])
        ? "error"
        : "inherit";
    let messageErrorColor = hasErrorsInFields(errors, ["messageFrom"])
        ? "error"
        : "inherit";
    let realtorInfoErrorColor = hasErrorsInFields(errors, [
        "realtor.name",
        "realtor.title",
        "realtor.title2",
        "realtor.licenseNumber",
        "realtor.phone",
        "realtor.fax",
        "realtor.email",
        "realtor.url",
        "realtor.address",
        "realtor.city",
        "realtor.state",
        "realtor.zip",
        "realtor.photoUrl",
        "realtor.logoUrl",
    ])
        ? "error"
        : "inherit";
    let contactInfoErrorColor = hasErrorsInFields(errors, [
        "loanOfficer",
        "team",
    ])
        ? "error"
        : "inherit";
    let creditInfoErrorColor = hasErrorsInFields(errors, [
        "lenderCreditPercentage",
        "realtorCommissionPercentage",
        "realtorCreditPercentage",
    ])
        ? "error"
        : "inherit";

    let loClassName = !!blockInput
        ? `${styles.LoanOfficer} ${styles.blockEntry}`
        : styles.LoanOfficer;
    let frmClassName = !!blockInput
        ? `${styles.previewFrame} ${styles.blockEntry}`
        : styles.previewFrame;

    // don't bother if we don't have an entry to display
    if (!loaded) return null;
    if (isEmpty(entry)) return null;

    return (
        <SplitPane
            split="vertical"
            minSize={320}
            defaultSize="40%"
            style={{
                height: "calc(100% - 64px)",
            }}
            onDragStarted={onDragStarted}
            onDragFinished={onDragFinished}>
            <div className={loClassName}>
                <Title
                    label="Page Title (Company Name)"
                    hint="Should be the Company Name used as URL slug (e.g. “ABC Company” becomes “abc-company” slug)"
                    isAdmin={isAdmin}
                    onChange={handleChange}
                    value={entry.title}
                    errors={errors}
                />
                <UrlSlug
                    isAdmin={isAdmin}
                    onChange={handleChange}
                    entry={entry}
                    errors={errors}
                />
                <HidePage
                    isAdmin={isAdmin}
                    onChange={handleChange}
                    entry={entry}
                    errors={errors}
                    hint="Hide this page from web site visitors"
                />
                <Paper elevation={1}>
                    <Box padding={2} margin={2}>
                        <Disclaimer
                            isAdmin={isAdmin}
                            onChange={handleChange}
                            entry={entry}
                            errors={errors}
                        />
                    </Box>
                </Paper>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography
                            component="h3"
                            variant="h6"
                            color={heroErrorColor}>
                            Page Header
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box padding={2} margin={2}>
                            <HeroImage
                                isAdmin={isAdmin}
                                collections={[
                                    "Generic",
                                    "Regional",
                                    "Site Specific",
                                    "G2H",
                                ]}
                                value={
                                    entry.hero ? entry.hero.heroBackground : ""
                                }
                                onChange={handleChange}
                            />

                            <Uploader
                                name="hero.customPhoto"
                                label="Custom Photo"
                                hint="Upload your custom header photo (images must be 2881x2017, in jpg format)"
                                entry={entry}
                                config={{
                                    imagesOnly: true,
                                    inputAcceptTypes: ".jpg, .jpeg",
                                    crop: "16:9",
                                    imageShrink: "2881x2017",
                                }}
                                handleFile={handleFile}
                            />
                            <PhotoAlignment
                                name="hero.photoAlignment"
                                value={
                                    entry.hero ? entry.hero.photoAlignment : ""
                                }
                                onChange={handleChange}
                                defaultValue="center"
                                entry={entry}
                                errors={errors}
                            />
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography
                            component="h3"
                            variant="h6"
                            color={companyLogoErrorColor}>
                            Company Logo
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box padding={1} margin={1}>
                            <Uploader
                                name="companyLogo"
                                label="Logo"
                                hint="Upload company logo (images must be in jpg/png format)"
                                entry={entry}
                                config={{
                                    imagesOnly: true,
                                    inputAcceptTypes: ".jpg, .jpeg, .png",
                                }}
                                handleFile={handleFile}
                                onChange={handleChange}
                                logo
                            />
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography
                            component="h3"
                            variant="h6"
                            color={companyPhotoErrorColor}>
                            Company Photo
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box padding={1} margin={1}>
                            <Uploader
                                name="companyPhoto"
                                label="Photo"
                                hint="Upload company photo (images must be in jpg/png format)"
                                entry={entry}
                                config={{
                                    imagesOnly: true,
                                    inputAcceptTypes: ".jpg, .jpeg, .png",
                                }}
                                handleFile={handleFile}
                                onChange={handleChange}
                                logo
                            />
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography
                            component="h3"
                            variant="h6"
                            color={contactInfoErrorColor}>
                            Loan Officer / Team
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.details}>
                        <LoanOfficerSelect
                            isAdmin={isAdmin}
                            onRefSelect={handleRef}
                            onChange={handleChange}
                            entry={entry}
                            errors={errors}
                            depRef="team"
                        />
                        <br />
                        or
                        <br />
                        <TeamSelect
                            isAdmin={isAdmin}
                            onRefSelect={handleRef}
                            onChange={handleChange}
                            entry={entry}
                            errors={errors}
                            depRef="loanOfficer"
                        />
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography
                            component="h3"
                            variant="h6"
                            color={messageErrorColor}>
                            Message
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box padding={1} margin={1}>
                            <Text
                                required
                                name="messageFrom"
                                label="Title"
                                disabled={!isAdmin}
                                hidden={!isAdmin}
                                onChange={handleChange}
                                errors={errors}
                                entry={entry}
                            />
                            <div className={styles.Editor}></div>
                            <Editor
                                name="body"
                                entry={entry}
                                onChange={handleBodyChange}
                                toolbarButtons="bold|italic|link|bullet|numbered|image"
                            />
                        </Box>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography
                            component="h3"
                            variant="h6"
                            color={realtorInfoErrorColor}>
                            Realtor
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box padding={1} margin={1}>
                            <Text
                                name="realtor.name"
                                label="Name"
                                disabled={!isAdmin}
                                hidden={!isAdmin}
                                onChange={handleChange}
                                entry={entry}
                                errors={errors}
                            />
                            <Text
                                name="realtor.title2"
                                label="Title"
                                hint="Realtor title (defaults to “Realtor”)"
                                disabled={!isAdmin}
                                hidden={!isAdmin}
                                onChange={handleChange}
                                entry={entry}
                                errors={errors}
                            />
                            <Text
                                name="realtor.title"
                                label="Company"
                                disabled={!isAdmin}
                                hidden={!isAdmin}
                                onChange={handleChange}
                                entry={entry}
                                errors={errors}
                            />
                            <Text
                                name="realtor.licenseNumber"
                                label="License #"
                                hint="Realtor license number"
                                disabled={!isAdmin}
                                hidden={!isAdmin}
                                onChange={handleChange}
                                entry={entry}
                                errors={errors}
                            />
                            <Telephone
                                name="realtor.phone"
                                label="Telephone"
                                hint="Format 555-555-5555"
                                isAdmin={isAdmin}
                                onChange={handleChange}
                                entry={entry}
                                errors={errors}
                            />
                            <Telephone
                                name="realtor.fax"
                                label="Fax"
                                hint="Format 555-555-5555"
                                isAdmin={isAdmin}
                                onChange={handleChange}
                                entry={entry}
                                errors={errors}
                            />
                            <Text
                                name="realtor.email"
                                label="Email"
                                disabled={!isAdmin}
                                hidden={!isAdmin}
                                onChange={handleChange}
                                entry={entry}
                                errors={errors}
                            />
                            <Text
                                name="realtor.url"
                                label="Site URL"
                                disabled={!isAdmin}
                                hidden={!isAdmin}
                                onChange={handleChange}
                                entry={entry}
                                errors={errors}
                            />
                            <AddressLine
                                name="realtor.address"
                                label="Address"
                                onChange={handleChange}
                                entry={entry}
                                errors={errors}
                            />
                            <City
                                name="realtor.city"
                                onChange={handleChange}
                                entry={entry}
                                errors={errors}
                            />
                            <State
                                name="realtor.state"
                                onChange={handleChange}
                                entry={entry}
                                errors={errors}
                            />
                            <ZipCode
                                name="realtor.zip"
                                onChange={handleChange}
                                entry={entry}
                                errors={errors}
                            />
                            <Uploader
                                name="realtor.photoUrl"
                                label="Photo"
                                hint="Upload realtor photo"
                                entry={entry}
                                config={{
                                    imagesOnly: true,
                                }}
                                defaultValue=""
                                handleFile={handleFile}
                                onChange={handleChange}
                            />
                            <Uploader
                                name="realtor.logoUrl"
                                label="Logo"
                                hint="Upload realtor logo"
                                entry={entry}
                                config={{
                                    imagesOnly: true,
                                }}
                                defaultValue=""
                                handleFile={handleFile}
                                onChange={handleChange}
                                logo
                            />
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Box mt={2}>
                    <Widgets
                        entry={entry}
                        errors={errors}
                        missing={missing}
                        handleListChange={handleListChange}
                        isAdmin={isAdmin}
                        pageType="corpCare"
                    />
                </Box>
                <ChangeLog
                    isAdmin={isAdmin}
                    onChange={handleChange}
                    value={entry.changelog}
                />
                <CustomScript
                    name="headBeginScript"
                    label="HEAD Beginning Script"
                    isAdmin={isAdmin}
                    onChange={handleChange}
                    entry={entry}
                    errors={errors}
                />
                <CustomScript
                    name="headEndScript"
                    label="HEAD Ending Script"
                    isAdmin={isAdmin}
                    onChange={handleChange}
                    entry={entry}
                    errors={errors}
                />
                <CustomScript
                    name="bodyBeginScript"
                    label="BODY Beginning Script"
                    isAdmin={isAdmin}
                    onChange={handleChange}
                    entry={entry}
                    errors={errors}
                />
                <CustomScript
                    name="bodyEndScript"
                    label="BODY Ending Script"
                    isAdmin={isAdmin}
                    onChange={handleChange}
                    entry={entry}
                    errors={errors}
                />
            </div>
            <Frame
                frameBorder="none"
                className={frmClassName}
                head={
                    <>
                        <style>
                            {
                                "html, body {background-color: #F6F6F6; margin: 0; padding: 0;}"
                            }
                        </style>
                        <base target="_blank" />
                    </>
                }
                // #6973: Had to add Uploadcare's AD script directly in order to get it to process images in iFrame.
                initialContent={`<!DOCTYPE html><html><head>${previewStyles}</head><body><div class="frame-root">
                    <script>
                        (function(src, cb) {
                            var s = document.createElement("script");
                            s.setAttribute("src", src);
                            s.onload = cb;
                            (document.head || document.body).appendChild(s);
                        })("https://ucarecdn.com/libs/blinkloader/3.x/blinkloader.min.js", function() {
                            window.Blinkloader.optimize({
                                pubkey: "6ffbab390389e8747e2c",
                                fadeIn: true,
                                lazyload: true,
                                smartCompression: true,
                                responsive: true,
                                retina: false,
                                webp: true,
                            });
                        });
                    </script>
                    <script src="https://embed.signalintent.com/js/embedded.js?org-guid=${process.env.GATSBY_SIGNAL_INTENT_ORG_GUID}"></script></div></body></html>`}>
                <CorporateCarePreview
                    entry={entry}
                    fetchCollection={fetchCollection}
                />
            </Frame>
        </SplitPane>
    );
};

CorporateCare.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(stylesExt)(CorporateCare);
