import React from "react";

import { navigate } from "gatsby";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import Avatar from "@material-ui/core/Avatar";

import VisibityIcon from "@material-ui/icons/Visibility";
import VisibityOffIcon from "@material-ui/icons/VisibilityOff";

import { entryUrls } from "../../lib/urls";

const LoanOfficerListItem = ({ loanOfficer }) => {
    const {
        id,
        title,
        hidden,
        gallery: { photo } = { photo: "" },
    } = loanOfficer;
    const to = `${entryUrls.loanOfficer}${id}`;
    const handleClick = to => e => {
        e.preventDefault();
        navigate(to);
    };

    return (
        <ListItem button onClick={handleClick(to)}>
            <ListItemAvatar>
                <Avatar alt={title} src={photo} />
            </ListItemAvatar>

            <ListItemText>{title}</ListItemText>
            <ListItemSecondaryAction>
                <ListItemIcon>
                    {!!hidden ? <VisibityOffIcon /> : <VisibityIcon />}
                </ListItemIcon>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default LoanOfficerListItem;
