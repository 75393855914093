import React from "react";
import TextField from "@material-ui/core/TextField";

const name = "hero.quote";
const hint =
    "Add a feature testimonial or inspirational quote (150 character max)";
const label = "Message / Testimonial";

const HeroQuote = ({ isAdmin, entry, errors, ...rest }) => {
    const errorMsg = errors[name];
    const value = entry.hero.quote;
    const helperText = errorMsg || hint;
    const id = `entry-${name}`;

    return (
        <TextField
            autoComplete="off"
            id={id}
            name={name}
            label={label}
            error={!!errorMsg}
            helperText={helperText}
            value={value}
            fullWidth={true}
            margin="normal"
            inputProps={{
                "data-lpignore": "true",
                "data-label": label,
            }}
            {...rest}
        />
    );
};

export default HeroQuote;
