import React, { useState, useEffect } from "react";
import Frame from "react-frame-component";
import SplitPane from "react-split-pane";
import { noop, set } from "lodash";
import PropTypes from "prop-types";
import { navigate } from "gatsby";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Loader from "../components/Loader";
import { withStyles } from "@material-ui/core/styles";

import Save from "@material-ui/icons/SaveAlt";
import Publish from "@material-ui/icons/Publish";
import ArrowBack from "@material-ui/icons/ArrowBack";

import SalesLeaders from "../Entry/components/SalesLeaders";
import Editor from "../Entry/components/Editor";

// preview view
import SalesLeadershipPreview from "../SalesLeadershipPreview";

import * as styles from "./styles.module.less";
import { getFirestoreDb } from "../../../config/cms.config";

const db = getFirestoreDb();

const stylesExt = theme => ({
    details: {
        flexDirection: "column",
    },
    button: {
        margin: theme.spacing(1),
    },
});

const defaultState = {
    loaded: false,
    dirty: false,
};

const SalesLeadership = props => {
    const {
        publishDocument = noop,
        publishSite = noop,
        collection = "leadership",
        id = "sales",
    } = props;
    const [previewStyles, setPreviewStyles] = useState(null);
    const [blockInput] = useState(false);
    const [showNotSaved, setShowNotSaved] = useState(false);
    const [state, setState] = useState(defaultState);
    const dirtyColor = !!state.dirty ? "secondary" : "inherit";
    const frmClassName = !!blockInput
        ? `${styles.previewFrame} ${styles.blockEntry}`
        : styles.previewFrame;

    const getStyles = () => {
        let head = "";
        const sheets = Array.from(
            document.querySelectorAll("link[rel=stylesheet]"),
        );
        const styles = Array.from(document.querySelectorAll("head style"));

        sheets.forEach(link => {
            head += link.outerHTML;
        });

        styles.forEach(style => {
            head += style.outerHTML;
        });

        return head;
    };

    const fetchData = async () => {
        var ref = db.doc(`${collection}/${id}`);
        let doc = await ref.get();
        if (doc.exists) {
            let id = doc.id;
            let entry = doc.data();
            let loaded = true;
            let dirty = false;
            setState({ ...state, id, entry, loaded, dirty });
        }
    };

    useEffect(() => {
        async function getData() {
            await fetchData();
        }
        getData();
    }, []);

    useEffect(() => {
        if (!previewStyles) {
            setPreviewStyles(getStyles());
        }
    }, [previewStyles]);

    const handlePublish = async e => {
        const { entry } = state;
        await publishDocument(entry, collection, id);
        await publishSite();
        eject();
    };

    const handleSave = async e => {
        const { entry } = state;
        setState({ ...state, loaded: false });
        await publishDocument(entry, collection, id);
        await fetchData();
    };

    const eject = () => {
        navigate(`/admin/`);
    };

    const handleNavigateBack = e => {
        if (!!state.dirty) {
            setShowNotSaved(true);
            return;
        }
        eject();
    };

    const handleNavigateBackWithoutSaving = e => {
        setShowNotSaved(false);
        eject();
    };

    const DiscardChangesDialog = ({ open, onCancel, onContinue }) => {
        return (
            <Dialog open={open} onClose={onCancel}>
                <DialogTitle>Discard changes?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        There are unsaved changes.
                        <br />
                        Click "No" to continue working and save changes.
                        <br />
                        Click "Yes" to discard and return to the list.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>No, continue working</Button>
                    <Button onClick={onContinue}>Yes, discard changes</Button>
                </DialogActions>
            </Dialog>
        );
    };

    const handleBodyChange = (body, name = "body") => {
        let { entry } = state;
        set(entry, name, body);
        setState({ ...state, entry, dirty: true });
    };

    const handleListChange = (name, list) => {
        let { entry } = state;
        let l = [].concat(list);
        set(entry, name, l);
        setState({
            ...state,
            entry: { ...entry },
            dirty: true,
        });
    };

    // don't bother if we don't have an entry to display
    if (!state.loaded) return null;

    const previewData = { data: { sales: { nodes: [{ ...state.entry }] } } };

    return (
        <div className={styles.SalesLeadership}>
            <DiscardChangesDialog
                open={showNotSaved}
                onCancel={e => setShowNotSaved(false)}
                onContinue={handleNavigateBackWithoutSaving}
            />
            <AppBar position="sticky" color="default">
                <Toolbar>
                    <Button color="inherit" onClick={handleNavigateBack}>
                        <ArrowBack />
                        Back
                    </Button>
                    <Typography
                        component="span"
                        variant="h6"
                        color={dirtyColor}
                        className={styles.Title}>
                        Sales Leadership
                    </Typography>

                    <Button
                        color={dirtyColor}
                        variant="contained"
                        onClick={handleSave}
                        className={styles.ToolbarButton}>
                        Save
                        <Save />
                    </Button>

                    <Button
                        color="default"
                        variant="contained"
                        onClick={handlePublish}
                        className={styles.ToolbarButton}>
                        Publish
                        <Publish />
                    </Button>
                </Toolbar>
            </AppBar>
            <SplitPane
                split="vertical"
                minSize={320}
                defaultSize="40%"
                style={{
                    height: "calc(100% - 64px)",
                }}>
                <div className={styles.adminPane}>
                    <Loader loading={!state.loaded} />

                    <Box marginTop={2}>
                        <Typography component="h3" variant="h6">
                            Header
                        </Typography>
                        <Box marginTop={1}>
                            <Editor
                                name="header"
                                entry={{ body: state.entry.header }}
                                onChange={body =>
                                    handleBodyChange(body, "header")
                                }
                                toolbarButtons="bold|italic|link|bullet|numbered"
                            />
                        </Box>
                    </Box>

                    <SalesLeaders
                        field="leaders"
                        label="Sales Leaders"
                        values={state.entry.leaders}
                        handleListChange={handleListChange}
                    />
                </div>
                <Frame
                    key={`frame-sales-leadership`}
                    frameBorder="none"
                    className={frmClassName}
                    head={
                        <>
                            <style>
                                {
                                    "html, body {background-color: #F6F6F6; margin: 0; padding: 0;}"
                                }
                            </style>
                            <base target="_blank" />
                        </>
                    }
                    // #6973: Had to add Uploadcare's AD script directly in order to get it to process images in the iFrame.
                    initialContent={`<!DOCTYPE html><html><head>${previewStyles}</head><body><div class="frame-root">
                    <script>
                        (function(src, cb) {
                            var s = document.createElement("script");
                            s.setAttribute("src", src);
                            s.onload = cb;
                            (document.head || document.body).appendChild(s);
                        })("https://ucarecdn.com/libs/blinkloader/3.x/blinkloader.min.js", function() {
                            window.Blinkloader.optimize({
                                pubkey: "6ffbab390389e8747e2c",
                                fadeIn: true,
                                lazyload: true,
                                smartCompression: true,
                                responsive: true,
                                retina: false,
                                webp: true,
                            });
                        });
                    </script>
                    <script src="https://embed.signalintent.com/js/embedded.js?org-guid=${process.env.GATSBY_SIGNAL_INTENT_ORG_GUID}"></script></div></body></html>`}>
                    <SalesLeadershipPreview {...previewData} />
                </Frame>
            </SplitPane>
        </div>
    );
};

SalesLeadership.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(stylesExt)(SalesLeadership);
