import React from "react";

import { navigate } from "gatsby";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import Avatar from "@material-ui/core/Avatar";

import VisibityIcon from "@material-ui/icons/Visibility";
import VisibityOffIcon from "@material-ui/icons/VisibilityOff";

import { entryUrls } from "../../lib/urls";

const GivingToHeroesListItem = ({ g2HPage }) => {
    const { id, title, subTitle, hidden } = g2HPage;
    const to = `${entryUrls.g2HPage}${id}`;
    const handleClick = to => e => {
        e.preventDefault();
        navigate(to);
    };

    const logo =
        "https://baycdn.blob.core.windows.net/homeloans/site-assets/giving-to-heroes/giving-to-heroes-logo-solid.png";

    return (
        <ListItem button onClick={handleClick(to)}>
            <ListItemAvatar>
                <Avatar alt={title} src={logo} />
            </ListItemAvatar>
            <ListItemText primary={title} secondary={subTitle} />
            <ListItemSecondaryAction>
                <ListItemIcon>
                    {!!hidden ? <VisibityOffIcon /> : <VisibityIcon />}
                </ListItemIcon>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default GivingToHeroesListItem;
