import React, { useState, useEffect } from "react";
import Frame from "react-frame-component";
import SplitPane from "react-split-pane";
import { noop } from "lodash";
import PropTypes from "prop-types";
import { navigate } from "gatsby";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Loader from "../components/Loader";
import { withStyles } from "@material-ui/core/styles";

import Save from "@material-ui/icons/SaveAlt";
import Publish from "@material-ui/icons/Publish";
import ArrowBack from "@material-ui/icons/ArrowBack";

import Editor from "../Entry/components/Editor";
import ReactSelectMaterialUi from "react-select-material-ui";

// preview view
import StaticTextPreview from "../StaticTextPreview";

import styles from "./styles.module.less";
import { Text } from "slate";

/**
 * Collect style elements from the current page for injection into preview iframe
 */
const getStyles = () => {
    let head = "";
    const sheets = Array.from(
        document.querySelectorAll("link[rel=stylesheet]"),
    );
    const styles = Array.from(document.querySelectorAll("head style"));

    sheets.forEach(link => {
        head += link.outerHTML;
    });

    styles.forEach(style => {
        head += style.outerHTML;
    });

    return head;
};

const stylesExt = theme => ({
    details: {
        flexDirection: "column",
    },
    button: {
        margin: theme.spacing(1),
    },
});

const StaticText = ({
    publishSite = noop,
    fetchStaticTextItems = noop,
    batchStaticTextItems = noop,
    isAdmin = noop,
}) => {
    // container for preview frame styles
    const [previewStyles, setPreviewStyles] = useState(null);
    // enables / disables pointer events on editor and preview during resizing
    const [blockInput, setBlockInput] = useState(false);
    const [staticTextItems, setStaticTextItems] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [dirty, setDirty] = useState(false);
    const [errors, setErrors] = useState([]);
    const [showNotSaved, setShowNotSaved] = useState(false);

    const pageOptions = [
        {
            id: "home",
            label: "Home Page",
            description: "",
        },
        {
            id: "aboutUs",
            label: "About Us",
            description: "",
        },
        {
            id: "meetOurTeam",
            label: "Meet Our Team",
            description:
                "Bio text should be 2 paragraghs.  In responsive mode, the first paragraph will be bold.  In full mode, the 2 paragraphs will combined and displayed as 1 paragraph.",
        },
        {
            id: "fhaLoan",
            label: "FHA Loan",
            description: "",
        },
        {
            id: "jumboLoan",
            label: "Jumbo Loan",
            description: "",
        },
        {
            id: "servicing",
            label: "Servicing",
            description: "",
        },
        {
            id: "widget",
            label: "Widgets",
            description: "",
        },
        {
            id: "homeLoans",
            label: "Home Loans",
            description: "",
        },
        {
            id: "fixedRateMortgage",
            label: "Fixed-Rate Mortgage",
            description: "",
        },
        {
            id: "careers",
            label: "Careers Page",
            description: "",
        },
    ];
    const [page, setPage] = useState(pageOptions[0]);

    useEffect(() => {
        async function fetchData() {
            if (!isAdmin) {
                eject();
            }
            await fetchItems();
        }
        fetchData();
    }, []);

    const fetchItems = async () => {
        let staticTextItems = await fetchStaticTextItems();
        setStaticTextItems(staticTextItems);
        setLoaded(true);
        setDirty(false);
    };

    useEffect(() => {
        if (!previewStyles) {
            setPreviewStyles(getStyles());
        }
    }, [previewStyles]);

    const hasErrorsInFields = (errors, fields = []) =>
        fields.some(field => !!errors[field]);

    let headerErrorColor = React.useCallback(
        hasErrorsInFields(errors, ["headerTitle"]) ? "error" : "inherit",
        [errors],
    );

    let formSettingsErrorColor = React.useCallback(
        hasErrorsInFields(errors, ["contactUsTitle"]) ? "error" : "inherit",
        [errors],
    );

    let leadSettingsErrorColor = React.useCallback(
        hasErrorsInFields(errors, [
            "bammUrl",
            "leadEmailCcList",
            "confirmEmailText",
        ])
            ? "error"
            : "inherit",
        [errors],
    );
    let frmClassName = !!blockInput
        ? `${styles.previewFrame} ${styles.blockEntry}`
        : styles.previewFrame;

    /**
     * save and publish
     */
    const handlePublish = async e => {
        // do work
        await batchStaticTextItems(staticTextItems);
        // publish site
        await publishSite();
        eject();
    };

    const handleSave = async e => {
        setLoaded(false);
        // do work
        await batchStaticTextItems(staticTextItems);
        // reload
        await fetchItems();
    };

    const eject = () => {
        navigate(`/admin/`);
    };

    const handleSelectPage = async e => {
        const selectedPage = pageOptions.find(item => {
            return item.id === e.target.value;
        });
        setPage(selectedPage);
    };

    const handleNavigateBack = e => {
        if (!!dirty) {
            setShowNotSaved(true);
            return;
        }
        eject();
    };

    const handleNavigateBackWithoutSaving = e => {
        setShowNotSaved(false);
        eject();
    };

    const DiscardChangesDialog = ({ open, onCancel, onContinue }) => {
        return (
            <Dialog open={open} onClose={onCancel}>
                <DialogTitle>Discard changes?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        There are unsaved changes.
                        <br />
                        Click "No" to continue working and save changes.
                        <br />
                        Click "Yes" to discard and return to the list.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>No, continue working</Button>
                    <Button onClick={onContinue}>Yes, discard changes</Button>
                </DialogActions>
            </Dialog>
        );
    };

    const dirtyColor = !!dirty ? "secondary" : "inherit";

    // don't bother if we don't have an entry to display
    if (!loaded) return null;

    return (
        <div className={styles.StaticText}>
            <DiscardChangesDialog
                open={showNotSaved}
                onCancel={e => setShowNotSaved(false)}
                onContinue={handleNavigateBackWithoutSaving}
            />
            <AppBar position="sticky" color="default">
                <Toolbar>
                    <Button color="inherit" onClick={handleNavigateBack}>
                        <ArrowBack />
                        Back
                    </Button>
                    <Typography
                        component="span"
                        variant="h6"
                        color={dirtyColor}
                        className={styles.Title}>
                        Static Text
                    </Typography>

                    <Button
                        color={dirtyColor}
                        variant="contained"
                        onClick={handleSave}
                        className={styles.ToolbarButton}>
                        Save
                        <Save />
                    </Button>

                    <Button
                        color="default"
                        variant="contained"
                        onClick={handlePublish}
                        className={styles.ToolbarButton}>
                        Publish
                        <Publish />
                    </Button>
                </Toolbar>
            </AppBar>
            <SplitPane
                split="vertical"
                minSize={320}
                defaultSize="40%"
                style={{
                    height: "calc(100% - 64px)",
                }}>
                <div className={styles.adminPane}>
                    <Loader loading={!loaded} />
                    <Box hidden={!loaded} margin={1} padding={2}>
                        <Typography component="h1" variant="h6">
                            Static Text
                        </Typography>
                        <Typography component="p" variant="body1">
                            Manage custom text below. Save all changes before
                            publishing.
                            <br />
                            Upon publishing, text changes will applied to the
                            live site.
                            <br />
                            Text changes made to Community Care and Corporate
                            Care will apply to all pages of that type.
                        </Typography>
                    </Box>

                    <Box
                        margin={1}
                        paddingLeft={2}
                        paddingRight={2}
                        paddingBottom={2}>
                        <TextField
                            select
                            value={page.id}
                            label="Page"
                            onChange={handleSelectPage}
                            variant="filled"
                            fullWidth>
                            {pageOptions.map((pageOption, index) => {
                                return (
                                    <option key={index} value={pageOption.id}>
                                        {pageOption.label}
                                    </option>
                                );
                            })}
                        </TextField>
                    </Box>

                    {page && page.description !== "" && (
                        <Box
                            margin={1}
                            paddingLeft={2}
                            paddingRight={2}
                            paddingBottom={2}>
                            <Typography component="p" variant="body1">
                                {page.description}
                            </Typography>
                        </Box>
                    )}

                    <StaticTextList
                        selectedPage={page}
                        loaded={loaded}
                        staticTextItems={staticTextItems}
                        setStaticTextItems={setStaticTextItems}
                        setDirty={setDirty}></StaticTextList>
                </div>
                <Frame
                    key={`frame-${page.id}`}
                    frameBorder="none"
                    className={frmClassName}
                    head={
                        <>
                            <style>
                                {
                                    "html, body {background-color: #F6F6F6; margin: 0; padding: 0;}"
                                }
                            </style>
                            <base target="_blank" />
                        </>
                    }
                    // #6973: Had to add Uploadcare's AD script directly in order to get it to process images in the iFrame.
                    initialContent={`<!DOCTYPE html><html><head>${previewStyles}</head><body><div class="frame-root">
                    <script>
                        (function(src, cb) {
                            var s = document.createElement("script");
                            s.setAttribute("src", src);
                            s.onload = cb;
                            (document.head || document.body).appendChild(s);
                        })("https://ucarecdn.com/libs/blinkloader/3.x/blinkloader.min.js", function() {
                            window.Blinkloader.optimize({
                                pubkey: "6ffbab390389e8747e2c",
                                fadeIn: true,
                                lazyload: true,
                                smartCompression: true,
                                responsive: true,
                                retina: false,
                                webp: true,
                            });
                        });
                    </script>
                    <script src="https://embed.signalintent.com/js/embedded.js?org-guid=${process.env.GATSBY_SIGNAL_INTENT_ORG_GUID}"></script></div></body></html>`}>
                    <StaticTextPreview
                        pageType={page.id}
                        staticTextItems={staticTextItems}
                    />
                </Frame>
            </SplitPane>
        </div>
    );
};

StaticText.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(stylesExt)(StaticText);

const StaticTextList = ({
    selectedPage,
    loaded,
    staticTextItems,
    setStaticTextItems,
    setDirty,
}) => {
    return (
        loaded &&
        staticTextItems.map((staticTextItem, index) => {
            // only show items for the selected page
            if (staticTextItem.page === selectedPage.id) {
                const getStaticTextChangeHandler = staticText => {
                    // selecting a page could cause this change handler to run, so check that the value was actually changed
                    if (staticText !== staticTextItem.html) {
                        staticTextItems[index].html = staticText;
                        staticTextItems[index].dirty = true;

                        setStaticTextItems([].concat(staticTextItems));
                        setDirty(true);
                    }
                };

                return (
                    <StaticTextItem
                        key={staticTextItem.id}
                        title={staticTextItem.title}
                        html={staticTextItem.html}
                        onChange={getStaticTextChangeHandler}
                        dirty={staticTextItem.dirty}
                    />
                );
            } else return;
        })
    );
};

const StaticTextItem = ({ title, html, onChange, dirty }) => {
    const dirtyColor = !!dirty ? "secondary" : "inherit";

    return (
        <>
            <Box margin={1} padding={2}>
                <Typography component="h1" variant="h6" color={dirtyColor}>
                    {title}
                </Typography>
            </Box>
            <Box margin={1} paddingLeft={2} paddingRight={2} paddingBottom={2}>
                <Editor
                    name="html"
                    entry={{ body: html }}
                    value={html}
                    onChange={onChange}
                    toolbarButtons="bold|italic|link|bullet|numbered"
                />
            </Box>
        </>
    );
};
