import React from "react";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import DragHandleIcon from "@material-ui/icons/DragHandle";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import VisibityIcon from "@material-ui/icons/Visibility";
import VisibityOffIcon from "@material-ui/icons/VisibilityOff";

import styles from "../widgets.module.less";

const widget = "calculator";
const field = "display"; // used for saving widget data
const name = `${widget}.${field}`; // used for validation and error messages
const label = "Display Loan Calculators?";
const id = `entry-module-${name}`;

const MortgageCalculator = ({ widget, onChange, ...rest }) => {
    const {
        display,
        showCalculatorMortgage,
        showCalculatorRentVsBuy,
        showCalculatorHomeAffordability,
        showCalculatorRequiredIncome,
        showCalculatorRefinance,
    } = widget;

    return (
        <Accordion
            TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
            className={styles.Widget}>
            <AccordionSummary
                classes={{ content: styles.TitleBar }}
                expandIcon={<ExpandMoreIcon />}>
                <DragHandleIcon data-movable-handle />
                <Typography component="h4" variant="h6">
                    Loan Calculators
                </Typography>
                {display ? (
                    <VisibityIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                ) : (
                    <VisibityOffIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                    <FormControlLabel
                        label={label}
                        control={
                            <Switch
                                color="primary"
                                id={id}
                                name={field}
                                checked={display}
                                onChange={onChange}
                                inputProps={{
                                    "data-label": label,
                                }}
                            />
                        }
                        style={{ paddingBottom: "20px" }}
                    />
                    <Typography component="p" variant="body1">
                        Select the calculators that will be displayed.
                    </Typography>
                    <Box>
                        <FormControlLabel
                            label="Mortgage Calculator"
                            control={
                                <Checkbox
                                    color="primary"
                                    id="showCalculatorMortgage"
                                    name="showCalculatorMortgage"
                                    checked={showCalculatorMortgage}
                                    onChange={onChange}
                                    inputProps={{
                                        "data-label": label,
                                    }}
                                />
                            }
                            style={{ paddingLeft: "20px" }}
                        />
                    </Box>
                    <Box>
                        <FormControlLabel
                            label="Home Rent vs Buy"
                            control={
                                <Checkbox
                                    color="primary"
                                    id="showCalculatorRentVsBuy"
                                    name="showCalculatorRentVsBuy"
                                    checked={showCalculatorRentVsBuy}
                                    onChange={onChange}
                                    inputProps={{
                                        "data-label": label,
                                    }}
                                />
                            }
                            style={{ paddingLeft: "20px" }}
                        />
                    </Box>
                    <Box>
                        <FormControlLabel
                            label="Home Affordability"
                            control={
                                <Checkbox
                                    color="primary"
                                    id="showCalculatorHomeAffordability"
                                    name="showCalculatorHomeAffordability"
                                    checked={showCalculatorHomeAffordability}
                                    onChange={onChange}
                                    inputProps={{
                                        "data-label": label,
                                    }}
                                />
                            }
                            style={{ paddingLeft: "20px" }}
                        />
                    </Box>
                    <Box>
                        <FormControlLabel
                            label="Mortgage Required Income"
                            control={
                                <Checkbox
                                    color="primary"
                                    id="showCalculatorRequiredIncome"
                                    name="showCalculatorRequiredIncome"
                                    checked={showCalculatorRequiredIncome}
                                    onChange={onChange}
                                    inputProps={{
                                        "data-label": label,
                                    }}
                                />
                            }
                            style={{ paddingLeft: "20px" }}
                        />
                    </Box>
                    <Box>
                        <FormControlLabel
                            label="Home Refinance"
                            control={
                                <Checkbox
                                    color="primary"
                                    id="showCalculatorRefinance"
                                    name="showCalculatorRefinance"
                                    checked={showCalculatorRefinance}
                                    onChange={onChange}
                                    inputProps={{
                                        "data-label": label,
                                    }}
                                />
                            }
                            style={{ paddingLeft: "20px" }}
                        />
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default MortgageCalculator;
