import React from "react";
import { List, arrayMove } from "react-movable";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Uploader from "../Uploader";
import Editor from "../Editor";
import InputLabel from "@material-ui/core/InputLabel";
import * as styles from "./styles.module.less";

const defaultLeader = {
    name: "",
    photoUrl:
        "https://ucarecdn.com/b2aaa0cb-d242-48d7-ac87-6cc18f55d6ba/-/preview/200x200/be-circle-mark.png",
    title: "",
    location: "",
    license: "",
    bio: "",
};

const SalesLeaders = ({
    field = "leaders",
    label = "Sales Leaders",
    values,
    handleListChange,
}) => {
    const getHandler = index => {
        return e => {
            const { name, value } = e.target;
            values[index][name] = value;
            handleListChange(field, values);
        };
    };

    const getFileHandler = index => {
        return (name, cdnUrl) => {
            values[index][name] = cdnUrl;
            handleListChange(field, values);
        };
    };

    const getDeleter = index => {
        return e => {
            values.splice(index, 1);
            handleListChange(field, values);
        };
    };

    const add = () => {
        values.push(Object.assign({}, defaultLeader));
        handleListChange(field, values);
    };

    return (
        <Paper className={styles.salesLeaders} elevation={1}>
            <Box padding={2} margin={2}>
                <Typography component="h3" variant="h6">
                    {label}
                </Typography>
                <List
                    values={values}
                    onChange={({ oldIndex, newIndex }) => {
                        handleListChange(
                            field,
                            arrayMove(values, oldIndex, newIndex),
                        );
                    }}
                    renderList={({ children, props, isDragged }) => {
                        return (
                            <div
                                {...props}
                                style={{
                                    cursor: isDragged ? "grabbing" : "inherit",
                                }}>
                                {children}
                            </div>
                        );
                    }}
                    renderItem={({
                        value,
                        props,
                        isDragged,
                        isSelected,
                        index,
                    }) => {
                        // disable keyboard navigation on widgets
                        props.onKeyDown = e => {
                            return e;
                        };
                        const handleChange = getHandler(index);
                        const handleFile = getFileHandler(index);
                        const handleDelete = getDeleter(index);
                        return (
                            <div {...props}>
                                <Row
                                    key={index}
                                    isDragged={isDragged}
                                    row={value}
                                    handleChange={handleChange}
                                    handleFile={handleFile}
                                    handleDelete={handleDelete}
                                />
                            </div>
                        );
                    }}
                />
                <Box className={styles.add} marginTop={2}>
                    <IconButton onClick={add} margin={0}>
                        <AddIcon />
                    </IconButton>
                </Box>
            </Box>
        </Paper>
    );
};

export default SalesLeaders;

const Row = ({ row, isDragged, handleChange, handleFile, handleDelete }) => {
    const { photoUrl, name, title, location, license, bio } = row;
    const cls = isDragged ? `${styles.row} ${styles.isDragged}` : styles.row;
    return (
        <Box className={cls}>
            <Box>
                <DragHandleIcon data-movable-handle />
            </Box>
            <Box
                display="flex"
                alignItems="stretch"
                flexDirection="column"
                width="100%">
                <Box padding={1}>
                    <Uploader
                        name="photoUrl"
                        label="Photo"
                        slim
                        value={photoUrl}
                        defaultValue={defaultLeader.photoUrl}
                        config={{
                            imagesOnly: true,
                            crop: "1:1",
                            imageShrink: "500x500",
                        }}
                        handleFile={handleFile}
                    />
                </Box>
                <Box
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "15px",
                        width: "100%",
                    }}>
                    <TextField
                        name="name"
                        label="Full Name"
                        value={name}
                        onChange={handleChange}
                        margin="normal"
                        inputProps={{
                            "data-lpignore": "true",
                            "data-label": "Sales leader full name",
                        }}
                        fullWidth
                    />
                    <TextField
                        name="title"
                        label="Title"
                        value={title}
                        onChange={handleChange}
                        margin="normal"
                        inputProps={{
                            "data-lpignore": "true",
                            "data-label": "Sales leader job title",
                        }}
                        fullWidth
                    />
                </Box>
                <Box
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "15px",
                        width: "100%",
                    }}>
                    <TextField
                        name="location"
                        label="Location"
                        value={location}
                        onChange={handleChange}
                        margin="normal"
                        inputProps={{
                            "data-lpignore": "true",
                            "data-label": "Sales leader location",
                        }}
                        fullWidth
                    />
                    <TextField
                        name="license"
                        label="License"
                        value={license}
                        onChange={handleChange}
                        margin="normal"
                        inputProps={{
                            "data-lpignore": "true",
                            "data-label": "Sales leader license",
                        }}
                        fullWidth
                    />
                </Box>
                <Box marginTop={2}>
                    <InputLabel shrink color="secondary">
                        Bio
                    </InputLabel>
                    <Box marginTop={1}>
                        <Editor
                            name="bio"
                            entry={{ body: bio }}
                            onChange={body =>
                                handleChange({
                                    target: { name: "bio", value: body },
                                })
                            }
                            toolbarButtons="bold|italic|link|bullet|numbered"
                        />
                    </Box>
                </Box>
            </Box>
            <IconButton onClick={handleDelete}>
                <DeleteIcon />
            </IconButton>
        </Box>
    );
};
