import React, { useState, useEffect } from "react";
import { find, get } from "lodash";

import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Box from "@material-ui/core/Box";
import ReactSelectMaterialUi from "react-select-material-ui";

import { getFirestoreDb } from "../../../../../config/cms.config";

const db = getFirestoreDb();

const name = "nonOriginator";
const hint = "";
const label = "Non-Originator";

const NonOriginatorSelect = ({
    isAdmin,
    value: inValue,
    errors,
    onRefSelect,
    depRefs = [],
    setTemplateKey = false,
    ...rest
}) => {
    const [nonOriginators, setNonOriginators] = useState([]);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        if (nonOriginators.length === 0) {
            db.collection(`entries/non-originator/published`)
                .where("hidden", "==", false)
                .get()
                .then(querySnapshot => {
                    let nonOriginators = querySnapshot.docs.map(doc => {
                        let ref = doc.ref;
                        let id = doc.id;
                        let data = doc.data();
                        let label = data.title;
                        return {
                            id,
                            ref,
                            label,
                            value: id,
                        };
                    });
                    setNonOriginators(nonOriginators);
                });
        }
    }, [nonOriginators]);

    useEffect(() => {
        let disable = false;
        depRefs.forEach(depRef => {
            if (depRef && depRef.get) disable = true;
        });
        setDisabled(disable);
    }, [depRefs]);

    const errorMsg = errors[name];
    const docRef = inValue;
    const value = docRef ? docRef.id : {};
    let helperText = errorMsg || hint;
    const id = `entry-${name}`;
    const inputProps = {
        readOnly: !isAdmin,
        "data-label": label,
    };

    const selectProps = {
        isCreatable: false,
        isClearable: true,
        isMultiple: false,
    };

    const handleChange = value => {
        let nonOriginator = find(nonOriginators, { id: value });
        onRefSelect(
            name,
            nonOriginator ? nonOriginator.ref : null,
            setTemplateKey
                ? {
                      name: "templateKey",
                      value: "non-originator",
                  }
                : null,
        );
    };

    // if not admin, display a read-only non-originator
    if (!isAdmin) {
        let nonOriginator = find(nonOriginators, { id: value });
        let label = get(nonOriginator, "label", "Loading…");
        return (
            <div>
                <FormControl margin="normal" disabled={true}>
                    <FormLabel>Non-Originator</FormLabel>
                    <Box marginTop={1}>
                        <FormLabel>{label}</FormLabel>
                    </Box>
                </FormControl>
            </div>
        );
    }

    return (
        <ReactSelectMaterialUi
            name={name}
            id={id}
            placeholder="Select a non-originator…"
            label={label}
            value={value}
            disabled={disabled}
            error={!!errorMsg}
            onChange={handleChange}
            options={nonOriginators}
            fullWidth={true}
            SelectProps={selectProps}
            helperText={helperText}
            {...inputProps}
        />
    );
};

export default NonOriginatorSelect;
