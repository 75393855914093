import React, { useState, useEffect } from "react";
import { orderBy, cloneDeep } from "lodash";

import createSlug from "../../../../../lib/createSlug";

import TeamDetail from "../../../../TeamDetail";

import { branch as defaultBranch } from "../../../lib/defaults";

/**
 *
 * Flatten an entry for use in LoanOfficerDetail
 * Fetch branche and team from refs
 * Fetch lo blogs and corporate blogs
 */
const TeamPreview = ({
    fetchCollection,
    entry,
    loanOfficers = [],
    defaultTextItems = [],
}) => {
    const [branch, setBranch] = useState(
        Object.assign({}, defaultBranch(defaultTextItems)),
    );
    const [mappedLoanOfficers, setMappedLoanOfficers] = useState([]);
    const [news, setNews] = useState([]);

    useEffect(() => {
        (async () => {
            let branchRef = entry.profile.branch;
            let branchDoc = !!branchRef ? await branchRef.get() : null;
            let b = branchDoc && branchDoc.exists ? branchDoc.data() : {};
            setBranch(b);
        })();
    }, [entry.profile.branch]);

    useEffect(() => {
        (async () => {
            let promisedDocuments = loanOfficers.map(async lo => {
                let doc = !!lo && lo.get ? await lo.get() : null;
                let data = doc && doc.exists ? doc.data() : null;
                return data;
            });
            let docs = await Promise.all(promisedDocuments);
            setMappedLoanOfficers(docs.filter(doc => !!doc));
        })();
    }, [loanOfficers]);

    useEffect(() => {
        (async () => {
            let corporateBlogs = await fetchCollection(
                "corporateBlog",
                "published",
            );
            let corpSorted = orderBy(corporateBlogs, ["date"], ["desc"]);
            let itemsCorp = corpSorted.slice(0, 3).map(item => {
                item.date = item.date.toDate();
                return item;
            });
            setNews(itemsCorp);
        })();
    }, []);

    if (!entry) return null;

    let { profile, contact, urlSlug, title } = entry;

    return (
        <div style={{ pointerEvents: "auto" }}>
            <TeamDetail
                {...{ ...cloneDeep(entry), firestoreId: entry.id }}
                {...profile}
                {...contact}
                slug={createSlug(urlSlug, title)}
                branch={branch}
                loanOfficers={mappedLoanOfficers}
                corporateBlogs={news}
                blogs={[]}
                isPreview={true}
            />
        </div>
    );
};

export default TeamPreview;
