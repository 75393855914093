import React, { useEffect, useState } from "react";
import { get } from "lodash";
import { navigate } from "gatsby";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

import Avatar from "@material-ui/core/Avatar";

import { entryUrls } from "../../lib/urls";
import { galleryPhoto } from "../../lib/defaults";

const BlogListItem = ({ blog }) => {
    const [displayAuthor, setDisplayAuthor] = useState(null);
    const [byLine, setByLine] = useState("");
    const { id, title, date, author } = blog;
    const to = `${entryUrls.blog}${id}`;
    const displayDate =
        !!date && date.toDate
            ? date.toDate().toLocaleString("en-US", { dateStyle: "short" })
            : null;
    const handleClick = to => e => {
        e.preventDefault();
        navigate(to);
    };
    useEffect(() => {
        // eslint-disable-next-line wrap-iife
        (async function() {
            if (author && author.get) {
                let doc = await author.get();
                let lo = doc.data();
                let title = get(lo, "title", "");
                let photo = get(lo, "gallery.photo", "");
                let byLine = !!title ? <em>by {title}</em> : "";
                setDisplayAuthor({
                    title,
                    photo,
                });
                setByLine(byLine);
            }
        })();
    });
    return (
        <ListItem button onClick={handleClick(to)}>
            <BlogAvatar author={displayAuthor} />
            <ListItemText>
                {title} {byLine} {!!displayDate && ` - ${displayDate}`}
            </ListItemText>
        </ListItem>
    );
};

export default BlogListItem;

const DefaultAvatar = () => {
    return (
        <ListItemAvatar>
            <Avatar src={galleryPhoto} />
        </ListItemAvatar>
    );
};

const BlogAvatar = ({ author }) => {
    if (!author) {
        return <DefaultAvatar />;
    }
    let { title, photo } = author;
    return (
        <ListItemAvatar>
            <Avatar src={photo} title={title} />
        </ListItemAvatar>
    );
};
