import React, { useState, useEffect } from "react";
import { find, get } from "lodash";

import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Box from "@material-ui/core/Box";
import ReactSelectMaterialUi from "react-select-material-ui";

import { getFirestoreDb } from "../../../../../config/cms.config";

const db = getFirestoreDb();

const hint = "";
const label = "Branch";

const BranchSelect = ({
    isAdmin,
    name = "profile.branch",
    value: inValue,
    entry = null,
    errors,
    onRefSelect,
    depRefs = [],
    setTemplateKey = false,
    isClearable = false,
    ...rest
}) => {
    const [branches, setBranches] = useState([]);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        if (branches.length === 0) {
            db.collection(`entries/branch/published`)
                .get()
                .then(querySnapshot => {
                    let branches = querySnapshot.docs.map(doc => {
                        let ref = doc.ref;
                        let id = doc.id;
                        let data = doc.data();
                        let { title, state, zip, hidden } = data;
                        let hiddenLabel = hidden ? "(hidden)" : "";
                        let label = `${title}, ${state} ${zip} ${hiddenLabel}`;
                        return {
                            id,
                            ref,
                            label,
                            value: id,
                        };
                    });
                    setBranches(branches);
                });
        }
    }, [branches]);

    useEffect(() => {
        let disable = false;
        depRefs.forEach(depRef => {
            let ref = entry ? entry[depRef] : depRef;
            if (ref && ref.get) disable = true;
        });
        setDisabled(disable);
    }, [depRefs]);

    const errorMsg = errors[name];
    const docRef = entry ? entry.profile.branch : inValue;
    const value = docRef ? docRef.id : {};
    let helperText = errorMsg || hint;
    const id = `entry-${name}`;
    const inputProps = {
        readOnly: !isAdmin,
        "data-label": label,
    };

    const selectProps = {
        isCreatable: false,
        isClearable: isClearable,
        isMultiple: false,
    };

    const handleChange = value => {
        let branch = find(branches, { id: value });
        onRefSelect(
            name,
            branch ? branch.ref : null,
            setTemplateKey
                ? {
                      name: "templateKey",
                      value: "branch",
                  }
                : null,
        );
    };

    // if not admin, display a read-only branch
    if (!isAdmin) {
        let branch = find(branches, { id: value });
        let label = get(branch, "label", "Loading…");
        return (
            <div>
                <FormControl margin="normal" disabled={true}>
                    <FormLabel>Branch</FormLabel>
                    <Box marginTop={1}>
                        <FormLabel>{label}</FormLabel>
                    </Box>
                </FormControl>
            </div>
        );
    }

    return (
        <ReactSelectMaterialUi
            name={name}
            id={id}
            placeholder="Select a branch…"
            label={label}
            value={value}
            disabled={disabled}
            error={!!errorMsg}
            onChange={handleChange}
            options={branches}
            fullWidth={true}
            SelectProps={selectProps}
            helperText={helperText}
            {...inputProps}
        />
    );
};

BranchSelect.defaultProps = {
    name: "profile.branch",
    docRef: "entry.profile.branch",
};

export default BranchSelect;
