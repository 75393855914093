import React from "react";
import TextField from "@material-ui/core/TextField";

const hint =
    "Enter a Floify, Consumer Connect, or Encompass URL. Ex: https://example.floify.com/apply";
const label = "Apply Now / Log In URL";

const ApplyNow = ({ name = "applyNow", isAdmin, entry, errors, ...rest }) => {
    const errorMsg = errors[name];
    const value = entry[name];
    let helperText = errorMsg || hint;
    const id = `entry-${name}`;
    return (
        <TextField
            autoComplete="off"
            id={id}
            name={name}
            label={label}
            disabled={!isAdmin}
            error={!!errorMsg}
            helperText={helperText}
            value={value}
            hidden={!isAdmin}
            fullWidth={true}
            margin="normal"
            inputProps={{
                "data-lpignore": "true",
                "data-label": label,
            }}
            {...rest}
        />
    );
};

export default ApplyNow;
