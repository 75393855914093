import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import styles from "./styles.module.less";

const Loader = ({ loading }) => {
    if (loading) {
        return (
            <div className={styles.Loader}>
                <CircularProgress variant="indeterminate" />
            </div>
        );
    }
    return null;
};

export default Loader;
