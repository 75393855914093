import React from "react";

import TextField from "@material-ui/core/TextField";

const name = "hero.byline";
const hint = "Give credit for your quote or testimonial";
const label = "By Line";

const HeroByline = ({ isAdmin, entry, errors, ...rest }) => {
    const errorMsg = errors[name];
    const value = entry.hero.byline;
    const helperText = errorMsg || hint;
    const id = `entry-${name}`;

    return (
        <TextField
            autoComplete="off"
            id={id}
            name={name}
            label={label}
            error={!!errorMsg}
            helperText={helperText}
            value={value}
            fullWidth={true}
            margin="normal"
            inputProps={{
                "data-lpignore": "true",
                "data-label": label,
            }}
            {...rest}
        />
    );
};

export default HeroByline;
