import React, { useState, useEffect } from "react";
import { noop } from "lodash";
import { navigate } from "gatsby";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Loader from "../components/Loader";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { withStyles } from "@material-ui/core/styles";

import Save from "@material-ui/icons/SaveAlt";
import Publish from "@material-ui/icons/Publish";
import ArrowBack from "@material-ui/icons/ArrowBack";

import Editor from "../Entry/components/Editor";

import { Typography } from "@material-ui/core";

import styles from "./styles.module.less";

const stylesExt = theme => ({
    button: {
        margin: theme.spacing(1),
    },
});

const DefaultText = ({
    publishSite = noop,
    fetchDefaultTextItems = noop,
    batchDefaultTextItems = noop,
    isAdmin = noop,
}) => {
    const [defaultTextItems, setDefaultTextItems] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [dirty, setDirty] = useState(false);
    const [showNotSaved, setShowNotSaved] = useState(false);

    useEffect(() => {
        async function fetchData() {
            if (!isAdmin) {
                eject();
            }
            await fetchItems();
        }
        fetchData();
    }, []);

    const fetchItems = async () => {
        let defaultText = await fetchDefaultTextItems();
        setDefaultTextItems(defaultText);
        setLoaded(true);
        setDirty(false);
    };

    /**
     * save and publish
     */
    const handlePublish = async e => {
        // do work
        await batchDefaultTextItems(defaultTextItems);
        // publish site
        await publishSite();
        eject();
    };

    const handleSave = async e => {
        setLoaded(false);
        // do work
        await batchDefaultTextItems(defaultTextItems);
        // reload
        await fetchItems();
    };

    const eject = () => {
        navigate(`/admin/`);
    };

    const handleNavigateBack = e => {
        if (!!dirty) {
            setShowNotSaved(true);
            return;
        }
        eject();
    };

    const handleNavigateBackWithoutSaving = e => {
        setShowNotSaved(false);
        eject();
    };

    const DiscardChangesDialog = ({ open, onCancel, onContinue }) => {
        return (
            <Dialog open={open} onClose={onCancel}>
                <DialogTitle>Discard changes?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        There are unsaved changes.
                        <br />
                        Click "No" to continue working and save changes.
                        <br />
                        Click "Yes" to discard and return to the list.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>No, continue working</Button>
                    <Button onClick={onContinue}>Yes, discard changes</Button>
                </DialogActions>
            </Dialog>
        );
    };

    const dirtyColor = !!dirty ? "secondary" : "inherit";

    // don't bother if we don't have an entry to display
    if (!loaded) return null;

    return (
        <div className={styles.DefaultText}>
            <DiscardChangesDialog
                open={showNotSaved}
                onCancel={e => setShowNotSaved(false)}
                onContinue={handleNavigateBackWithoutSaving}
            />
            <AppBar position="sticky" color="default">
                <Toolbar>
                    <Button color="inherit" onClick={handleNavigateBack}>
                        <ArrowBack />
                        Back
                    </Button>
                    <Typography
                        component="span"
                        variant="h6"
                        color={dirtyColor}
                        className={styles.Title}>
                        Default Text
                    </Typography>

                    <Button
                        color={dirtyColor}
                        variant="contained"
                        onClick={handleSave}
                        className={styles.ToolbarButton}>
                        Save
                        <Save />
                    </Button>

                    <Button
                        color="default"
                        variant="contained"
                        onClick={handlePublish}
                        className={styles.ToolbarButton}>
                        Publish
                        <Publish />
                    </Button>
                </Toolbar>
            </AppBar>
            <Grid container spacing={0} component="div" justify="center">
                <Grid item xs={8}>
                    <Loader loading={!loaded} />
                    <Box hidden={!loaded} margin={1} padding={2}>
                        <Typography
                            component="h1"
                            variant="h6"
                            color={dirtyColor}>
                            Default Text
                        </Typography>
                        <Typography
                            component="p"
                            variant="body1"
                            color={dirtyColor}>
                            Manage default text below.
                            <br />
                            Items that use text editors in the admin forms will
                            have one here, otherwise they will have a standard
                            text input.
                            <br />
                            Upon saving, default text will applied to new items.
                        </Typography>
                    </Box>

                    <Box style={{ background: "white" }}>
                        <DefaultTextList
                            loaded={loaded}
                            defaultTextItems={defaultTextItems}
                            setDefaultTextItems={setDefaultTextItems}
                            setDirty={setDirty}
                        />
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

const DefaultTextList = ({
    loaded,
    defaultTextItems,
    setDefaultTextItems,
    setDirty,
}) => {
    return (
        loaded &&
        defaultTextItems.map((item, index) => {
            const getDefaultTextChangeHandler = e => {
                let { name, value } = e.target;
                defaultTextItems[index].text = value;
                defaultTextItems[index].dirty = true;
                defaultTextItems[index].isNew = false;

                setDefaultTextItems([].concat(defaultTextItems));
                setDirty(true);
            };
            const getDefaultTextChangeHandlerIsHtml = html => {
                // selecting a page could cause this change handler to run, so check that the value was actually changed
                if (html !== item.text) {
                    defaultTextItems[index].text = html;
                    defaultTextItems[index].dirty = true;

                    setDefaultTextItems([].concat(defaultTextItems));
                    setDirty(true);
                }
            };

            return (
                <div>
                    <DefaultTextItem
                        key={item.id}
                        item={item}
                        onChange={getDefaultTextChangeHandler}
                        onChangeIsHtml={getDefaultTextChangeHandlerIsHtml}
                    />
                </div>
            );
        })
    );
};

const DefaultTextItem = ({ item, onChange, onChangeIsHtml }) => {
    const { title, text, isHtml, dirty = false } = item;
    const dirtyColor = !!dirty ? "secondary" : "inherit";

    return (
        <>
            <Box margin={1} padding={2}>
                <Typography component="h1" variant="h6" color={dirtyColor}>
                    {title}
                </Typography>
            </Box>
            <Box margin={1} paddingLeft={2} paddingRight={2} paddingBottom={2}>
                {isHtml ? (
                    <Editor
                        name="html"
                        entry={{ body: text }}
                        value={text}
                        onChange={onChangeIsHtml}
                        toolbarButtons="bold|italic|link|bullet|numbered"
                    />
                ) : (
                    <TextField
                        name="text"
                        value={text}
                        onChange={onChange}
                        fullWidth={true}
                        multiline={true}
                    />
                )}
            </Box>
        </>
    );
};

export default withStyles(stylesExt)(DefaultText);
