import React from "react";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import DragHandleIcon from "@material-ui/icons/DragHandle";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import VisibityIcon from "@material-ui/icons/Visibility";
import VisibityOffIcon from "@material-ui/icons/VisibilityOff";

import styles from "../widgets.module.less";

const type = "instagram";
const field = "display";
const name = `${type}.${field}`;
const label = "Display Instgram Feed?";
const id = `entry-module-${name}`;

const Instagram = ({ widget, onChange, errors, hasErrors, ...rest }) => {
    const { display, instagramToken } = widget;
    const titleColor = !!hasErrors ? "error" : "inherit";

    const tokenValidatorName = `${type}.instagramToken`;
    const tokenErrorMsg = errors[tokenValidatorName];
    const tokenHelperTxt =
        tokenErrorMsg ||
        "You’ll need to authorize Instagram to display your feed. Once authorized, you’ll receive an access token to be added above.";

    const handleOpen = e => {
        return (
            window &&
            window.open(
                "/instagram",
                "insta-auth",
                "menubar=no,location=no,resizable=yes,scrollbars=yes,status=yes,width=600,height=600",
            )
        );
    };

    return (
        <Accordion
            TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
            className={styles.Widget}>
            <AccordionSummary
                classes={{ content: styles.TitleBar }}
                expandIcon={<ExpandMoreIcon />}>
                <DragHandleIcon data-movable-handle />
                <Typography component="h4" variant="h6" color={titleColor}>
                    Instagram Feed
                </Typography>
                {display ? (
                    <VisibityIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                ) : (
                    <VisibityOffIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                    <FormControlLabel
                        label={label}
                        control={
                            <Switch
                                color="primary"
                                id={id}
                                name={field}
                                checked={display}
                                onChange={onChange}
                                inputProps={{
                                    "data-label": label,
                                }}
                            />
                        }
                    />
                    <TextField
                        required
                        autoComplete="off"
                        id={id}
                        name={"instagramToken"}
                        label={"Instagram Authentication Token"}
                        error={!!tokenErrorMsg}
                        helperText={tokenHelperTxt}
                        value={instagramToken}
                        fullWidth={true}
                        margin="normal"
                        inputProps={{
                            "data-lpignore": "true",
                            "data-label": "Instagram Access Token",
                        }}
                        onChange={onChange}
                    />

                    <Button onClick={handleOpen}>
                        <OpenInNewIcon />
                        Authorize Instagram
                    </Button>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default Instagram;
