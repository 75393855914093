import React, { useState, useEffect } from "react";
import Frame from "react-frame-component";
import SplitPane from "react-split-pane";
import { noop, isEmpty, isDate } from "lodash";

import { KeyboardDatePicker } from "@material-ui/pickers";

// intro
import Title from "../BlogTitle";
import Owner from "../Owner";
import MetaDescription from "../MetaDescription";
import CategorySelect from "../CategorySelect";
import TagSelect from "../TagSelect";
import PhotoAlignment from "../PhotoAlignment";
import Uploader from "../Uploader";
import BlogHero from "../BlogHero";
import FeaturedBlogPost from "../FeaturedBlogPost";
import PublicBlog from "../PublicBlog";

// body / About You
import Editor from "../Editor";

// scripts
import CustomScript from "../CustomScript";

// preview view
import BlogPreview from "../BlogPreview";

import styles from "./styles.module.less";

import { getFirestoreDb } from "../../../../../config/cms.config";

const db = getFirestoreDb();

/**
 * Collect style elements from the current page for injection into preview iframe
 */
const getStyles = () => {
    let head = "";
    const sheets = Array.from(
        document.querySelectorAll("link[rel=stylesheet]"),
    );
    const styles = Array.from(document.querySelectorAll("head style"));

    sheets.forEach(link => {
        head += link.outerHTML;
    });

    styles.forEach(style => {
        head += style.outerHTML;
    });

    return head;
};

const normalizeDate = val => {
    if (isDate(val)) {
        return val;
    } else {
        return val && val.toDate ? val.toDate() : null;
    }
};

const Blog = ({
    loaded = false,
    entry,
    errors,
    isAdmin,
    email,
    handleChange = noop,
    handleFile = noop,
    handleBodyChange = noop,
}) => {
    // container for preview frame styles
    const [previewStyles, setPreviewStyles] = useState(null);
    // enables / disables pointer events on editor and preview during resizing
    const [blockInput, setBlockInput] = useState(false);
    const [author, setAuthor] = useState(entry.author ? entry.author : null);

    const fetchAuthor = async email => {
        let querySnapshot = await db
            .collection(`entries/loan-officer/published`)
            .where("owner", "==", email)
            .limit(1)
            .get();
        if (!querySnapshot.empty) {
            let doc = querySnapshot.docs[0];
            return doc;
        }
        return null;
    };

    useEffect(() => {
        if (!previewStyles) {
            setPreviewStyles(getStyles());
        }
    }, [previewStyles]);

    // load author object from ref
    useEffect(() => {
        (async () => {
            // if not admin, and author null, try to set author
            let newAuthor = null;
            let doc;
            if (entry.type === "individual") {
                if (!isAdmin && !entry.author) {
                    // LO blogger, set author if doesn't exist
                    doc = await fetchAuthor(email);
                    // set author without tripping dirty bit
                    entry.author = !!doc ? doc.ref : null;
                } else if (!!entry.owner) {
                    // load author for preview
                    let a = entry.owner;
                    if (typeof a === "string") {
                        doc = await fetchAuthor(a);
                        // set author without tripping dirty bit
                        entry.author = !!doc ? doc.ref : null;
                    } else {
                        // author is a document reference, get the reference doc
                        doc = !!a && a.get ? await a.get() : null;
                    }
                }
            }
            newAuthor = await getAuthorWithBranchData(doc);
            setAuthor(newAuthor);
        })();
    }, [entry.owner]);

    const getAuthorWithBranchData = async doc => {
        let author = doc && doc.exists ? doc.data() : null;
        if (
            author !== null &&
            author.hasOwnProperty("profile") &&
            author.profile !== null
        ) {
            let branch = await author.profile.branch.get();
            let branchData = branch && branch.exists ? branch.data() : null;
            author.profile.branch = branchData;
        }

        return author;
    };

    // ensure post type is set correctly
    useEffect(() => {
        if (!entry.type) {
            entry.type = isAdmin ? "corporate" : "individual";
        }
    }, []);

    const onDragStarted = () => {
        setBlockInput(true);
    };

    const onDragFinished = () => {
        setBlockInput(false);
    };

    let blogClassName = !!blockInput
        ? `${styles.Blog} ${styles.blockEntry}`
        : styles.Blog;
    let frmClassName = !!blockInput
        ? `${styles.previewFrame} ${styles.blockEntry}`
        : styles.previewFrame;

    let entryDate = !!entry.date ? normalizeDate(entry.date) : new Date();

    const handleDateChange = moment => {
        // simulate event
        let change = {
            target: {
                name: "date",
                type: "date",
                value: !!moment ? moment.toDate() : null,
            },
        };
        handleChange(change);
    };

    // don't bother if we don't have an entry to display
    if (!loaded) return null;
    if (isEmpty(entry)) return null;

    return (
        <SplitPane
            split="vertical"
            minSize={320}
            defaultSize="50%"
            style={{
                height: "calc(100% - 64px)",
            }}
            onDragStarted={onDragStarted}
            onDragFinished={onDragFinished}>
            <div className={blogClassName}>
                <Title
                    isAdmin={true}
                    onChange={handleChange}
                    value={entry.title}
                    errors={errors}
                />

                <Owner
                    isAdmin={isAdmin}
                    onChange={handleChange}
                    entry={entry}
                    errors={errors}
                    defaultValue={email}
                />

                <MetaDescription
                    onChange={handleChange}
                    entry={entry}
                    errors={errors}
                />

                <KeyboardDatePicker
                    name="date"
                    label="Date Published"
                    format="YYYY-MM-DD"
                    margin="dense"
                    value={entryDate}
                    onChange={handleDateChange}
                />

                <FeaturedBlogPost
                    isAdmin={isAdmin}
                    onChange={handleChange}
                    entry={entry}
                    errors={errors}
                />

                <PublicBlog
                    isAdmin={isAdmin}
                    onChange={handleChange}
                    entry={entry}
                    errors={errors}
                />

                <CategorySelect
                    onChange={handleChange}
                    entry={entry}
                    errors={errors}
                />

                <TagSelect
                    onChange={handleChange}
                    entry={entry}
                    errors={errors}
                />

                <BlogHero
                    onChange={handleChange}
                    entry={entry}
                    errors={errors}
                />

                <Uploader
                    name="image"
                    label="Custom Image"
                    hint="Add a featured image to your blog post."
                    entry={entry}
                    config={{
                        imagesOnly: true,
                    }}
                    handleFile={handleFile}
                />

                <PhotoAlignment
                    name="photoAlignment"
                    value={entry.photoAlignment}
                    onChange={handleChange}
                    defaultValue="center"
                    entry={entry}
                    errors={errors}
                />

                <Editor
                    name="body"
                    entry={entry}
                    onChange={handleBodyChange}
                    placeholder="A new blog post…"
                />

                <CustomScript
                    name="headBeginScript"
                    label="HEAD Beginning Script"
                    isAdmin={isAdmin}
                    onChange={handleChange}
                    entry={entry}
                    errors={errors}
                />
                <CustomScript
                    name="headEndScript"
                    label="HEAD Ending Script"
                    isAdmin={isAdmin}
                    onChange={handleChange}
                    entry={entry}
                    errors={errors}
                />
                <CustomScript
                    name="bodyBeginScript"
                    label="BODY Beginning Script"
                    isAdmin={isAdmin}
                    onChange={handleChange}
                    entry={entry}
                    errors={errors}
                />
                <CustomScript
                    name="bodyEndScript"
                    label="BODY Ending Script"
                    isAdmin={isAdmin}
                    onChange={handleChange}
                    entry={entry}
                    errors={errors}
                />
            </div>
            <Frame
                frameBorder="none"
                className={frmClassName}
                head={
                    <>
                        <style>
                            {
                                "html, body {background-color: #F6F6F6; margin: 0; padding: 0;}"
                            }
                        </style>
                        <base target="_blank" />
                    </>
                }
                // #6973: Had to add Uploadcare's AD script directly in order to get it to process images in iFrame.
                initialContent={`<!DOCTYPE html><html><head>${previewStyles}</head><body><div class="frame-root">
                    <script>
                        (function(src, cb) {
                            var s = document.createElement("script");
                            s.setAttribute("src", src);
                            s.onload = cb;
                            (document.head || document.body).appendChild(s);
                        })("https://ucarecdn.com/libs/blinkloader/3.x/blinkloader.min.js", function() {
                            window.Blinkloader.optimize({
                                pubkey: "6ffbab390389e8747e2c",
                                fadeIn: true,
                                lazyload: true,
                                smartCompression: true,
                                responsive: true,
                                retina: false,
                                webp: true,
                            });
                        });
                    </script></div></body></html>`}>
                <BlogPreview entry={entry} author={author} />
            </Frame>
        </SplitPane>
    );
};

export default Blog;
