import React from "react";

import { navigate } from "gatsby";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import Avatar from "@material-ui/core/Avatar";

import VisibityIcon from "@material-ui/icons/Visibility";
import VisibityOffIcon from "@material-ui/icons/VisibilityOff";
import PlaceIcon from "@material-ui/icons/Place";

import { entryUrls } from "../../lib/urls";

const BranchListItem = ({ branch }) => {
    const { id, title, hidden } = branch;
    const to = `${entryUrls.branch}${id}`;
    const handleClick = to => e => {
        e.preventDefault();
        navigate(to);
    };
    return (
        <ListItem button onClick={handleClick(to)}>
            <ListItemAvatar>
                <Avatar>
                    <PlaceIcon />
                </Avatar>
            </ListItemAvatar>

            <ListItemText>{title}</ListItemText>
            <ListItemSecondaryAction>
                <ListItemIcon>
                    {!!hidden ? <VisibityOffIcon /> : <VisibityIcon />}
                </ListItemIcon>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default BranchListItem;
