import React from "react";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";

const name = "disclaimer";
const hint = "";

const Disclaimer = ({
    isAdmin,
    value: inValue,
    entry = null,
    errors,
    ...rest
}) => {
    const errorMsg = errors[name];
    const value = entry ? entry[name] : inValue;
    let helperText = errorMsg || hint;
    const id = `entry-${name}`;
    let helperColor = !!errorMsg ? "error" : "inherit";
    return (
        <FormControl fullWidth={true} margin="normal">
            <Typography color={helperColor} component="h3" variant="h6">
                Action Required
            </Typography>
            <Typography component="p" variant="body2">
                I confirm I have permission to use all content, photos, images,
                testimonials and uploaded files.
                <br />I certify I have secured the rights to all assets, either
                by purchasing rights or by expressed written permission from the
                owner, and that they are all free from trademark or copyright
                restrictions.
            </Typography>
            <Typography component="p" variant="body2">
                If in doubt, I will contact{" "}
                <a href="mailto:marketingdesk@bayeq.com">
                    marketingdesk@bayeq.com
                </a>{" "}
                for guidance before uploading.
            </Typography>
            <FormControlLabel
                label="I agree"
                labelPlacement="end"
                control={
                    <Switch
                        id={id}
                        color="primary"
                        name={name}
                        checked={value}
                        {...rest}
                    />
                }
            />
            <FormHelperText error={!!errorMsg}>{helperText}</FormHelperText>
        </FormControl>
    );
};

export default Disclaimer;
