import React from "react";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import DragHandleIcon from "@material-ui/icons/DragHandle";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import VisibityIcon from "@material-ui/icons/Visibility";
import VisibityOffIcon from "@material-ui/icons/VisibilityOff";

import Editor from "../../Editor";
import Text from "../../Text";
import Uploader from "../../Uploader";
import HeroImage from "../../HeroImage";

// generic toggle
import Boolean from "../../Boolean";

import styles from "../widgets.module.less";

const widget = "profile";
const field = "display";
const name = `${widget}.${field}`;
const label = "Display Profile?";
const id = `entry-module-${name}`;

const compactViewField = "profileShowCompactView";
const compactViewName = `${widget}.${compactViewField}`;
const compactViewLabel = "Show Compact View?";
const compactViewId = `entry-module-${compactViewName}`;

const Profile = ({
    widget,
    onChange,
    onFileChange,
    onEditorChange,
    isAdmin,
    pageType,
    errors,
    hasErrors,
    ...rest
}) => {
    const {
        display,
        profileShowCompactView,
        profileTitle,
        profileBody,
        darkTitleText,
        useBackgroundImage,
        backgroundImage,
        backgroundImageCustom,
        overrideAddress,
        overrideAddressText,
        licensedInText,
    } = widget;
    const forceCompactView = pageType === "g2h";

    return (
        <Accordion
            TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
            className={styles.Widget}>
            <AccordionSummary
                classes={{ content: styles.TitleBar }}
                expandIcon={<ExpandMoreIcon />}>
                <DragHandleIcon data-movable-handle />
                <Typography component="h4" variant="h6">
                    Profile
                </Typography>
                {display ? (
                    <VisibityIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                ) : (
                    <VisibityOffIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                )}
            </AccordionSummary>

            <AccordionDetails>
                <Box>
                    <FormControl hidden={!isAdmin}>
                        <FormControlLabel
                            label={label}
                            control={
                                <Switch
                                    color="primary"
                                    id={id}
                                    name={field}
                                    checked={display}
                                    onChange={onChange}
                                    inputProps={{
                                        "data-label": label,
                                    }}
                                />
                            }
                        />
                    </FormControl>
                    <Typography component="p" variant="body1">
                        The profile widget will display about, contact, and
                        social networking information.
                        {isAdmin && (
                            <>
                                <br />
                                Show or hide the profile in the widget manager.
                            </>
                        )}
                    </Typography>
                    <FormControlLabel
                        className={styles.marginTop}
                        label={compactViewLabel}
                        control={
                            <Switch
                                color="primary"
                                id={compactViewId}
                                name={compactViewField}
                                checked={
                                    forceCompactView || profileShowCompactView
                                }
                                onChange={onChange}
                                disabled={forceCompactView}
                                inputProps={{
                                    "data-label": label,
                                }}
                            />
                        }
                    />
                    <Text
                        name="profileTitle"
                        label="Title"
                        disabled={!isAdmin}
                        hidden={!isAdmin}
                        onChange={onChange}
                        error={!!errors["profile.profileTitle"]}
                        errors={errors}
                        value={profileTitle}
                    />
                    <Typography
                        component="h4"
                        variant="h6"
                        style={{ paddingTop: "20px" }}>
                        About the page
                    </Typography>
                    <div className={styles.contentEditor}>
                        <Editor
                            name="contentText"
                            entry={{ body: profileBody }}
                            onChange={body => {
                                onEditorChange("profileBody", body);
                            }}
                        />
                    </div>
                    <Boolean
                        name="useBackgroundImage"
                        value={useBackgroundImage}
                        label="Use Background Image?"
                        hint="Use a background image or a white background"
                        onChange={onChange}
                    />
                    {useBackgroundImage && (
                        <Paper>
                            <Box padding={2} margin={2}>
                                <Boolean
                                    name="darkTitleText"
                                    value={darkTitleText}
                                    label="Use Dark Text?"
                                    hint="Display the Title and text in black for better contrast"
                                    onChange={onChange}
                                />
                                <br />
                                <Typography component="h3" variant="h6">
                                    Background Image
                                </Typography>
                                <br />
                                <HeroImage
                                    isAdmin={isAdmin}
                                    name="backgroundImage"
                                    value={backgroundImage}
                                    collections={["Body"]}
                                    onChange={onChange}
                                />
                                <Uploader
                                    name="backgroundImageCustom"
                                    label="Custom Image"
                                    value={backgroundImageCustom}
                                    config={{
                                        imagesOnly: true,
                                        inputAcceptTypes: ".jpg, .jpeg, .png",
                                        crop: "16:9",
                                        imageShrink: "2881x2017",
                                    }}
                                    handleFile={onFileChange}
                                    onChange={onChange}
                                    logo
                                />
                            </Box>
                        </Paper>
                    )}
                    {pageType === "loanOfficer" && (
                        <>
                            <Boolean
                                name="overrideAddress"
                                value={overrideAddress}
                                label="Override Address?"
                                // hint="Override the branch address text"
                                onChange={onChange}
                            />
                            {overrideAddress && (
                                <Paper>
                                    <Box padding={2} margin={2}>
                                        <Text
                                            name="overrideAddressText"
                                            label="Override Address Text"
                                            disabled={!isAdmin}
                                            hidden={!isAdmin}
                                            onChange={onChange}
                                            error={
                                                !!errors[
                                                    "profile.overrideAddressText"
                                                ]
                                            }
                                            errors={errors}
                                            value={overrideAddressText}
                                        />
                                    </Box>
                                </Paper>
                            )}
                        </>
                    )}
                    {pageType === "loanOfficer" && (
                        <Text
                            name="licensedInText"
                            label="Licensed In Text"
                            disabled={!isAdmin}
                            hidden={!isAdmin}
                            onChange={onChange}
                            error={!!errors["profile.licensedInText"]}
                            errors={errors}
                            value={licensedInText}
                        />
                    )}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default Profile;
