import React from "react";

import { navigate } from "gatsby";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import Avatar from "@material-ui/core/Avatar";

import VisibityIcon from "@material-ui/icons/Visibility";
import VisibityOffIcon from "@material-ui/icons/VisibilityOff";

import { entryUrls } from "../../lib/urls";
import BEbug from "../../../../images/bayequity_circle_mark.png";

const CommunityCareListItem = ({ communityCarePage }) => {
    const { id, hidden, title, subTitle } = communityCarePage;
    const to = `${entryUrls.communityCarePage}${id}`;
    const handleClick = to => e => {
        e.preventDefault();
        navigate(to);
    };

    return (
        <ListItem button onClick={handleClick(to)}>
            <ListItemAvatar>
                <Avatar alt={title} src={BEbug} />
            </ListItemAvatar>
            <ListItemText primary={title} secondary={subTitle} />
            <ListItemSecondaryAction>
                <ListItemIcon>
                    {!!hidden ? <VisibityOffIcon /> : <VisibityIcon />}
                </ListItemIcon>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default CommunityCareListItem;
