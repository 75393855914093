import React from "react";
import { noop } from "lodash";
import { List, arrayMove } from "react-movable";

import Paper from "@material-ui/core/Paper";
import FormHelperText from "@material-ui/core/FormHelperText";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Text from "../Text";

import Field from "./Field";
import styles from "./styles.module.less";

const hint = "";

const CustomContactForm = ({
    field,
    titleField = "contactFormTitle",
    contactFormTitle,
    contactFormSubtitle,
    showContactFormSubTitle = false,
    contactFormFields,
    predefinedInputs,
    errors,
    isAdmin,
    onChange = noop,
    handleReorderList = noop,
    setField,
    nonRemovableFields = [],
}) => {
    const errorMsg = errors[field];
    let helperText = errorMsg || hint;
    let formFields = contactFormFields;

    const getHandler = index => {
        return e => {
            const value =
                e.target.type === "checkbox"
                    ? e.target.checked
                    : e.target.value;
            const name = e.target.name;
            formFields[index][name] = value;
            setField(field, formFields);
        };
    };

    const getDeleter = index => {
        return e => {
            formFields.splice(index, 1);
            setField(field, formFields);
        };
    };

    const add = () => {
        formFields.push({ type: "", required: false });
        setField(field, formFields);
    };

    // setup field type options
    let typeOptions = [];
    for (let key in predefinedInputs.fields) {
        let field = predefinedInputs.fields[key];
        typeOptions.push({
            id: key,
            label: `${
                field.inputs.some(x => x.hasBammField && x.hasLeadField)
                    ? `${field.label} - BL`
                    : field.inputs.some(x => x.hasBammField)
                    ? `${field.label} - B`
                    : field.inputs.some(x => x.hasLeadField)
                    ? `${field.label} - L`
                    : field.label
            }`,
        });
    }
    typeOptions.push({
        id: "custom",
        label: "Custom Question",
    });

    return (
        <Box>
            <Paper>
                <Box padding={2} margin={2}>
                    <Typography component="h3" variant="h6">
                        Contact Form
                    </Typography>
                    <Typography component="p" variant="body1">
                        {hint}
                    </Typography>
                    <Text
                        required
                        name={titleField}
                        label="Form Title"
                        disabled={!isAdmin}
                        hidden={!isAdmin}
                        onChange={onChange}
                        error={!!errors["contactUs." + titleField]}
                        errors={errors}
                        value={contactFormTitle}
                    />
                    {showContactFormSubTitle && (
                        <Text
                            required
                            name="contactFormSubtitle"
                            label="Form Subtitle"
                            disabled={!isAdmin}
                            hidden={!isAdmin}
                            onChange={onChange}
                            error={!!errors["contactUs.contactFormSubtitle"]}
                            errors={errors}
                            value={contactFormSubtitle}
                        />
                    )}
                    <List
                        values={formFields}
                        onChange={({ oldIndex, newIndex }) => {
                            const reordered = arrayMove(
                                formFields,
                                oldIndex,
                                newIndex,
                            );
                            handleReorderList(reordered);
                        }}
                        renderList={({ children, props, isDragged }) => {
                            return (
                                <Box
                                    marginTop={2}
                                    {...props}
                                    errors={props.errors}
                                    missing={props.missing}
                                    style={{
                                        cursor: isDragged
                                            ? "grabbing"
                                            : "inherit",
                                    }}>
                                    {children}
                                </Box>
                            );
                        }}
                        renderItem={({ value, props, isDragged, index }) => {
                            const onChange = getHandler(index);
                            const handleClick = getDeleter(index);

                            return (
                                <div {...props}>
                                    <Field
                                        index={index}
                                        row={value}
                                        type={value.type}
                                        required={value.required}
                                        isDragged={isDragged}
                                        onChange={onChange}
                                        handleClick={handleClick}
                                        setField={setField}
                                        typeOptions={typeOptions}
                                        contactFormFields={contactFormFields}
                                        contactFormFieldName={field}
                                        nonRemovableFields={nonRemovableFields}
                                    />
                                </div>
                            );
                        }}
                    />
                    <Box className={styles.add} marginTop={2}>
                        <IconButton onClick={add} margin={0}>
                            <AddIcon />
                        </IconButton>
                    </Box>
                    <FormHelperText>{helperText}</FormHelperText>
                </Box>
            </Paper>
        </Box>
    );
};

export default CustomContactForm;
