import React from "react";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import DragHandleIcon from "@material-ui/icons/DragHandle";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import VisibityIcon from "@material-ui/icons/Visibility";
import VisibityOffIcon from "@material-ui/icons/VisibilityOff";

import Uploader from "../../Uploader";
import HeroImage from "../../HeroImage";

// generic toggle
import Boolean from "../../Boolean";

import styles from "../widgets.module.less";

const widget = "becomePartner";
const field = "display";
const name = `${widget}.${field}`;
const label = "Display Become An Affiliate Partner?";
const id = `entry-module-${name}`;

const BecomePartner = ({
    widget,
    onChange,
    onFileChange,
    hasErrors,
    isAdmin,
    ...rest
}) => {
    const { display, backgroundImage, backgroundImageCustom } = widget;
    const titleColor = !!hasErrors ? "error" : "inherit";

    return (
        <Accordion
            TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
            className={styles.Widget}>
            <AccordionSummary
                classes={{ content: styles.TitleBar }}
                expandIcon={<ExpandMoreIcon />}>
                <DragHandleIcon data-movable-handle />
                <Typography component="h4" variant="h6" color={titleColor}>
                    Become An Affiliate Partner
                </Typography>
                {display ? (
                    <VisibityIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                ) : (
                    <VisibityOffIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                    <FormControlLabel
                        label={label}
                        control={
                            <Switch
                                color="primary"
                                id={id}
                                name={field}
                                checked={display}
                                onChange={onChange}
                                inputProps={{
                                    "data-label": label,
                                }}
                            />
                        }
                    />
                    <Paper>
                        <Box padding={2} margin={2}>
                            <Typography component="h3" variant="h6">
                                Background Image
                            </Typography>
                            <br />
                            <HeroImage
                                isAdmin={isAdmin}
                                name="backgroundImage"
                                value={backgroundImage}
                                collections={["Body"]}
                                onChange={onChange}
                            />
                            <Uploader
                                name="backgroundImageCustom"
                                label="Custom Image"
                                value={backgroundImageCustom}
                                config={{
                                    imagesOnly: true,
                                    inputAcceptTypes: ".jpg, .jpeg, .png",
                                    crop: "16:9",
                                    imageShrink: "2881x2017",
                                }}
                                handleFile={onFileChange}
                                onChange={onChange}
                                logo
                            />
                        </Box>
                    </Paper>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default BecomePartner;
