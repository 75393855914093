import React from "react";
import { get } from "lodash";
import TextField from "@material-ui/core/TextField";

const hint = "";

const Number = ({
    name,
    label,
    entry,
    errors,
    value,
    step = 1,
    onChange,
    ...rest
}) => {
    const errorMsg = errors[name];
    const val = value || get(entry, name);
    const helperText = errorMsg || hint;
    const id = `entry-${name}`;

    // proxy change handler to ensure numeric data
    const handleNumberChange = e => {
        const { name, value, type } = e.target;
        //console.log(name, typeof value);
        let change = {
            target: {
                name,
                type,
                value: parseFloat(value),
            },
        };
        onChange(change);
    };

    return (
        <TextField
            autoComplete="off"
            id={id}
            name={name}
            type="number"
            step={step}
            label={label}
            error={!!errorMsg}
            helperText={helperText}
            value={val}
            fullWidth={true}
            margin="normal"
            inputProps={{
                "data-lpignore": "true",
                "data-label": label,
            }}
            onChange={handleNumberChange}
            {...rest}
        />
    );
};

export default Number;
