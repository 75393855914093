import React, { useState, useEffect } from "react";
import { orderBy, cloneDeep } from "lodash";

import createSlug from "../../../../../lib/createSlug";

import BranchDetail from "../../../../BranchDetail";

/**
 *
 * Flatten an entry for use in LoanOfficerDetail
 * Fetch branche and team from refs
 * Fetch lo blogs and corporate blogs
 */
const BranchPreview = ({
    fetchCollection,
    entry,
    loanOfficers = [],
    teams = [],
}) => {
    const [mappedLoanOfficers, setMappedLoanOfficers] = useState([]);
    const [mappedTeams, setMappedTeams] = useState([]);
    const [news, setNews] = useState([]);

    useEffect(() => {
        (async () => {
            let promisedDocuments = loanOfficers.map(async lo => {
                let doc = !!lo && lo.get ? await lo.get() : null;
                let data = doc && doc.exists ? doc.data() : null;
                return data;
            });
            let docs = await Promise.all(promisedDocuments);
            setMappedLoanOfficers(docs.filter(doc => !!doc));
        })();
    }, [loanOfficers]);

    useEffect(() => {
        (async () => {
            let promisedDocs = teams.map(async t => {
                let doc = !!t && t.get ? await t.get() : null;
                let data = doc && doc.exists ? doc.data() : null;
                return data;
            });
            let docs = await Promise.all(promisedDocs);
            setMappedTeams(docs.filter(doc => !!doc));
        })();
    }, [teams]);

    useEffect(() => {
        (async () => {
            let corporateBlogs = await fetchCollection(
                "corporateBlog",
                "published",
            );
            let corpSorted = orderBy(corporateBlogs, ["date"], ["desc"]);
            let itemsCorp = corpSorted.slice(0, 3).map(item => {
                item.date = item.date.toDate();
                return item;
            });
            setNews(itemsCorp);
        })();
    }, []);

    useEffect(() => {
        // add target = _blank to preview URLs
        Array.from(document.querySelectorAll("#preview a")).forEach(a =>
            a.setAttribute("target", "_blank"),
        );
    });

    if (!entry) return null;

    let { contact, urlSlug, title } = entry;

    return (
        <div style={{ pointerEvents: "auto" }}>
            <BranchDetail
                {...{ ...cloneDeep(entry), firestoreId: entry.id }}
                {...contact}
                slug={createSlug(urlSlug, title)}
                loanOfficers={mappedLoanOfficers}
                teams={mappedTeams}
                corporateBlogs={news}
                blogs={[]}
                isPreview={true}
            />
        </div>
    );
};

export default BranchPreview;
