import React from "react";
import TextField from "@material-ui/core/TextField";

const name = "title";
const hint = "";
const label = "Title";

const Title = ({ isAdmin, value, errors, ...rest }) => {
    const errorMsg = errors[name];
    const helperText = errorMsg || hint;
    const id = `entry-${name}`;

    return (
        <TextField
            required
            autoComplete="off"
            id={id}
            name={name}
            label={label}
            disabled={!isAdmin}
            error={!!errorMsg}
            helperText={helperText}
            value={value}
            hidden={!isAdmin}
            fullWidth={true}
            margin="normal"
            inputProps={{
                "data-lpignore": "true",
                "data-label": label,
            }}
            {...rest}
        />
    );
};

export default Title;
