import React from "react";

import { navigate } from "gatsby";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";

import { entryUrls } from "../../lib/urls";
import { galleryPhoto } from "../../lib/defaults";

const CorporateBlogListItem = ({ blog }) => {
    const { id, title, date } = blog;
    const displayDate =
        !!date && date.toDate
            ? date.toDate().toLocaleString("en-US", { dateStyle: "short" })
            : null;
    const to = `${entryUrls.corporateBlog}${id}`;
    const handleClick = to => e => {
        e.preventDefault();
        navigate(to);
    };
    return (
        <ListItem button onClick={handleClick(to)}>
            <ListItemAvatar>
                <Avatar src={galleryPhoto} title="Bay Equity News" />
            </ListItemAvatar>
            <ListItemText>
                {title} {!!displayDate && ` - ${displayDate}`}
            </ListItemText>
        </ListItem>
    );
};

export default CorporateBlogListItem;
