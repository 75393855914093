import React from "react";

import { navigate } from "gatsby";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import Avatar from "@material-ui/core/Avatar";

import VisibityIcon from "@material-ui/icons/Visibility";
import VisibityOffIcon from "@material-ui/icons/VisibilityOff";
import PlaceIcon from "@material-ui/icons/Place";
import GroupIcon from "@material-ui/icons/Group";

import { entryUrls } from "../../lib/urls";

const SplashPageListItem = ({ splashPage }) => {
    const { id, title, subTitle, hidden } = splashPage;
    const to = `${entryUrls.splashPage}${id}`;
    const handleClick = to => e => {
        e.preventDefault();
        navigate(to);
    };

    return (
        <ListItem button onClick={handleClick(to)}>
            <ListItemAvatar>
                <Avatar>{renderAvatar(splashPage.templateKey)}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={title} secondary={subTitle} />
            <ListItemSecondaryAction>
                <ListItemIcon>
                    {!!hidden ? <VisibityOffIcon /> : <VisibityIcon />}
                </ListItemIcon>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

const renderAvatar = pageType => {
    switch (pageType) {
        case "loan-officer":
        case "non-originator":
            return <Avatar />;
        case "team":
            return <GroupIcon />;
        default:
            return <PlaceIcon />;
    }
};

export default SplashPageListItem;
