import React from "react";

import { navigate } from "gatsby";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

import Avatar from "@material-ui/core/Avatar";

import { entryUrls } from "../../lib/urls";

const NonOriginatorListItem = ({ nonOriginator }) => {
    const { id, title, gallery: { photo } = { photo: "" } } = nonOriginator;
    const to = `${entryUrls.nonOriginator}${id}`;
    const handleClick = to => e => {
        e.preventDefault();
        navigate(to);
    };

    return (
        <ListItem button onClick={handleClick(to)}>
            <ListItemAvatar>
                <Avatar alt={title} src={photo} />
            </ListItemAvatar>
            <ListItemText>{title}</ListItemText>
        </ListItem>
    );
};

export default NonOriginatorListItem;
