import React from "react";
import { noop } from "lodash";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import DragHandleIcon from "@material-ui/icons/DragHandle";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import VisibityIcon from "@material-ui/icons/Visibility";
import VisibityOffIcon from "@material-ui/icons/VisibilityOff";

import Uploader from "../../Uploader";
import Text from "../../Text";
import Number from "../../Number";
import Editor from "../../Editor";
import HeroImage from "../../HeroImage";

// contact form fields
import CustomContactForm from "../../CustomContactForm";
import predefinedInputs from "../../../../../AboutCareModule/lib/predefinedInputs.json";

// generic toggle
import Boolean from "../../Boolean";

import styles from "../widgets.module.less";

const widget = "aboutCare";
const field = "display"; // used for saving widget data
const name = `${widget}.${field}`; // used for validation and error messages
const label = "Display About Care?";
const id = `entry-module-${name}`;

const AboutCare = ({
    widget,
    onChange = noop,
    setField = noop,
    onFileChange = noop,
    onEditorChange = noop,
    errors,
    isAdmin,
    ...rest
}) => {
    const {
        display,
        aboutCareHtml,
        backgroundImage,
        backgroundImageCustom,
        darkTitleText,
        contactFormFields,
        contactFormTitle,
        contactFormSubtitle,
        bammUrl,
        lenderCreditPercentage,
        realtorCommissionPercentage,
        realtorCreditPercentage,
        noAppraisalFeeCredit,
        allowDiscountedRealtorFee,
    } = widget;

    const hasErrorsInFields = (errors, fields = []) =>
        fields.some(field => !!errors[field]);

    let creditInfoErrorColor = hasErrorsInFields(errors, [
        "lenderCreditPercentage",
        "realtorCommissionPercentage",
        "realtorCreditPercentage",
    ])
        ? "error"
        : "inherit";

    const handleReorderList = list => {
        setField("contactFormFields", list);
    };

    return (
        <Accordion
            TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
            className={styles.Widget}>
            <AccordionSummary
                classes={{ content: styles.TitleBar }}
                expandIcon={<ExpandMoreIcon />}>
                <DragHandleIcon data-movable-handle />
                <Typography component="h4" variant="h6">
                    About Care
                </Typography>
                {display ? (
                    <VisibityIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                ) : (
                    <VisibityOffIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                    <FormControlLabel
                        label={label}
                        control={
                            <Switch
                                color="primary"
                                id={id}
                                name={field}
                                checked={display}
                                onChange={onChange}
                                inputProps={{
                                    "data-label": label,
                                }}
                            />
                        }
                        style={{ paddingBottom: "20px" }}
                    />
                    <Paper>
                        <Box padding={2} margin={2}>
                            <Typography component="h3" variant="h6">
                                Background Image
                            </Typography>
                            <br />
                            <HeroImage
                                isAdmin={isAdmin}
                                name="backgroundImage"
                                value={backgroundImage}
                                collections={["Body"]}
                                onChange={onChange}
                            />
                            <Uploader
                                name="backgroundImageCustom"
                                label="Custom Image"
                                value={backgroundImageCustom}
                                config={{
                                    imagesOnly: true,
                                    inputAcceptTypes: ".jpg, .jpeg, .png",
                                    crop: "16:9",
                                    imageShrink: "2881x2017",
                                }}
                                handleFile={onFileChange}
                                onChange={onChange}
                                logo
                            />
                        </Box>
                    </Paper>
                    <div className={styles.contactUsEditor}>
                        <Editor
                            name="aboutCareHtml"
                            entry={{ body: aboutCareHtml }}
                            onChange={body => {
                                onEditorChange("aboutCareHtml", body);
                            }}
                            toolbarButtons="bold|italic|link|bullet|numbered"
                        />
                    </div>
                    <Boolean
                        name="darkTitleText"
                        value={darkTitleText}
                        label="Use Dark Text?"
                        hint="Display the Title and text in black for better contrast"
                        onChange={onChange}
                    />
                    <CustomContactForm
                        field="contactFormFields"
                        contactFormTitle={contactFormTitle}
                        contactFormSubtitle={contactFormSubtitle}
                        contactFormFields={contactFormFields}
                        showContactFormSubTitle={true}
                        predefinedInputs={predefinedInputs}
                        errors={errors}
                        isAdmin={isAdmin}
                        onChange={onChange}
                        handleReorderList={handleReorderList}
                        setField={setField}
                        nonRemovableFields={[
                            "loanPurpose",
                            "purchasePrice",
                            "downPayment",
                            "loanBalance",
                            "cashoutAmount",
                        ]}
                    />
                    <Box>
                        <Paper>
                            <Box padding={2} margin={2}>
                                <Typography component="h3" variant="h6">
                                    Lead/Email Settings
                                </Typography>
                                <Typography component="p" variant="body1">
                                    Information used when form is submitted
                                </Typography>
                                <Text
                                    className={styles.field}
                                    name="bammUrl"
                                    label="BAMM URL"
                                    hint="The BAMM URL to capture the lead information on form submission"
                                    disabled={!isAdmin}
                                    hidden={!isAdmin}
                                    onChange={onChange}
                                    value={bammUrl}
                                    errors={!!errors["aboutCare.bammUrl"]}
                                />
                            </Box>
                        </Paper>
                    </Box>
                    <Box>
                        <Paper>
                            <Box padding={2} margin={2}>
                                <Typography
                                    component="h3"
                                    variant="h6"
                                    color={creditInfoErrorColor}>
                                    Calculator Settings
                                </Typography>
                                <Boolean
                                    name="noAppraisalFeeCredit"
                                    label="No Appraisal Fee Credit"
                                    hint=""
                                    onChange={onChange}
                                    value={noAppraisalFeeCredit}
                                    errors={errors}
                                />
                                <Boolean
                                    name="allowDiscountedRealtorFee"
                                    label="Allow Discounted Realtor Fee"
                                    hint=""
                                    onChange={onChange}
                                    value={allowDiscountedRealtorFee}
                                    errors={errors}
                                />
                                <Number
                                    required
                                    name="lenderCreditPercentage"
                                    label="Lender Credit Percentage"
                                    hint="Used to calculate lender credit amount"
                                    onChange={onChange}
                                    value={lenderCreditPercentage}
                                    errors={errors}
                                />
                                <Number
                                    required
                                    name="realtorCommissionPercentage"
                                    label="Realtor Commission Percentage"
                                    hint="Used to calculate reatlor commission amount"
                                    onChange={onChange}
                                    value={realtorCommissionPercentage}
                                    errors={errors}
                                />
                                <Number
                                    required
                                    name="realtorCreditPercentage"
                                    label="Realtor Credit Percentage"
                                    hint="Used to calculate realtor credit amount"
                                    select
                                    onChange={onChange}
                                    value={realtorCreditPercentage}
                                    errors={errors}
                                    fullWidth
                                    margin="normal">
                                    <option key="0.1" value="0.1">
                                        0.1
                                    </option>
                                    <option key="0.25" value="0.25">
                                        0.25
                                    </option>
                                </Number>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default AboutCare;
