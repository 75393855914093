import React from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const ChangeLog = ({ value }) => {
    let logs;
    try {
        logs = JSON.parse(value);
    } catch (e) {
        logs = [];
    }

    const renderLogEntry = log => {
        let { header, events } = log;
        return (
            <div key={header}>
                <Typography component="h4" variant="subtitle1">
                    {header}
                </Typography>
                {events.map((evt, i) => {
                    return (
                        <Typography key={i} component="div" variant="body2">
                            {evt}
                        </Typography>
                    );
                })}
                <br />
            </div>
        );
    };

    return (
        <Box mt={2}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography component="h3" variant="h6">
                        Change Log
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box>{logs.map(renderLogEntry)}</Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default React.memo(ChangeLog);
