import React from "react";
import { get } from "lodash";
import TextField from "@material-ui/core/TextField";

const Text = ({
    name,
    label,
    value: inValue,
    entry = null,
    errors,
    hint = "",
    rows = 1,
    ...rest
}) => {
    const value = entry ? get(entry, name) : inValue;
    const errorMsg = errors[name];
    const helperText = errorMsg || hint;
    const id = `entry-${name}`;

    return (
        <TextField
            autoComplete="off"
            id={id}
            name={name}
            label={label}
            error={!!errorMsg}
            helperText={helperText}
            value={value}
            fullWidth={true}
            rowsMax={rows}
            multiline={rows > 1}
            margin="normal"
            inputProps={{
                "data-lpignore": "true",
                "data-label": label,
            }}
            {...rest}
        />
    );
};

export default Text;
