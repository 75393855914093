import React, { useState, useEffect } from "react";
import { List, arrayMove } from "react-movable";
import { find } from "lodash";

import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import DeleteIcon from "@material-ui/icons/Delete";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import Button from "@material-ui/core/Button";

import ReactSelectMaterialUi from "react-select-material-ui";

import { getFirestoreDb } from "../../../../../config/cms.config";

import styles from "./styles.module.less";

const db = getFirestoreDb();

const toLabel = data => {
    if (!data) return null;
    let { title, hidden } = data;
    let hiddenLabel = hidden ? " (hidden)" : "";
    return `${title}${hiddenLabel}`;
};

const selectProps = {
    isCreatable: false,
    isClearable: false,
    isMultiple: false,
};

const Teams = ({ name = "teams", values, handleListChange }) => {
    const [teams, setTeam] = useState([]);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);
    const [idx, setIdx] = useState(null);

    // load all published teams
    useEffect(() => {
        if (teams.length === 0) {
            db.collection(`entries/team/published`)
                .get()
                .then(querySnapshot => {
                    let teams = querySnapshot.docs.map(doc => {
                        let value = doc.ref;
                        let label = toLabel(doc.data());
                        return {
                            label,
                            value,
                        };
                    });
                    setTeam(teams);
                });
        }
    }, []);

    const getHandler = () => {
        return value => {
            setSelected(value);
            values[idx] = value;
            handleListChange(name, values);
        };
    };

    const getEdit = (index, selected) => {
        return () => {
            setOpen(true);
            setIdx(index);
            setSelected(selected);
        };
    };

    const getDeleter = index => {
        return () => {
            values.splice(index, 1);
            handleListChange(name, values);
        };
    };

    const add = () => {
        setOpen(true);
        setIdx(values.length);
        values.push(null);
    };

    const getLabel = selected => {
        if (!selected) return null;
        let l = find(teams, tm => tm.value.isEqual(selected));
        if (!!l) {
            return l.label;
        }
        return null;
    };

    const renderDialog = (open = false) => {
        const handleChange = getHandler(idx);
        const close = () => setOpen(false);
        return (
            <Dialog
                open={open}
                onClose={close}
                scroll="body"
                PaperProps={{ style: { overflowY: "unset" } }}>
                <DialogTitle>Select a Team</DialogTitle>
                <DialogContent classes={{ root: styles.dialogContent }}>
                    <ReactSelectMaterialUi
                        name="teamselect"
                        placeholder="Select a team"
                        value={selected}
                        onChange={handleChange}
                        options={teams}
                        style={{ position: "static" }}
                        fullWidth={true}
                        SelectProps={selectProps}
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={close}>OK</Button>
                </DialogActions>
            </Dialog>
        );
    };

    return (
        <Paper elevation={1}>
            <Box padding={2} margin={2}>
                <Typography component="h3" variant="h6">
                    Teams
                </Typography>
                <List
                    values={values}
                    onChange={({ oldIndex, newIndex }) => {
                        handleListChange(
                            name,
                            arrayMove(values, oldIndex, newIndex),
                        );
                    }}
                    renderList={({ children, props, isDragged }) => {
                        props.onKeyDown = e => e;
                        return (
                            <div
                                {...props}
                                style={{
                                    cursor: isDragged ? "grabbing" : "inherit",
                                }}>
                                {children}
                            </div>
                        );
                    }}
                    renderItem={({
                        value,
                        props,
                        isDragged,
                        isSelected,
                        index,
                    }) => {
                        const handleClickEdit = getEdit(index, value);
                        const handleClickDelete = getDeleter(index);
                        const cls = isDragged
                            ? `${styles.SelectRow} ${styles.isDragged}`
                            : styles.SelectRow;
                        return (
                            <div {...props}>
                                <Box className={cls} marginTop={1}>
                                    <DragHandleIcon data-movable-handle />
                                    <IconButton onClick={handleClickEdit}>
                                        <EditIcon />
                                    </IconButton>
                                    <Typography component="p" variant="body2">
                                        {getLabel(value)}
                                    </Typography>
                                    <IconButton onClick={handleClickDelete}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            </div>
                        );
                    }}></List>
                <IconButton onClick={add} margin={0}>
                    <AddIcon />
                </IconButton>
                {renderDialog(open, selected, idx)}
            </Box>
        </Paper>
    );
};

export default Teams;
