import React, { useState, useEffect } from "react";
import { cloneDeep } from "lodash";
import createSlug from "../../../../../lib/createSlug";

import { noop, orderBy } from "lodash";

import CommunityCareDetail from "../../../../CommunityCareDetail";
import { getDocumentByRef } from "../../../../../lib/helpers";

import styles from "./styles.module.less";

const CommunityCarePreview = ({
    fetchCollection = noop,
    entry,
    staticTextItems,
}) => {
    const [pageData, setPageData] = useState({});
    const [news, setNews] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [socialId, setSocialId] = useState("");

    useEffect(() => {
        (async () => {
            let data = await mapPageData(entry, blogs, news);
            setPageData(data);
        })();
    }, [entry.loanOfficer, entry.team]);

    useEffect(() => {
        // set social_id
        setSocialId(getTestimonialsSocialId());

        (async () => {
            // set corporate blogs
            let corporateBlogs = await fetchCollection(
                "corporateBlog",
                "published",
            );
            let corpSorted = orderBy(corporateBlogs, ["date"], ["desc"]);
            let items = corpSorted.slice(0, 3).map(item => {
                item.date = item.date.toDate();
                return item;
            });
            setNews(items);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            // set LO blogs
            let items = [];
            if (entry.loanOfficer && pageData.profile) {
                let loBlogs = await fetchCollection("blog", "published");
                let loSorted = orderBy(loBlogs, ["date"], ["desc"]);
                let loFiltered = loSorted.filter(
                    blog => blog.owner === pageData.profile.email,
                );
                items = loFiltered.slice(0, 3).map(item => {
                    item.date = item.date.toDate();
                    return item;
                });
            }
            setBlogs(items);
        })();
    }, [pageData.profile]);

    useEffect(() => {
        // set social_id
        setSocialId(getTestimonialsSocialId());
    }, [entry.modules.find(module => module.type === "testimonials")]);

    const getTestimonialsSocialId = () => {
        let { modules } = entry;
        const { social_id } =
            (modules &&
                modules.find(module => module.type === "testimonials")) ||
            {};
        return social_id;
    };

    if (!entry) return null;

    return (
        <div className={styles.mktBody}>
            <CommunityCareDetail
                {...{ ...cloneDeep(entry), firestoreId: entry.id }}
                {...pageData}
                html={entry.body}
                corporateBlogs={news}
                blogs={blogs}
                social_id={socialId}
                isPreview={true}
                staticTextItems={staticTextItems}
            />
        </div>
    );
};

export default CommunityCarePreview;

/**
 *
 * Flatten an entry for use in CommunityCareDetail
 * Fetch loan-officer, branch and team from refs
 */
const mapPageData = async entry => {
    let pageData = {};

    // don't need scripts for preview
    pageData.headBeginScript = [];
    pageData.headEndScript = [];
    pageData.bodyBeginScript = [];
    pageData.bodyEndScript = [];

    // loan-officer selected, build out based on this...
    if (entry.loanOfficer) {
        let lo = await getDocumentByRef(entry.loanOfficer);
        if (lo) {
            pageData.loanOfficer = lo;

            let { urlslug, title, id } = pageData.loanOfficer;
            pageData.loanOfficer.slug = getSlug(
                urlslug,
                id,
                title,
                pageData.loanOfficer.profile.name,
            );

            let loBranch = await getDocumentByRef(lo.profile.branch);
            if (!loBranch) {
                console.log(
                    `Error: loan officer "${lo.profile.name}" is missing a branch`,
                );
                return null;
            }
            pageData.loanOfficer.profile.branch = loBranch;
            await getExtraData(pageData.loanOfficer.profile.branch);
        } else {
            pageData.loanOfficer = null;
            console.log(
                `Error: loan officer "${entry.loanOfficer}" is missing. Set to null`,
            );
        }

        // lo-specific module info
        pageData.contactInfo = {
            name: lo.profile.name,
            address1: lo.profile.branch.address1,
            address2: lo.profile.branch.address2,
            address3: lo.profile.branch.address3,
            city: lo.profile.branch.city,
            state: lo.profile.branch.state,
            zip: lo.profile.branch.zip,
            phone: lo.contact.phone,
            mobilePhone: lo.contact.mobilePhone,
            fax: lo.contact.fax,
            email: lo.profile.email,
        };
        pageData.socialIcons = {
            facebook: lo.contact.facebook,
            instagram: lo.contact.instagram,
            linkedin: lo.contact.linkedin,
            twitter: lo.contact.twitter,
            youtube: lo.contact.youtube,
        };
        pageData.name = lo.profile.name;
        pageData.applyNow = lo.applyNow;
        pageData.applyNowScript = lo.applyNowScript;
        pageData.blogWord = "my";
        pageData.templateKey = "loan-officer";
        pageData.licenseIds = lo.profile.licenseIds;
        pageData.licenseLink = lo.profile.licenseLink;
        pageData.logIn = lo.login;
        pageData.jobTitle = lo.profile.jobTitle;
        pageData.team = null;
        pageData.profile = lo.profile;
        pageData.branch = lo.profile.branch;
        pageData.graphic = null;
        pageData.slug = pageData.loanOfficer.slug;
    } else if (entry.team) {
        let team = await getDocumentByRef(entry.team);
        if (team) {
            pageData.team = team;

            let { urlslug, title, id } = team;
            team.slug = getSlug(urlslug, id, title, pageData.team.profile.name);
            let teamBranch = await getDocumentByRef(team.profile.branch);
            if (!teamBranch) {
                console.log(
                    `Error: team "${team.profile.name}" is missing a branch`,
                );
                return null;
            }
            team.profile.branch = teamBranch;
            await getExtraData(pageData.team);
        } else {
            team = null;
            console.log(
                `Error: team "${entry.team.id}" is missing. Set to null`,
            );
        }

        // team-specific module info
        pageData.contactInfo = {
            name: team.profile.name,
            address1: team.profile.branch.address1,
            address2: team.profile.branch.address2,
            address3: team.profile.branch.address3,
            city: team.profile.branch.city,
            state: team.profile.branch.state,
            zip: team.profile.branch.zip,
            phone: team.contact.phone,
            mobilePhone: team.contact.mobilePhone,
            fax: team.contact.fax,
            email: team.profile.email,
        };
        pageData.socialIcons = {
            facebook: pageData.team.contact.facebook,
            instagram: pageData.team.contact.instagram,
            linkedin: pageData.team.contact.linkedin,
            twitter: pageData.team.contact.twitter,
            youtube: pageData.team.contact.youtube,
        };
        pageData.name = team.profile.name;
        pageData.applyNow = team.applyNow;
        pageData.applyNowScript = team.applyNowScript;
        pageData.blogWord = "our";
        pageData.templateKey = "team";
        pageData.licenseIds = team.profile.licenseIds;
        pageData.logIn = team.login;
        pageData.jobTitle = "";
        pageData.team = team;
        pageData.profile = team.profile;
        pageData.branch = pageData.team.profile.branch;
        pageData.graphic = team.profile.graphic;
        pageData.slug = pageData.team.slug;
    }

    return pageData;
};

async function getExtraData(collection) {
    if (Array.isArray(collection.loanOfficers)) {
        let promisesPromises = collection.loanOfficers.map(
            async loanOfficer => {
                if (loanOfficer && loanOfficer.get) {
                    let lo = await getDocumentByRef(loanOfficer);
                    return lo;
                }
                return null;
            },
        );
        let loanOfficers = await Promise.all(promisesPromises);
        collection.loanOfficers = loanOfficers;
    } else {
        collection.loanOfficers = [];
    }

    if (Array.isArray(collection.teams)) {
        let promisesPromises = collection.teams.map(async team => {
            if (team && team.get) {
                let lo = await getDocumentByRef(team);
                return lo;
            }
            return null;
        });
        let teams = await Promise.all(promisesPromises);
        collection.teams = teams;
    } else {
        collection.teams = [];
    }

    // ensure managers and staff are set as arrays
    collection.staff = Array.isArray(collection.staff) ? collection.staff : [];
    collection.managers = Array.isArray(collection.managers)
        ? collection.managers
        : [];
}

const getSlug = (...args) => {
    return createSlug(...args);
};
