import React from "react";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import DragHandleIcon from "@material-ui/icons/DragHandle";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuItem from "@material-ui/core/MenuItem";

import Uploader from "../../Uploader";
import VisibityIcon from "@material-ui/icons/Visibility";
import VisibityOffIcon from "@material-ui/icons/VisibilityOff";
import Boolean from "../../Boolean";

import MoveUpIcon from "@material-ui/icons/KeyboardArrowUp";
import MoveDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { isEmpty } from "lodash";

import styles from "../widgets.module.less";

const type = "video";
const field = "display";
const name = `${type}.${field}`;
const label = "Display Videos?";
const id = `entry-module-${name}`;

const Video = ({
    widget,
    onChange,
    onFileChange,
    setField,
    deleteField,
    errors,
    hasErrors,
    ...rest
}) => {
    const {
        display,
        videos = [],
        backgroundImageCustom = "",
        darkTitleText = true,
    } = widget;

    const titleColor = !!hasErrors ? "error" : "inherit";

    const handleChangeList = list => {
        setField("videos", list);
    };

    return (
        <Accordion
            TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
            className={styles.Widget}>
            <AccordionSummary
                classes={{ content: styles.TitleBar }}
                expandIcon={<ExpandMoreIcon />}>
                <DragHandleIcon data-movable-handle />
                <Typography component="h4" variant="h6" color={titleColor}>
                    Videos
                </Typography>
                {display ? (
                    <VisibityIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                ) : (
                    <VisibityOffIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                    <FormControlLabel
                        label={label}
                        control={
                            <Switch
                                color="primary"
                                id={id}
                                name={field}
                                checked={display}
                                onChange={onChange}
                                inputProps={{
                                    "data-label": label,
                                }}
                            />
                        }
                    />
                    <Boolean
                        name="darkTitleText"
                        entry={widget}
                        label="Use Dark Text?"
                        hint="Display the Title in black for better contrast"
                        onChange={onChange}
                    />
                    <VideosList
                        videos={videos}
                        onChangeList={handleChangeList}
                        errors={errors}
                    />
                    <Paper>
                        <Box margin={2} padding={2}>
                            <Uploader
                                id={`entry-module-${type}.backgroundImageCustom`}
                                entry={widget}
                                name={"backgroundImageCustom"}
                                label={"Background Image"}
                                hint="Upload a background image (images must be in jpg format)"
                                value={backgroundImageCustom}
                                config={{
                                    imagesOnly: true,
                                    inputAcceptTypes: ".jpg, .jpeg",
                                    crop: "16:9",
                                    imageShrink: "2881x2017",
                                }}
                                handleFile={onFileChange}
                            />
                        </Box>
                    </Paper>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

const VideosList = ({ videos, onChangeList, errors }) => {
    const getHandleChange = index => e => {
        const { name, value } = e.target;
        videos[index][name] = value;
        onChangeList(videos);
    };

    const addItem = e => {
        e.preventDefault();
        const item = {
            videoTitle: "",
            videoCaption: "",
            videoUrl: "",
        };
        videos.push(item);
        onChangeList(videos);
    };

    const getDeleter = index => {
        return e => {
            videos.splice(index, 1);
            onChangeList(videos);
        };
    };

    const getMoveUp = index => {
        return e => {
            e.preventDefault();
            const to = Math.max(index - 1, 0);
            videos.splice(to, 0, videos.splice(index, 1)[0]);
            onChangeList(videos);
        };
    };

    const getMoveDown = index => {
        return e => {
            e.preventDefault();
            const to = Math.min(index + 1, videos.length - 1);
            videos.splice(to, 0, videos.splice(index, 1)[0]);
            onChangeList(videos);
        };
    };

    return (
        <Box>
            {!isEmpty(videos) &&
                videos.map((video, i) => {
                    return (
                        <Paper key={i}>
                            <Box p={2} m={2}>
                                <VideoListItem
                                    index={i}
                                    video={video}
                                    onChange={getHandleChange(i)}
                                    onDelete={getDeleter(i)}
                                    onMoveUp={getMoveUp(i)}
                                    onMoveDown={getMoveDown(i)}
                                    errors={errors[i]}
                                />
                            </Box>
                        </Paper>
                    );
                })}
            <Box mt={2}>
                <Button onClick={addItem}>
                    <AddIcon />
                    Add Video
                </Button>
            </Box>
        </Box>
    );
};

const VideoListItem = ({
    index,
    video,
    onChange,
    onDelete,
    onMoveUp,
    onMoveDown,
    errors = [],
}) => {
    const titleValidatorName = `${type}[${index}].videoTitle`;
    const titleErrorMsg = errors[titleValidatorName];
    const titleHelperTxt = titleErrorMsg || "Give your video a title";

    const captionValidatorName = `${type}[${index}].videoCaption`;
    const captionErrorMsg = errors[captionValidatorName];
    const captionHelperTxt =
        captionErrorMsg || "Provide a description for this video";

    const urlValidatorName = `${type}[${index}].videoUrl`;
    const urlErrorMsg = errors[urlValidatorName];
    const urlHelperTxt =
        urlErrorMsg ||
        "Enter (copy and paste) the link for your YouTube, Vimeo, or Facebook video";

    const { videoTitle, videoCaption, videoUrl } = video;
    return (
        <Grid container direction="row" spacing={2}>
            <Grid item xs={1}>
                <IconButton onClick={onMoveUp}>
                    <MoveUpIcon />
                </IconButton>
                <IconButton onClick={onMoveDown}>
                    <MoveDownIcon />
                </IconButton>
            </Grid>
            <Grid item xs>
                <TextField
                    required
                    autoComplete="off"
                    id={`${id}-title`}
                    name={"videoTitle"}
                    label={"Title"}
                    error={!!titleErrorMsg}
                    helperText={titleHelperTxt}
                    value={videoTitle}
                    fullWidth={true}
                    margin="normal"
                    inputProps={{
                        "data-lpignore": "true",
                        "data-label": "Title",
                    }}
                    onChange={onChange}
                />
                <TextField
                    required
                    autoComplete="off"
                    id={`${id}-caption`}
                    multiline
                    rowsMax={2}
                    name={"videoCaption"}
                    label={"Caption"}
                    error={!!captionErrorMsg}
                    helperText={captionHelperTxt}
                    value={videoCaption}
                    fullWidth={true}
                    margin="normal"
                    inputProps={{
                        "data-lpignore": "true",
                        "data-label": "Caption",
                    }}
                    onChange={onChange}
                />
                <TextField
                    required
                    autoComplete="off"
                    id={`${id}-url`}
                    name={"videoUrl"}
                    label={"Video URL"}
                    error={!!urlErrorMsg}
                    helperText={urlHelperTxt}
                    value={videoUrl}
                    fullWidth={true}
                    margin="normal"
                    inputProps={{
                        "data-lpignore": "true",
                        "data-label": "Video URL",
                    }}
                    onChange={onChange}
                />
            </Grid>
            <Grid
                item
                container
                className={styles.VideoDelete}
                xs="1"
                justify="flex-end"
                alignItems="flex-start">
                <IconButton onClick={onDelete}>
                    <DeleteIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default Video;
