import React, { Component } from "react";
import { HTMLContent } from "../Content";
import * as styles from "./styles.module.less";

class ToggleComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle: true,
        };
    }
    toggleClass = () => {
        let toggle = this.state.toggle;
        toggle = !toggle;
        this.setState({ toggle });
    };

    render() {
        const { description } = this.props;
        return (
            <>
                <div
                    className={
                        this.state.toggle
                            ? `${styles.hidden}`
                            : `${styles.block}`
                    }>
                    {description}
                </div>
                <span
                    className={
                        this.state.toggle
                            ? `${styles.arrow}`
                            : `${styles.arrow__up}`
                    }
                    onClick={() => this.toggleClass()}
                />
            </>
        );
    }
}

const LeadershipTeam = ({ team }) => {
    return (
        <div className={`${styles.inner} ${styles.BeTeam}`}>
            <div>
                {team.map((s, i) => {
                    let src = s.photoUrl;
                    let alt = `${s.name} - ${s.title} | Bay Equity Home Loans`;
                    return (
                        <div key={i}>
                            <div className={styles.ourTeam}>
                                <div className={styles.photo}>
                                    <img src={src} alt={alt} />
                                </div>
                                <div className={styles.description}>
                                    <h2>{s.name}</h2>
                                    <div className={styles.info}>
                                        <div>{s.title}</div>
                                        {!!s.location && (
                                            <div>{s.location}</div>
                                        )}
                                        {!!s.license && <div>{s.license}</div>}
                                    </div>
                                    <ToggleComponent
                                        description={
                                            <HTMLContent content={s.bio} />
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default LeadershipTeam;
