import React from "react";
import TextField from "@material-ui/core/TextField";

const name = "vanityUrl";
const label = "Vanity URL";
const hint =
    "The external URL that represents this page (used to create redirect and link to page on lead email)";
const inputProps = {
    "data-lpignore": "true",
    "data-label": label,
};

const VanityUrl = ({ isAdmin, value, error: errorMsg, ...rest }) => {
    const helperText = errorMsg || hint;
    const id = `entry-${name}`;

    return (
        <TextField
            required
            autoComplete="off"
            id={id}
            name={name}
            label={label}
            disabled={!isAdmin}
            error={!!errorMsg}
            helperText={helperText}
            value={value}
            hidden={!isAdmin}
            fullWidth={true}
            margin="normal"
            inputProps={inputProps}
            {...rest}
        />
    );
};

export default React.memo(VanityUrl);
