import React, { useState, useEffect } from "react";
import SalesLeadership from "../../../pages/sales-leadership";

/**
 *
 * Flatten an entry for use in SplashDetail
 * Fetch loan officer, team, branch or non-Originator as needed from refs
 * Fetch lo blogs and corporate blogs
 */
const SalesLeadershipPreview = data => {
    return (
        <div id="preview" style={{ pointerEvents: "auto" }}>
            <SalesLeadership {...data} />
        </div>
    );
};

export default SalesLeadershipPreview;
