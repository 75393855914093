import React from "react";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

const name = "terminated";
const hint = "Mark this profile as terminated";
const label = "Terminated";

const Terminated = ({ isAdmin, entry, errors, ...rest }) => {
    const errorMsg = errors[name];
    const value = entry[name];
    let helperText = errorMsg || hint;
    const id = `entry-${name}`;
    return (
        <FormControl hidden={!isAdmin} fullWidth={true} margin="normal">
            <FormControlLabel
                label={label}
                labelPlacement="end"
                control={
                    <Switch
                        color="primary"
                        id={id}
                        name={name}
                        checked={value}
                        inputProps={{
                            "data-label": label,
                        }}
                        {...rest}
                    />
                }
            />
            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    );
};

export default Terminated;
