import React from "react";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import DragHandleIcon from "@material-ui/icons/DragHandle";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import Uploader from "../../Uploader";
import VisibityIcon from "@material-ui/icons/Visibility";
import VisibityOffIcon from "@material-ui/icons/VisibilityOff";
import Boolean from "../../Boolean";

import styles from "../widgets.module.less";

const type = "calendar";
const field = "display";
const name = `${type}.${field}`;
const label = "Display Calendar?";
const id = `entry-module-${name}`;

const Calendly = ({
    widget,
    onChange,
    errors,
    hasErrors,
    index,
    setField,
    showUpload = false,
    ...rest
}) => {
    const { display, calendar, backgroundImage } = widget;
    const titleColor = !!hasErrors ? "error" : "inherit";
    const usernameValidatorName = `${type}.calendar`;
    const usernameErrorMsg = errors[usernameValidatorName];
    const usernameHelperTxt =
        usernameErrorMsg ||
        "Enter your Calendly username to display your schedule on your profile (you must sign up a Calendly account to use this widget)";

    const handleFile = (name, value, label) => {
        setField(name, value ?? null);
    };

    return (
        <Accordion
            TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
            className={styles.Widget}>
            <AccordionSummary
                classes={{ content: styles.TitleBar }}
                expandIcon={<ExpandMoreIcon />}>
                <DragHandleIcon data-movable-handle />
                <Typography component="h4" variant="h6" color={titleColor}>
                    Calendly Calendar
                </Typography>
                {display ? (
                    <VisibityIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                ) : (
                    <VisibityOffIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                    <FormControlLabel
                        label={label}
                        control={
                            <Switch
                                color="primary"
                                id={id}
                                name={field}
                                checked={display}
                                onChange={onChange}
                                inputProps={{
                                    "data-label": label,
                                }}
                            />
                        }
                    />
                    <TextField
                        required
                        autoComplete="off"
                        id={id}
                        name={"calendar"}
                        label={"Calendly Username"}
                        error={!!usernameErrorMsg}
                        helperText={usernameHelperTxt}
                        value={calendar}
                        fullWidth={true}
                        margin="normal"
                        inputProps={{
                            "data-lpignore": "true",
                            "data-label": "Calendly Username",
                        }}
                        onChange={onChange}
                    />
                    {showUpload && (
                        <>
                            <Uploader
                                id={`entry-module-${type}.backgroundImage`}
                                entry={widget}
                                name={"backgroundImage"}
                                label={"Background Image"}
                                hint="Upload a background image (images must be in jpg format)"
                                value={backgroundImage}
                                config={{
                                    imagesOnly: true,
                                    inputAcceptTypes: ".jpg, .jpeg",
                                    crop: "16:9",
                                    imageShrink: "2881x2017",
                                }}
                                handleFile={handleFile}
                            />
                            <Boolean
                                name="darkTitleText"
                                entry={widget}
                                label="Use Dark Text?"
                                hint="Display the Title in black for better contrast"
                                onChange={onChange}
                            />
                        </>
                    )}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default Calendly;
