import React, { useState, useEffect } from "react";
import Frame from "react-frame-component";
import SplitPane from "react-split-pane";
import { noop, isEmpty } from "lodash";
import PropTypes from "prop-types";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

// intro
import Title from "../Title";
import UrlSlug from "../UrlSlug";
import HidePage from "../HidePage";
import Disclaimer from "../Disclaimer";

// Profile
import Uploader from "../Uploader";
import LoanOfficerSelect from "../LoanOfficerSelect";
import BranchSelect from "../BranchSelect";
import TeamSelect from "../TeamSelect";
import NonOriginatorSelect from "../NonOriginatorSelect";
import PhotoAlignment from "../PhotoAlignment";

import HeroImage from "../HeroImage";
import VanityUrl from "./components/VanityUrl";

// widgets!
import Widgets from "../Widgets";

// generic toggle
import Boolean from "../Boolean";

// change log
import ChangeLog from "../ChangeLog";

// scripts
import CustomScript from "../CustomScript";

// preview view
import SplashPagePreview from "../SplashPagePreview";

import styles from "./styles.module.less";

/**
 * Collect style elements from the current page for injection into preview iframe
 */
const getStyles = () => {
    let head = "";
    const sheets = Array.from(
        document.querySelectorAll("link[rel=stylesheet]"),
    );
    const styles = Array.from(document.querySelectorAll("head style"));

    sheets.forEach(link => {
        head += link.outerHTML;
    });

    styles.forEach(style => {
        head += style.outerHTML;
    });

    return head;
};

const stylesExt = theme => ({
    details: {
        flexDirection: "column",
    },
    button: {
        margin: theme.spacing(1),
    },
});

const SplashPage = ({
    dirty,
    loaded = false,
    errors,
    missing,
    isAdmin,
    isNew,
    email,
    classes,
    handleChange = noop,
    handleListChange = noop,
    handleFile = noop,
    handleRef = noop,
    fetchCollection = noop,
    // entry props below...
    id,
    title,
    vanityUrl,
    urlslug,
    hidden,
    disclaimer,
    dbaLogoBlack,
    headerImage,
    headerImageCustom,
    headerImageAlignment,
    headerTitle,
    headerSubTitle,
    headerDarkText,
    bammUrl,
    leadEmailCcList,
    confirmEmailText,
    confirmEmailFrom,
    displayApplyNow,
    loanOfficer,
    branch,
    team,
    nonOriginator,
    modules,
    headBeginScript,
    headEndScript,
    bodyBeginScript,
    bodyEndScript,
    changelog,
}) => {
    const entry = {
        title,
        vanityUrl,
        email,
        urlslug,
        hidden,
        disclaimer,
        dbaLogoBlack,
        headerImage,
        headerImageCustom,
        headerImageAlignment,
        headerTitle,
        headerSubTitle,
        headerDarkText,
        bammUrl,
        leadEmailCcList,
        confirmEmailFrom,
        confirmEmailText,
        displayApplyNow,
        loanOfficer,
        branch,
        team,
        nonOriginator,
        modules,
        headBeginScript,
        headEndScript,
        bodyBeginScript,
        bodyEndScript,
        changelog,
    };
    // container for preview frame styles
    const [previewStyles, setPreviewStyles] = useState(null);
    // enables / disables pointer events on editor and preview during resizing
    const [blockInput, setBlockInput] = useState(false);

    useEffect(() => {
        if (!previewStyles) {
            setPreviewStyles(getStyles());
        }
    }, [previewStyles]);

    const onDragStarted = React.useCallback(() => {
        setBlockInput(true);
    }, [blockInput]);

    const onDragFinished = React.useCallback(() => {
        setBlockInput(false);
    }, [blockInput]);

    const hasErrorsInFields = (errors, fields = []) =>
        fields.some(field => !!errors[field]);

    let collectionErrorColor = React.useCallback(
        (dirty && !collectionHasSelection()) ||
            hasErrorsInFields(errors, [
                "loanOfficer",
                "team",
                "branch",
                "nonOriginator",
            ])
            ? "error"
            : "inherit",
        [dirty, errors],
    );

    let headerErrorColor = React.useCallback(
        hasErrorsInFields(errors, ["headerTitle"]) ? "error" : "inherit",
        [errors],
    );

    let loClassName = !!blockInput
        ? `${styles.LoanOfficer} ${styles.blockEntry}`
        : styles.LoanOfficer;
    let frmClassName = !!blockInput
        ? `${styles.previewFrame} ${styles.blockEntry}`
        : styles.previewFrame;

    function collectionHasSelection() {
        return (
            entry.loanOfficer !== null ||
            entry.team !== null ||
            entry.branch !== null ||
            entry.nonOriginator !== null
        );
    }

    // don't bother if we don't have an entry to display
    if (!loaded) return null;
    if (isEmpty(entry)) return null;

    return (
        <SplitPane
            split="vertical"
            minSize={320}
            defaultSize="40%"
            style={{
                height: "calc(100% - 64px)",
            }}
            onDragStarted={onDragStarted}
            onDragFinished={onDragFinished}>
            <div className={loClassName}>
                <Title
                    hint="Should be Branch, Team or Loan Officer's name (or combination) used as URL slug (e.g. “John Smith” becomes “john-smith” slug)"
                    isAdmin={isAdmin}
                    onChange={handleChange}
                    value={entry.title}
                    errors={errors}
                />
                <VanityUrl
                    isAdmin={isAdmin}
                    onChange={handleChange}
                    value={entry.vanityUrl}
                    error={errors["vanityUrl"]}
                />
                <UrlSlug
                    isAdmin={isAdmin}
                    onChange={handleChange}
                    value={entry.urlslug}
                    errors={errors}
                />
                <HidePage
                    isAdmin={isAdmin}
                    onChange={handleChange}
                    value={entry.hidden}
                    errors={errors}
                />
                <Paper elevation={1}>
                    <Box padding={2} margin={2}>
                        <Disclaimer
                            isAdmin={isAdmin}
                            onChange={handleChange}
                            value={entry.disclaimer}
                            errors={errors}
                        />
                    </Box>
                </Paper>
                <Accordion
                    TransitionProps={{
                        mountOnEnter: true,
                        unmountOnExit: true,
                    }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography
                            component="h3"
                            variant="h6"
                            color={collectionErrorColor}>
                            Loan Officer / Team / Branch / Non-Originator
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.details}>
                        <LoanOfficerSelect
                            isAdmin={isAdmin}
                            onRefSelect={handleRef}
                            value={entry.loanOfficer}
                            errors={errors}
                            depRefs={[
                                entry.team,
                                entry.branch,
                                entry.nonOriginator,
                            ]}
                            setTemplateKey
                        />
                        <br />
                        or
                        <br />
                        <TeamSelect
                            isAdmin={isAdmin}
                            onRefSelect={handleRef}
                            value={entry.team}
                            errors={errors}
                            depRefs={[
                                entry.loanOfficer,
                                entry.branch,
                                entry.nonOriginator,
                            ]}
                            setTemplateKey
                        />
                        <br />
                        or
                        <br />
                        <BranchSelect
                            isAdmin={isAdmin}
                            onRefSelect={handleRef}
                            value={entry.branch}
                            errors={errors}
                            name="branch"
                            depRefs={[
                                entry.loanOfficer,
                                entry.team,
                                entry.nonOriginator,
                            ]}
                            setTemplateKey
                            isClearable
                        />
                        <br />
                        or
                        <br />
                        <NonOriginatorSelect
                            isAdmin={isAdmin}
                            onRefSelect={handleRef}
                            value={entry.nonOriginator}
                            errors={errors}
                            depRefs={[
                                entry.loanOfficer,
                                entry.team,
                                entry.branch,
                            ]}
                            isClearable
                            setTemplateKey
                        />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    TransitionProps={{
                        mountOnEnter: true,
                        unmountOnExit: true,
                    }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography
                            component="h3"
                            variant="h6"
                            color={headerErrorColor}>
                            Header / Menu Settings
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box padding={1} margin={1}>
                            <TextField
                                required
                                name="headerTitle"
                                label="Title"
                                onChange={handleChange}
                                value={entry.headerTitle}
                                rowsMax={3}
                                multiline={true}
                                margin="normal"
                                fullWidth={true}
                                error={!!errors["headerTitle"]}
                                inputProps={{
                                    "data-lpignore": "true",
                                    "data-label": "Header Title",
                                }}
                            />
                            <TextField
                                name="headerSubTitle"
                                label="Subtitle"
                                value={entry.headerSubTitle}
                                onChange={handleChange}
                                fullWidth={true}
                                errors={errors}
                                inputProps={{
                                    "data-lpignore": "true",
                                    "data-label": "Header Subtitle",
                                }}
                            />
                            <Boolean
                                name="headerDarkText"
                                value={entry.headerDarkText}
                                label="Use Dark Text?"
                                hint="Display the Title and Subtitle text in black for better contrast"
                                onChange={handleChange}
                            />
                            <Boolean
                                name="displayApplyNow"
                                value={entry.displayApplyNow}
                                label="Display 'Apply Now / Log In' Button?"
                                hint="Display the 'Apply Now / Log In' button on menu"
                                onChange={handleChange}
                            />
                            <br />
                            <Paper>
                                <Box padding={2} margin={2}>
                                    <Typography component="h3" variant="h6">
                                        Background Image
                                    </Typography>
                                    <br />
                                    <HeroImage
                                        isAdmin={isAdmin}
                                        name="headerImage"
                                        value={entry.headerImage}
                                        collections={["Header"]}
                                        onChange={handleChange}
                                    />
                                    <Uploader
                                        name="headerImageCustom"
                                        label="Custom Image"
                                        value={entry.headerImageCustom}
                                        config={{
                                            imagesOnly: true,
                                            inputAcceptTypes:
                                                ".jpg, .jpeg, .png",
                                            crop: "16:9",
                                            imageShrink: "2881x2017",
                                        }}
                                        handleFile={handleFile}
                                        onChange={handleChange}
                                        logo
                                    />
                                    <Box padding={1}>
                                        <PhotoAlignment
                                            name="headerImageAlignment"
                                            label="Image Alignment"
                                            hint="Set the alignment of the Header Background Image. Image is centered by default"
                                            value={entry.headerImageAlignment}
                                            onChange={handleChange}
                                            defaultValue="center"
                                            errors={errors}
                                        />
                                    </Box>
                                </Box>
                            </Paper>
                        </Box>
                    </AccordionDetails>
                </Accordion>

                <Box mt={2}>
                    <Widgets
                        value={entry.modules}
                        errors={errors}
                        missing={missing}
                        handleListChange={handleListChange}
                        showUpload={true}
                        isAdmin={isAdmin}
                        pageType="splash"
                    />
                </Box>

                <ChangeLog
                    isAdmin={isAdmin}
                    onChange={handleChange}
                    value={entry.changelog}
                />
                <CustomScript
                    name="headBeginScript"
                    label="HEAD Beginning Script"
                    isAdmin={isAdmin}
                    onChange={handleChange}
                    value={entry.headBeginScript}
                    errors={errors}
                />
                <CustomScript
                    name="headEndScript"
                    label="HEAD Ending Script"
                    isAdmin={isAdmin}
                    onChange={handleChange}
                    value={entry.headEndScript}
                    errors={errors}
                />
                <CustomScript
                    name="bodyBeginScript"
                    label="BODY Beginning Script"
                    isAdmin={isAdmin}
                    onChange={handleChange}
                    value={entry.bodyBeginScript}
                    errors={errors}
                />
                <CustomScript
                    name="bodyEndScript"
                    label="BODY Ending Script"
                    isAdmin={isAdmin}
                    onChange={handleChange}
                    value={entry.bodyEndScript}
                    errors={errors}
                />
            </div>
            <Frame
                frameBorder="none"
                className={frmClassName}
                head={
                    <>
                        <style>
                            {
                                "html, body {background-color: #F6F6F6; margin: 0; padding: 0;}"
                            }
                        </style>
                        <base target="_blank" />
                    </>
                }
                // #6973: Had to add Uploadcare's AD script directly in order to get it to process images in the iFrame.
                initialContent={`<!DOCTYPE html><html><head>${previewStyles}</head><body><div class="frame-root">
                    <script>
                        (function(src, cb) {
                            var s = document.createElement("script");
                            s.setAttribute("src", src);
                            s.onload = cb;
                            (document.head || document.body).appendChild(s);
                        })("https://ucarecdn.com/libs/blinkloader/3.x/blinkloader.min.js", function() {
                            window.Blinkloader.optimize({
                                pubkey: "6ffbab390389e8747e2c",
                                fadeIn: true,
                                lazyload: true,
                                smartCompression: true,
                                responsive: true,
                                retina: false,
                                webp: true,
                            });
                        });
                    </script>
                    <script src="https://embed.signalintent.com/js/embedded.js?org-guid=${process.env.GATSBY_SIGNAL_INTENT_ORG_GUID}"></script></div></body></html>`}>
                <SplashPagePreview
                    entry={{ ...entry, id }}
                    fetchCollection={fetchCollection}
                />
            </Frame>
        </SplitPane>
    );
};

SplashPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(stylesExt)(SplashPage);
