/* eslint-disable camelcase */
import "isomorphic-fetch";

/**
 * A small batch of helpers to perform geo location searches
 */

const ApiKey = process.env.GATSBY_GOOGLE_GEOCODE_API_KEY;
const geocodeBaseUrl = `https://maps.googleapis.com/maps/api/geocode/json?key=${ApiKey}`;
const USZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

/**
 * Does this look like a US zip code to you?
 * @param {String} text
 */
export function isUSZip(text) {
    return text.match(USZip);
}

/**
 * Geoclode the given address,
 * returns an object with lat and lon,
 * null on failure
 */
export async function geocode({ address = "", component = "country:US" }) {
    const c = !!component ? `&components=${component}` : "";
    const a = !!address ? `&address=${encodeURIComponent(address)}` : "";
    const url = [`${geocodeBaseUrl}`, a, c].join("");
    const response = await fetch(url);
    if (!response.ok) return null;
    const json = await response.json();
    const { results = [] } = json;
    if (!results || !results.length) return null;
    const result = results[0];
    const {
        geometry: {
            location: { lat, lng },
        },
        address_components = [],
        formatted_address = "",
        types = [],
        partial_match = false,
    } = result;
    // reject any result which is a partial match
    if (partial_match) return null; // no partials, only known places
    // the below seems to be causing more problems than fixing
    // if (!types.includes("political") && !types.includes("postal_code")) {
    //     return null;
    // } // no Fred Meyer, No Walgreens
    if (types.includes("country")) return null; // all results are in the US, we don't need a country returned
    let state = getStateFromAddressComponents(address_components);
    return {
        state,
        lat,
        lon: lng,
        formattedAddress: formatted_address,
    };
}

const getStateFromAddressComponents = components => {
    let state = components.find(c =>
        c.types.includes("administrative_area_level_1"),
    );
    if (!state) return null;
    const { long_name, short_name } = state;
    return { long_name, short_name };
};

/**
 * Geo Locate a given zip
 * @param {String} zip
 */
export async function geolocateZip(zip) {
    if (!isUSZip(zip)) return null;
    let component = `postal_code:${zip}|country:US`;
    return await geocode({ component });
}

/**
 * Geolocate a given
 * @param {String} address
 */
export async function geolocateAddress(address) {
    return await geocode({ address });
}
