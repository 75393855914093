import React from "react";
import { noop } from "lodash";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Grid from "@material-ui/core/Grid";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";

import CustomFormField from "../CustomFormField";

import styles from "./field.module.less";

const Field = ({
    isDragged = false,
    onChange,
    handleClick,
    setField,
    index,
    row,
    typeOptions = [],
    type = "",
    required = false,
    contactFormFields,
    contactFormFieldName,
    nonRemovableFields,
}) => {
    const {
        customFieldType = "",
        label = "",
        bammName = "",
        options = [],
    } = row;
    const cls = isDragged ? `${styles.row} ${styles.isDragged}` : styles.row;

    return (
        <Box
            className={cls}
            marginTop={1}
            style={{
                cursor: isDragged ? "grabbing" : "inherit",
            }}>
            <Grid container>
                <Grid item xs>
                    <DragHandleIcon data-movable-handle />
                    <TextField
                        disabled={nonRemovableFields.includes(type)}
                        className={styles.field}
                        name="type"
                        select
                        value={type}
                        onChange={onChange}
                        inputProps={{
                            "data-label": "Field Type",
                        }}>
                        {typeOptions.map(opt => (
                            <option key={opt.id} value={opt.id}>
                                {opt.label}
                            </option>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <Box display="flex" justifyContent="flex-end">
                        <FormControlLabel
                            label="Required"
                            style={{ marginLeft: "10px" }}
                            control={
                                <Switch
                                    color="secondary"
                                    id="required"
                                    name="required"
                                    checked={required}
                                    onChange={onChange}
                                    inputProps={{
                                        "data-label": "Required",
                                    }}
                                />
                            }
                        />
                        <IconButton
                            onClick={handleClick}
                            disabled={nonRemovableFields.includes(type)}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
            <Box>
                {type === "custom" && (
                    <CustomFormField
                        index={index}
                        customFieldType={customFieldType}
                        label={label}
                        bammName={bammName}
                        options={options}
                        onChange={onChange}
                        setField={setField}
                        contactFormFields={contactFormFields}
                        contactFormFieldName={contactFormFieldName}
                    />
                )}
            </Box>
        </Box>
    );
};

export default Field;
