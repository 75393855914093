import React from "react";
import TextField from "@material-ui/core/TextField";

const CustomScript = ({
    name,
    label,
    isAdmin,
    value: inValue,
    entry = null,
    errors,
    hint = "Enter a custom JavaScript tracking script. Scripts should be complete, including <script> tags.",
    ...rest
}) => {
    const errorMsg = errors[name];
    const value = entry ? entry[name] : inValue;
    const helperText = errorMsg || hint;
    const id = `entry-${name}`;

    return (
        <TextField
            multiline
            rowsMax={4}
            autoComplete="off"
            id={id}
            name={name}
            label={label}
            disabled={!isAdmin}
            error={!!errorMsg}
            helperText={helperText}
            value={value}
            hidden={!isAdmin}
            fullWidth={true}
            margin="normal"
            inputProps={{
                "data-lpignore": "true",
                "data-label": label,
            }}
            {...rest}
        />
    );
};

export default CustomScript;
