import React from "react";
import { set, cloneDeep } from "lodash";
import { List, arrayMove } from "react-movable";

import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import Map from "./Map";
import MortgageCalculator from "./MortgageCalculator";
import Video from "./Video";
import Blog from "./Blog";
import FacebookPage from "./FacebookPage";
import Calendly from "./Calendly";
import Bookings from "./Bookings";
import Instagram from "./Instagram";
import Resources from "./Resources";
import Twitter from "./Twitter";
import Events from "./Events";
import Awards from "./Awards";
import Testimonials from "./Testimonials";
import Homebot from "./Homebot";
import TeamMembers from "./TeamMembers";
import Profile from "./Profile";
import Content from "./Content";
import ContactUs from "./ContactUs";
import G2HCalculator from "./G2HCalculator";
import AboutG2H from "./AboutG2H";
import AboutCare from "./AboutCare";
import AboutBE from "./AboutBE";
import CustomTestimonials from "./CustomTestimonials";
import CareTestimonials from "./CareTestimonials";
import BecomePartner from "./BecomePartner";

import { hasError, isMissing } from "../../../lib/validations";

const name = "modules";
const hint =
    "Turn widgets on and off or click and drag to reorder. Only completed widgets will display.";

const Widgets = ({
    value,
    entry = null,
    errors,
    missing,
    handleListChange,
    isAdmin,
    pageType,
    showUpload = false,
}) => {
    let widgets = entry ? entry[name] : value;

    const getChangeHandler = index => {
        const handleChange = e => {
            const value =
                e.target.type === "checkbox"
                    ? e.target.checked
                    : e.target.value;
            const field = e.target.name;
            const { type } = widgets[index];
            const validatorName = `${type}.${field}`;
            set(widgets[index], field, value); // "field" may be nested in the case of events
            widgets[index] = cloneDeep(widgets[index]); // ensure a "fresh" object for nested widgets
            errors[validatorName] = hasError(validatorName, value);
            missing[validatorName] = isMissing(validatorName, value);
            handleListChange(name, widgets);
        };
        return handleChange;
    };

    const getEditorChangeHandler = index => {
        const handleEditorChange = (field, value) => {
            const { type } = widgets[index];
            const validatorName = `${type}.${field}`;
            set(widgets[index], field, value); // "field" may be nested in the case of events
            widgets[index] = cloneDeep(widgets[index]); // ensure a "fresh" object for nested widgets
            errors[validatorName] = hasError(validatorName, value);
            missing[validatorName] = isMissing(validatorName, value);
            handleListChange(name, widgets);
        };
        return handleEditorChange;
    };

    const getSetter = index => {
        const setField = (field, value) => {
            set(widgets[index], field, value); // "field" may be nested in the case of events
            widgets[index] = cloneDeep(widgets[index]); // ensure a "fresh" object for nested widgets
            handleListChange(name, widgets);
        };
        return setField;
    };

    const getDeleter = index => {
        const deleteField = field => {
            delete widgets[index][field];
            widgets[index] = cloneDeep(widgets[index]);
            handleListChange(name, widgets);
        };
        return deleteField;
    };

    const getFileHandler = index => {
        const fileHandler = (field, value, label = "") => {
            set(widgets[index], field, value); // "field" may be nested in the case of events
            widgets[index] = cloneDeep(widgets[index]); // ensure a "fresh" object for nested widgets
            handleListChange(name, widgets);
        };
        return fileHandler;
    };

    return (
        <Box>
            <Paper>
                <Box padding={2}>
                    <Typography component="h3" variant="h6">
                        Widget Manager
                    </Typography>
                    <Typography component="p" variant="body1">
                        {hint}
                    </Typography>
                </Box>
            </Paper>
            <List
                values={widgets}
                onChange={({ oldIndex, newIndex }) => {
                    handleListChange(
                        name,
                        arrayMove(widgets, oldIndex, newIndex),
                    );
                }}
                renderList={renderList}
                renderItem={({
                    value,
                    props,
                    isDragged,
                    isSelected,
                    index,
                }) => {
                    const rest = {
                        props,
                        isDragged,
                        isSelected,
                        index,
                    };
                    // disable keyboard navigation on widgets
                    props.onKeyDown = e => {
                        return e;
                    };
                    return (
                        <Widget
                            key={value.type}
                            widget={value}
                            errors={errors}
                            onChange={getChangeHandler(index)}
                            onEditorChange={getEditorChangeHandler(index)}
                            onFileChange={getFileHandler(index)}
                            onListChange={handleListChange}
                            setField={getSetter(index)}
                            deleteField={getDeleter(index)}
                            index={index}
                            showUpload={showUpload}
                            isAdmin={isAdmin}
                            pageType={pageType}
                            {...rest}
                        />
                    );
                }}
            />
        </Box>
    );
};

export default Widgets;

const renderList = ({ children, props, isDragged }) => {
    return (
        <Box
            marginTop={2}
            {...props}
            style={{
                cursor: isDragged ? "grabbing" : "inherit",
            }}>
            {children}
        </Box>
    );
};

const Widget = ({
    widget,
    props,
    onChange,
    onEditorChange,
    onFileChange,
    onListChange,
    setField,
    deleteField,
    errors,
    isAdmin,
    pageType,
    ...rest
}) => {
    const { type } = widget;
    const hasErrorsInWidget = (errors, type) => {
        let keys = Object.keys(errors);
        return keys.some(key => key.startsWith(type) && !!errors[key]);
    };
    return (
        <div {...props}>
            <Box marginTop={2}>
                {type === "map" && (
                    <Map
                        widget={widget}
                        onChange={onChange}
                        errors={errors}
                        hasErrors={hasErrorsInWidget(errors, type)}
                        {...rest}
                    />
                )}
                {type === "calculator" && (
                    <MortgageCalculator
                        widget={widget}
                        onChange={onChange}
                        hasErrors={hasErrorsInWidget(errors, type)}
                        {...rest}
                    />
                )}
                {type === "video" && (
                    <Video
                        widget={widget}
                        onChange={onChange}
                        onFileChange={onFileChange}
                        setField={setField}
                        deleteField={deleteField}
                        errors={errors}
                        hasErrors={hasErrorsInWidget(errors, type)}
                        {...rest}
                    />
                )}

                {type === "blog" && (
                    <Blog
                        widget={widget}
                        onChange={onChange}
                        errors={errors}
                        {...rest}
                    />
                )}

                {type === "facebookpage" && (
                    <FacebookPage
                        widget={widget}
                        onChange={onChange}
                        errors={errors}
                        hasErrors={hasErrorsInWidget(errors, type)}
                        {...rest}
                    />
                )}

                {type === "calendar" && (
                    <Calendly
                        widget={widget}
                        onChange={onChange}
                        setField={setField}
                        errors={errors}
                        hasErrors={hasErrorsInWidget(errors, type)}
                        {...rest}
                    />
                )}

                {type === "bookings" && (
                    <Bookings
                        widget={widget}
                        onChange={onChange}
                        setField={setField}
                        errors={errors}
                        hasErrors={hasErrorsInWidget(errors, type)}
                        {...rest}
                    />
                )}

                {type === "instagram" && (
                    <Instagram
                        widget={widget}
                        onChange={onChange}
                        errors={errors}
                        hasErrors={hasErrorsInWidget(errors, type)}
                        {...rest}
                    />
                )}

                {type === "resources" && (
                    <Resources
                        widget={widget}
                        onChange={onChange}
                        setField={setField}
                        errors={errors}
                        hasErrors={hasErrorsInWidget(errors, type)}
                        {...rest}
                    />
                )}

                {type === "twitter" && (
                    <Twitter
                        widget={widget}
                        onChange={onChange}
                        errors={errors}
                        hasErrors={hasErrorsInWidget(errors, type)}
                        {...rest}
                    />
                )}

                {type === "events" && (
                    <Events
                        widget={widget}
                        onChange={onChange}
                        setField={setField}
                        errors={errors}
                        hasErrors={hasErrorsInWidget(errors, type)}
                        {...rest}
                    />
                )}

                {type === "awards" && (
                    <Awards
                        widget={widget}
                        onChange={onChange}
                        setField={setField}
                        errors={errors}
                        hasErrors={hasErrorsInWidget(errors, type)}
                        {...rest}
                    />
                )}

                {type === "testimonials" && (
                    <Testimonials
                        widget={widget}
                        onChange={onChange}
                        errors={errors}
                        hasErrors={hasErrorsInWidget(errors, type)}
                        {...rest}
                    />
                )}

                {type === "homebot" && (
                    <Homebot
                        widget={widget}
                        onChange={onChange}
                        setField={setField}
                        errors={errors}
                        hasErrors={hasErrorsInWidget(errors, type)}
                        {...rest}
                    />
                )}

                {type === "teamMembers" && (
                    <TeamMembers
                        widget={widget}
                        onChange={onChange}
                        onFileChange={onFileChange}
                        errors={errors}
                        hasErrors={hasErrorsInWidget(errors, type)}
                        {...rest}
                    />
                )}

                {type === "profile" && (
                    <Profile
                        widget={widget}
                        onChange={onChange}
                        onFileChange={onFileChange}
                        onEditorChange={onEditorChange}
                        errors={errors}
                        hasErrors={hasErrorsInWidget(errors, type)}
                        isAdmin={isAdmin}
                        pageType={pageType}
                        {...rest}
                    />
                )}

                {type === "content" && (
                    <Content
                        widget={widget}
                        onChange={onChange}
                        onEditorChange={onEditorChange}
                        onFileChange={onFileChange}
                        errors={errors}
                        hasErrors={hasErrorsInWidget(errors, type)}
                        {...rest}
                    />
                )}

                {type === "contactUs" && (
                    <ContactUs
                        widget={widget}
                        onChange={onChange}
                        onEditorChange={onEditorChange}
                        onFileChange={onFileChange}
                        setField={setField}
                        errors={errors}
                        hasErrors={hasErrorsInWidget(errors, type)}
                        isAdmin={isAdmin}
                        {...rest}
                    />
                )}

                {type === "g2hCalculator" && (
                    <G2HCalculator
                        widget={widget}
                        onChange={onChange}
                        onEditorChange={onEditorChange}
                        onFileChange={onFileChange}
                        setField={setField}
                        errors={errors}
                        hasErrors={hasErrorsInWidget(errors, type)}
                        isAdmin={isAdmin}
                        {...rest}
                    />
                )}

                {type === "aboutG2H" && (
                    <AboutG2H
                        widget={widget}
                        onChange={onChange}
                        onEditorChange={onEditorChange}
                        onFileChange={onFileChange}
                        onListChange={onListChange}
                        setField={setField}
                        errors={errors}
                        hasErrors={hasErrorsInWidget(errors, type)}
                        isAdmin={isAdmin}
                        {...rest}
                    />
                )}

                {type === "aboutCare" && (
                    <AboutCare
                        widget={widget}
                        onChange={onChange}
                        onEditorChange={onEditorChange}
                        onFileChange={onFileChange}
                        onListChange={onListChange}
                        setField={setField}
                        errors={errors}
                        hasErrors={hasErrorsInWidget(errors, type)}
                        isAdmin={isAdmin}
                        {...rest}
                    />
                )}

                {type === "aboutBE" && (
                    <AboutBE
                        widget={widget}
                        onChange={onChange}
                        onEditorChange={onEditorChange}
                        onFileChange={onFileChange}
                        errors={errors}
                        hasErrors={hasErrorsInWidget(errors, type)}
                        isAdmin={isAdmin}
                        {...rest}
                    />
                )}

                {type === "customTestimonials" && (
                    <CustomTestimonials
                        widget={widget}
                        onChange={onChange}
                        onFileChange={onFileChange}
                        setField={setField}
                        errors={errors}
                        hasErrors={hasErrorsInWidget(errors, type)}
                        isAdmin={isAdmin}
                        {...rest}
                    />
                )}

                {type === "careTestimonials" && (
                    <CareTestimonials
                        widget={widget}
                        onChange={onChange}
                        onFileChange={onFileChange}
                        setField={setField}
                        errors={errors}
                        hasErrors={hasErrorsInWidget(errors, type)}
                        isAdmin={isAdmin}
                        {...rest}
                    />
                )}

                {type === "becomePartner" && (
                    <BecomePartner
                        widget={widget}
                        onChange={onChange}
                        onFileChange={onFileChange}
                        errors={errors}
                        hasErrors={hasErrorsInWidget(errors, type)}
                        isAdmin={isAdmin}
                        {...rest}
                    />
                )}
            </Box>
        </div>
    );
};
