import React from "react";
import { noop } from "lodash";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { List, arrayMove } from "react-movable";

import DragHandleIcon from "@material-ui/icons/DragHandle";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import FormHelperText from "@material-ui/core/FormHelperText";

import VisibityIcon from "@material-ui/icons/Visibility";
import VisibityOffIcon from "@material-ui/icons/VisibilityOff";

import Uploader from "../../Uploader";
import HeroImage from "../../HeroImage";

import styles from "../widgets.module.less";
import states from "../../../../../../lib/states.json";

const widget = "customTestimonials";
const field = "display"; // used for saving widget data
const name = `${widget}.${field}`; // used for validation and error messages
const label = "Display Custom Testimonials?";
const id = `entry-module-${name}`;

const CustomTestimonials = ({
    widget,
    onChange = noop,
    setField = noop,
    isAdmin,
    onFileChange = noop,
    errors,
    ...rest
}) => {
    const {
        display,
        customTestimonials,
        backgroundImage,
        backgroundImageCustom,
    } = widget;

    const handleChangeList = list => {
        setField("customTestimonials", list);
    };

    return (
        <Accordion
            TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
            className={styles.Widget}>
            <AccordionSummary
                classes={{ content: styles.TitleBar }}
                expandIcon={<ExpandMoreIcon />}>
                <DragHandleIcon data-movable-handle />
                <Typography component="h4" variant="h6">
                    Custom Testimonials
                </Typography>
                {display ? (
                    <VisibityIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                ) : (
                    <VisibityOffIcon
                        color="disabled"
                        className={styles.WidgetVisibility}
                    />
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                    <FormControlLabel
                        label={label}
                        control={
                            <Switch
                                color="primary"
                                id={id}
                                name={field}
                                checked={display}
                                onChange={onChange}
                                inputProps={{
                                    "data-label": label,
                                }}
                            />
                        }
                        style={{ paddingBottom: "20px" }}
                    />
                    <Paper>
                        <Box padding={2} margin={2}>
                            <Typography component="h3" variant="h6">
                                Background Image
                            </Typography>
                            <br />
                            <HeroImage
                                isAdmin={isAdmin}
                                name="backgroundImage"
                                value={backgroundImage}
                                collections={["Body"]}
                                onChange={onChange}
                            />
                            <Uploader
                                name="backgroundImageCustom"
                                label="Custom Image"
                                value={backgroundImageCustom}
                                config={{
                                    imagesOnly: true,
                                    inputAcceptTypes: ".jpg, .jpeg, .png",
                                    crop: "16:9",
                                    imageShrink: "2881x2017",
                                }}
                                handleFile={onFileChange}
                                onChange={onChange}
                                logo
                            />
                        </Box>
                    </Paper>
                    <Box>
                        <Typography component="h3" variant="h6">
                            Testimonials
                        </Typography>
                        <Typography component="p" variant="body1">
                            Enter the testimonials that will be displayed.
                        </Typography>
                        <CustomTestimonialsList
                            customTestimonials={customTestimonials}
                            onChangeList={handleChangeList}
                            errors={errors}
                        />
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

const CustomTestimonialsList = ({
    customTestimonials,
    errors,
    onChangeList = noop,
}) => {
    const errorMsg = errors[field];
    let helperText = errorMsg || "";

    const getHandler = index => {
        return e => {
            const value =
                e.target.type === "checkbox"
                    ? e.target.checked
                    : e.target.value;
            const name = e.target.name;
            customTestimonials[index][name] = value;
            onChangeList(customTestimonials);
        };
    };

    const getDeleter = index => {
        return e => {
            customTestimonials.splice(index, 1);
            onChangeList(customTestimonials);
        };
    };

    const add = () => {
        customTestimonials.push({ text: "", name: "" });
        onChangeList(customTestimonials);
    };

    return (
        <Box>
            <Paper>
                <Box padding={2} margin={2}>
                    <List
                        values={customTestimonials}
                        onChange={({ oldIndex, newIndex }) => {
                            const reordered = arrayMove(
                                customTestimonials,
                                oldIndex,
                                newIndex,
                            );
                            onChangeList(reordered);
                        }}
                        renderList={({ children, props, isDragged }) => {
                            return (
                                <Box
                                    marginTop={2}
                                    {...props}
                                    errors={props.errors}
                                    missing={props.missing}
                                    style={{
                                        cursor: isDragged
                                            ? "grabbing"
                                            : "inherit",
                                    }}>
                                    {children}
                                </Box>
                            );
                        }}
                        renderItem={({ value, props, isDragged, index }) => {
                            const onChange = getHandler(index);
                            const handleClick = getDeleter(index);

                            return (
                                <div {...props}>
                                    <CustomTestimonialListItem
                                        row={value}
                                        required={value.required}
                                        isDragged={isDragged}
                                        onChange={onChange}
                                        onDelete={handleClick}
                                    />
                                </div>
                            );
                        }}
                    />
                    <Box className={styles.add} marginTop={2}>
                        <IconButton onClick={add} margin={0}>
                            <AddIcon />
                        </IconButton>
                    </Box>
                    <FormHelperText>{helperText}</FormHelperText>
                </Box>
            </Paper>
        </Box>
    );
};

const CustomTestimonialListItem = ({
    isDragged = false,
    row,
    onChange = noop,
    onDelete = noop,
    errors = [],
}) => {
    const { text = "", name = "", title = "", city = "", state = "" } = row;
    const cls = isDragged ? `${styles.row} ${styles.isDragged}` : styles.row;

    let stateOptions = [];
    for (let stateAbbr in states) {
        stateOptions.push({
            id: stateAbbr,
            label: states[stateAbbr],
        });
    }

    return (
        <Box
            className={cls}
            marginTop={1}
            style={{
                cursor: isDragged ? "grabbing" : "inherit",
            }}>
            <Grid container>
                <Grid item xs={1}>
                    <DragHandleIcon data-movable-handle />
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        className={styles.field}
                        name="text"
                        value={text}
                        label="Text"
                        onChange={onChange}
                        multiline={true}
                        fullWidth={true}
                        rows="2"
                        inputProps={{
                            "data-label": "Text",
                        }}></TextField>
                    <TextField
                        className={styles.field}
                        name="name"
                        value={name}
                        label="Name"
                        onChange={onChange}
                        style={{ width: "50%" }}
                        inputProps={{
                            "data-label": "Name",
                        }}></TextField>
                    <TextField
                        className={styles.field}
                        name="title"
                        value={title}
                        label="Title"
                        onChange={onChange}
                        style={{ width: "50%" }}
                        inputProps={{
                            "data-label": "Title",
                        }}></TextField>
                    <TextField
                        className={styles.field}
                        name="city"
                        value={city}
                        label="City"
                        onChange={onChange}
                        style={{ width: "50%" }}
                        inputProps={{
                            "data-label": "City",
                        }}></TextField>
                    <TextField
                        className={styles.field}
                        name="state"
                        value={state}
                        label="State"
                        select
                        onChange={onChange}
                        style={{ width: "50%" }}
                        inputProps={{
                            "data-label": "State",
                        }}>
                        {stateOptions.map(opt => (
                            <option key={opt.id} value={opt.id}>
                                {opt.label}
                            </option>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={1}>
                    <Box display="flex" justifyContent="flex-end">
                        <IconButton onClick={onDelete}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CustomTestimonials;
